import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app-constants.constants';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  public IDC_LOGO = AppConstants.IDC_LOGO;

  constructor(private router: Router) { }

  /*----------METHOD TO REDIRECT THE USER TO LOGIN PAGE---------*/
  redirectToLogin() {
    this.router.navigate([AppConstants.LOGIN_URL]);
  }

}
