import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppConstants } from '../../../app-constants.constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common-service/common-service.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ManageAlertTextUtility } from 'src/app/shared/utilities/manage-alert-text.util';
import { AlertModalEntity } from 'src/app/shared/interfaces/alert-modal-entity.interface';
import { EncryptDecryptUtility } from 'src/app/shared/utilities/encrypt-decrypt.util';
import { ErrorConstants } from 'src/app/shared/constants/error.constants';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  @ViewChild('setPasswordButton', { static: true }) setPasswordButton;

  public IDC_LOGO: string = AppConstants.IDC_LOGO;
  public accountActivationForm: FormGroup;
  public errorText: string = null;
  public alertUtility = new ManageAlertTextUtility();
  public successText: AlertModalEntity = this.alertUtility.getAlertEntity(null, null, null, null);
  public activationId: any = null;
  public isFormSubmitted = false;
  public PORTAL_ACTIVATION = 'ACTIVATION';
  public PORTAL_FORGOT_PASS = 'FORGOT_PASSWORD';
  public portalType: string = this.PORTAL_ACTIVATION;
  private encryptDecryptUtil = new EncryptDecryptUtility();
  public isTermsAgreed = false;

  private activationSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _commonService: CommonService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    /*----------EXTRACTING THE PORTAL TYPE (CLIENT/ADMIN) FROM THE ROUTE DATA----------*/
    this.activatedRoute.data.subscribe(data => {
      if (data && data.type) {
        this.portalType = data.type;
        this.getUserId();
      }
    });
  }

  /*---------METHOD TO FETCH THE ACTIVATION ID FROM URL----------*/
  getUserId() {
    this.activatedRoute.params.subscribe(params => {
      this.activationId = params.id;
    });
  }

  /*---------HELPER METHOD TO CREATE ACCOUNT ACTIVATION FORM----------*/
  createForm() {
    this.accountActivationForm = this.fb.group({
      'newPassword': [null, [Validators.required, Validators.pattern(AppConstants.PASSWORD_REGEX)]], // TODO: ADD PATTERN TO PASSWORD
      'confirmPassword': [null, Validators.required] // TODO: ADD PATTERN TO PASSWORD
    });
  }

  /*----------METHOD TO CHECK IF NEW PASSWORD AND CONFIRM PASSWORD MATCHES-----------*/
  private validatePassword() {
    let doesPasswordsMatch = false;
    if (this.accountActivationForm.get('newPassword').value === this.accountActivationForm.get('confirmPassword').value) {
      doesPasswordsMatch = true;
    }
    return doesPasswordsMatch;
  }

  /*----------HELPER METHOD TO CALL API BASED ON PORTAL TYPE-----------*/
  formSubmitted(formValues) {
    this.isFormSubmitted = true;
    if (this.accountActivationForm.valid) {
      if (this.portalType === this.PORTAL_ACTIVATION) {
        this.activateAccount(formValues);
      } else if (this.portalType === this.PORTAL_FORGOT_PASS) {
        this.resetPassword(formValues);
      }
    }
  }

  /*------------METHOD TRIGGERRED WHEN USER SUBMITS ACCOUNT ACTIVATION FORM----------*/
  activateAccount(formValues) {
    this.errorText = null;
    this.alertUtility.resetAlertEntity(this.successText);
    if(this.isTermsAgreed) {
      if (this.validatePassword()) {
        const formValueEntity = {
          'activationId': this.activationId,
          'newPassword': this.encryptDecryptUtil.encrypt(formValues.newPassword, AppConstants.IDC_DEFAULT_ENCRYPTION_KEY)
        };

        this.activationSubscription = this._commonService.postOperation(environment.ACTIVATE_ACCOUNT_URL, formValueEntity)
        .subscribe((response) => {
          if (response && response.status && response.status === 'success') {
            this.successText = this.alertUtility
            .getAlertEntity('SUCCESS', 'Set Password', 'Success', 'Password has been set successfully!');
          } else if (response && response.error) {
            this.errorText = response.error;
          } else {
            this.errorText = ErrorConstants.UNEXPECTED_ERROR_MSG;
          }
        }, (error: Error) => {
          this.errorText = ErrorConstants.UNEXPECTED_ERROR_MSG;
          // console.error('SetPasswordComponent :: activateAccount() :: Exception occurred :: ', error.message);
        });
      } else {
        this.errorText = ErrorConstants.PASSWORDS_DONT_MATCH_MSG;
        // console.error('SetPasswordComponent :: activateAccount() :: Passords dont match');
      }
    }
    
  }

  /*------------METHOD TRIGGERRED WHEN USER SUBMITS RESET PASSWORD FORM----------*/
  resetPassword(formValues) {
    this.errorText = null;
    this.successText = null;
    if (this.validatePassword()) {
      const formValueEntity = {
        'activationId': this.activationId,
        'newPassword': this.encryptDecryptUtil.encrypt(formValues.newPassword, AppConstants.IDC_DEFAULT_ENCRYPTION_KEY)
      };

      this.activationSubscription = this._commonService.postOperation(environment.PASSWORD_RESET, formValueEntity)
      .subscribe((response) => {
        if (response && response.status) {
          if (response.status === 'success') {
            this.successText = this.alertUtility.getAlertEntity('SUCCESS', 'Reset Password', 'Success',
            'Password has been reset successfully!');
          }
        } else if (response && response.error) {
          this.errorText = response.error;
        } else {
          this.errorText = ErrorConstants.UNEXPECTED_ERROR_MSG;
        }
      }, (error: Error) => {
        this.errorText = ErrorConstants.UNEXPECTED_ERROR_MSG;
        // console.error('SetPasswordComponent :: resetPassword() :: Exception occurred :: ', error.message);
      });
    } else {
      this.errorText = ErrorConstants.PASSWORDS_DONT_MATCH_MSG;
      // console.error('SetPasswordComponent :: resetPassword() :: Passords dont match');
    }
  }

  /*--------HELPER METHOD TO NAVIGATE USER TO LOGIN POST ACCOUNT ACTIVATION---------*/
  navigateToLogin() {
    this.alertUtility.resetAlertEntity(this.successText);
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    if (this.activationSubscription) {
      this.activationSubscription.unsubscribe();
    }
  }
}
