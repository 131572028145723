import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateDecimal'
})
export class TruncateDecimalPipe implements PipeTransform {

  transform(value: any): any {
    if(undefined !== value && null !== value) {
      const _value = value.toString().split('.');
      return _value[0];
    } 

    return value;
  }
}
