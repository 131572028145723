import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, OnDestroy, Input, ChangeDetectorRef} from '@angular/core';
import { ErrorConstants } from 'src/app/shared/constants/error.constants';
@Component({
  selector: 'app-web-cam',
  templateUrl: './web-cam.component.html',
  styleUrls: ['./web-cam.component.scss']
})
export class WebCamComponent implements AfterViewInit, OnDestroy {
  WIDTH = 1280;
  HEIGHT = 960;

  PREVIEW_WIDTH = 0;
  PREVIEW_HEIGHT = 0;

  @ViewChild('videoContainer', { static: true }) public videoContainer: ElementRef;
  @ViewChild('previewVideo', { static: true }) public previewVideo: ElementRef;
  @ViewChild('video', { static: true }) public video: ElementRef;
  @ViewChild('canvas', { static: true }) public canvas: ElementRef;
  @ViewChild('canvasSmall', { static: true }) public canvasSmall: ElementRef;

  @Output() clickedPhotoEvent = new EventEmitter();
  @Output() webcamerrorMsgEvent = new EventEmitter();

  public capturedImage = null;
  public errorMsg: any;
  public stream;

  constructor(private _cdRef: ChangeDetectorRef) {}

  async ngAfterViewInit() {
    this.PREVIEW_WIDTH = Number(this.videoContainer.nativeElement.clientWidth);
    this.PREVIEW_HEIGHT = Number(this.videoContainer.nativeElement.clientHeight);
    this._cdRef.detectChanges();
    await this.setupDevices();
  }

  /*----------METHOD TO STREAM THE VIDEO CAPTURED THROUGH WEBCAM----------*/
  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (this.stream) {
          this.previewVideo.nativeElement.srcObject = this.stream;
          this.previewVideo.nativeElement.play();

          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();
          this.errorMsg = null;
        } else {
          // this.errorMsg = 'You have no output video device';
          this.errorMsg = ErrorConstants.WEBCAM_ISSUE;
        }
      } catch (e) {
        // this.errorMsg = e;
        this.errorMsg = ErrorConstants.WEBCAM_ISSUE;
      }

      if (this.errorMsg) {
        this.webcamerrorMsgEvent.emit(this.errorMsg);
      }
    }
    // else {
    //   this.errorMsg = ErrorConstants.WEBCAM_ISSUE;
    // }
  }

  /*------------METHOD TO CAPTURE THE STREAM AND CREATE A CANVAS PREVIEW----------*/
  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.capturedImage = this.canvas.nativeElement.toDataURL('image/png');
    const entity = {
      rawData: this.capturedImage,
      blobData: this.DataURIToBlob(this.capturedImage),
      compressedBlobData: this.DataURIToBlob(this.canvasSmall.nativeElement.toDataURL('image/png'))
    };
    this.clickedPhotoEvent.emit(entity);
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);

    this.canvasSmall.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.PREVIEW_WIDTH, this.PREVIEW_HEIGHT);
  }

  /*----------CAMERA CAPTURES IMAGE IN BASE-64, CONVERTING IT TO MULTIPART/BLOB BEFORE SENDING OVER API--------*/
  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  ngOnDestroy() {
    // STOPPING THE CAMERA
    if (this.video && this.video.nativeElement) {
      this.video.nativeElement.pause();
    }

    if (this.stream) {
      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }
}
