import { AfterContentInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-idc-doughnut-chart',
  templateUrl: './idc-doughnut-chart.component.html',
  styleUrls: ['./idc-doughnut-chart.component.scss']
})
export class IdcDoughnutChartComponent implements AfterContentInit {

  @ViewChild('doughnutChart', { static: true }) private chartContainer: ElementRef;
  @Input('colors') colors = [];
  @Input('dividerColor') dividerColor = '#f2f2f2';
  @Input('percentage') percentage;
  @Input('label') label;
  @Input('chartData') data;

  private width;
  private height;
  private margin = 0;
  private radius;
  private svg;
  private color;
  private pie;
  private data_ready;

  // Create dummy data
  // public data = { a: 80, b: 5, c: 15 }

  constructor() { }

  ngAfterContentInit() {
    this.draw();

  }

  draw() {
    this.width = this.chartContainer.nativeElement.offsetWidth;
    this.height = this.chartContainer.nativeElement.offsetWidth;
    this.radius = Math.min(this.width, this.height) / 2 - this.margin;
    const container = d3.select(this.chartContainer.nativeElement);
    this.svg = container
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', 'translate(' + this.width / 2 + ',' +
        this.height / 2 + ')');

    // set the color scale
    this.color = d3.scaleOrdinal()
      .domain(Object.keys(this.data))
      .range(this.colors);

    // Compute the position of each group on the pie:
    this.pie = d3.pie()
      .value(function (d) { return d['value']; });
    
    this.data_ready = this.pie(d3.entries(this.data));

    this.svg
      .selectAll('whatever')
      .data(this.data_ready)
      .enter()
      .append('path')
      .attr('d', d3.arc()
        .innerRadius(40)         // This is the size of the donut hole
        .outerRadius(this.radius))
      .attr('fill',(d) => this.color(d.data.key))
      .attr('stroke', this.dividerColor)
      .style('stroke-width', '6px')
      .style('opacity', 1);
  }

}
