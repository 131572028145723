<div class="face-validation-loader-container" *ngIf="!isSessionReady"> <!--APP SPECIFIC CHANGES-->
    <app-loader [showText]="true"></app-loader>
  </div>
  
  <canvas
      [style.display]="'none'"
      #canvasSmall
      id="canvas-small"
      [width]="360"
      [height]="270"
  ></canvas>
  
  <div class="d-flex flex-column justify-content-center align-items-center _vedioAlign">
    <!-- OPTIONAL ERROR CONTAINER-->
    <!-- <div class="alert alert-danger" id="pl-error-container"></div>  -->
    <div id="pl-video-container">
      <div class="position-relative">
        <div id="overlay-container" class="w-100 h-100"></div>
        <canvas id="output-video"></canvas>
        <div class="ocrloader">
          <em></em>
          <span></span>
        </div>
        <img id="pl-captured-image"><!--OPTIONAL-->
      </div>
    
      <video id="pl-input-video" autoplay></video>
    
      <div class="control1"></div>
    </div>
      
    <div class="snap-container text-center mt-3">
      <button class="btn btn-primary generic-button-blue" id="pl-capture-button" (click)="capture()"></button>
    </div> 
  </div> 

  <div class="pt-2 text-center" *ngIf="!isReady" style="margin-top: 6rem;"> <!--APP SPECIFIC CHANGES-->
    <div class="alert alert-primary" role="alert">
      <img src="assets/img/web-demo/alert-timer.png"> 
      <span>Processing...</span>
     </div>
  </div>
  