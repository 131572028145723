<div id="landing-page-set-password" class="h-100 theme_scroll">
	<div class="header">
		<img [src]="IDC_LOGO">
	</div>

	<div class="content container-fluid pb-0">
		<div class="row">
			<div class="col-12">
				<h5>Terms & Conditions</h5>
			</div>
		</div>
		
		<div class="row">
			<div class="col-lg-7 col-md-12" style="min-height: 200px;">
				<div class="data-body theme_scroll">
					<app-terms-and-conditions></app-terms-and-conditions>
				</div>
				
			</div>
		</div>

		<div class="row consent mt-3">
			<div class="col-12">
				<div>
					<label class="container-checkbox-round">
						<span class="search-type-label" [ngClass]="isTermsAgreed ? 'terms-agreed' : ''">
							I have read and hereby agree to the terms and conditions
						</span>
						<input type="checkbox" (change)="errorText = null" [(ngModel)]="isTermsAgreed" [ngModelOptions]="{standalone: true}">
						<span class="checkmark-round" id="set-password-consent-checkbox"></span>
					</label>
				</div>
			</div>
		</div>

		<div class="row t-and-c-disclaimer mt-3">
			<div class="col-lg-7 col-md-12">
				<div>
					<p>
						Read the above terms and conditions carefully! 
						It contains very important information about 
                        your rights and obligations, as well as limitations and exclusions that may apply to you. 
                        This document contains a dispute resolution and indemnification clause. 
                        By clicking on the “accept” button or accessing or using the services or the platform, 
                        you are consenting to be bound by and are becoming a party to this agreement. 
                        If you do not agree to all of the terms of this agreement, click the “do not accept” button 
                        or leave the website.
					</p>

					<p class="mb-0">
						Idcentral’s competitors are prohibited from accessing the services, 
                        except with subex’s prior written consent. The term “competitors” or “competition” shall mean 
                        any entity, body corporate, firm, association, independent contractor, consultant who are 
						accessing services with the intent of:- 
						<br>(i) developing competitors own services, similar to 
						services offered by idcentral, and/or 
						<br>(ii) improving existing services, and/or 
						<br>(iii) adding idcentral functionality to competitors existing services. 
						In addition, the services may not be accessed by such competitors for purposes of monitoring 
						their availability, performance or 
						functionality, or for any other benchmarking or competitive purposes. 
						If you are unsure whether 
                        you are competitor please do not sign this agreement and send a mail to
						<a href="mailto:partner@idcentral.io">partner@idcentral.io</a>
					</p>
				</div>
			</div>
		</div>

		
	</div>

	<div class="container-fluid content set-pass-sec">
		<form [formGroup]="accountActivationForm" (ngSubmit)="formSubmitted(accountActivationForm.value)">

			<div class="row">
				<div class="col-12">
					<h5>Set password for your account</h5>

					<p class="password-instructions mt-3 mb-2">Make sure your password contains: </p>
					<ul class="pl-3 password-instructions">
						<li>At least one upper case and one lower case letter</li>
						<li>At least one digit</li>
						<li>At least one special character</li>
						<li>Minimum eight characters</li>
					</ul>
				</div>
			</div>

			<div class="row" *ngIf="this.errorText">
				<div class="col-4">
					<div class="alert alert-danger mt-3"><strong>Error </strong>{{errorText}}</div> 
				</div>
			</div>

			<div class="row">
				<div class="col-lg-2 col-md-3 col-sm-12">
					<div class="form-group">
						<label>Enter Password <span class="required-icon">*</span></label>
						<input 
							type="password" 
							id="new-password" 
							name="newPassword"
							formControlName="newPassword"	
							[attr.disabled]="isTermsAgreed ? null : 'valid'"
							[ngClass]="(accountActivationForm.controls['newPassword'].touched && accountActivationForm.controls['newPassword'].errors?.pattern) ||
							(isFormSubmitted && accountActivationForm.controls['newPassword'].errors?.required) ? 'error-input' : ''"
							class="form-control idc-generic-input"							
						>

						<div *ngIf="isFormSubmitted && accountActivationForm.controls['newPassword'].errors?.required" 
							class="validation-error">Password is a mandatory field</div>

						<div class="validation-error password-validation-error" 
							*ngIf="(isFormSubmitted || accountActivationForm.controls['newPassword'].touched) && accountActivationForm.controls['newPassword'].errors?.pattern"		
								> Invalid password
							<!-- The password must contain:
							<ul class="pl-3">
								<li>At least one upper case and one lower case letter</li>
								<li>At least one digit</li>
								<li>At least one special character</li>
								<li>Minimum eight in length</li>
							</ul> -->
						</div>
					</div>
				</div> 

				<div class="col-lg-2 col-md-3 col-sm-12">
					<div class="form-group">
						<label>Confirm Password <span class="required-icon">*</span></label>
						<input 
							type="password" 
							id="confirm-password" 
							name="confirmPassword"
							formControlName="confirmPassword"
							[ngClass]="isFormSubmitted && accountActivationForm.controls['confirmPassword'].errors?.required ?
							'error-input' : ''"	
							[attr.disabled]="isTermsAgreed ? null : 'valid'"
							class="form-control idc-generic-input"					
						>

						<div 
							*ngIf="isFormSubmitted && accountActivationForm.controls['confirmPassword'].errors?.required" 
							class="validation-error"
						>Confirm Password is a mandatory field</div>
					</div>
				</div>

				<div class="col-12">
					<button type="submit" 
					id="os-set-password-submit-btn"
					[attr.disabled]="isTermsAgreed ? null : 'valid'"
					class="btn btn-primary full-width-blue-button set-pass-button" #setPasswordButton>Submit</button>
				</div>

			</div>
		</form>
	</div>
</div>

<app-alert-modal-new
	*ngIf="successText && successText.alertText" 
	[hideCloseButton]="true"
	[hideCancelButton]="true"
	[yes_button_name]="'Login'"
	(modalAction)="navigateToLogin()"
	[iconType]="successText.iconType"
	[alertText]="successText.alertSubText"
	[alertHeader]="successText.alertText"
></app-alert-modal-new>