import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router) {
    // PROD SETTING :: ENABLE BELOW GA CODE
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   if(event.url === '/onboarding-solutions' || event.url.indexOf('/set-password/') > -1 || event.url === '/login') {
    //     gtag('event', 'page_view', {
    //       page_path: event.urlAfterRedirects
    //     });
    //   }
    // });
  }


}
