import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtractDatePipe } from './pipes/extract-date/extract-date.pipe';
import { CoverEmptyPipe } from './pipes/cover-empty-values/cover-empty.pipe';
import { IsOpenDirective } from './directives/is-open/is-open.directive';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { FilterDateRangePipe } from './pipes/filter-date-range/filter-date-range.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { LoaderModalComponent } from './components/loader-modal/loader-modal.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
// import { PaymentGatewayComponent } from './components/payment-gateway/payment-gateway.component';
import { IdcIconComponent } from './components/idc-icon/idc-icon.component';
import { IdcDynamicTableComponent } from './components/idc-dynamic-table/idc-dynamic-table.component';
import { IdcStackedComponent } from './components/charts/idc-stacked-bar-chart/idc-stacked-bar-chart.component';
import { IdcGroupedBarChartComponent } from './components/charts/idc-grouped-bar-chart/grouped-bar-chart/idc-grouped-bar-chart.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TacometerComponent } from './components/charts/tacometer/tacometer.component';
import { ExtractTimePipe } from './pipes/extract-time/extract-time.pipe';
import { IdcDoughnutChartComponent } from './components/charts/idc-doughnut-chart/idc-doughnut-chart.component';
import { RoundOffPipe } from './pipes/round-off/round-off.pipe';
import { AlertModalNewComponent } from './components/alert-modal-new/alert-modal-new.component';
import { FilterCountryCodePipe } from './pipes/filter-country-code/filter-country-code.pipe';
import { WebCaptureComponent } from './components/web-capture/web-capture.component';
import { WebCamComponent } from './components/web-capture/web-cam/web-cam.component';
import { SplitUnderscorePipe } from './pipes/split-underscore/split-underscore.pipe';
import { UppercaseFirstLetterPipe } from './pipes/uppercase-first-letter/uppercase-first-letter.pipe';
import { SplitCamelCasePipe } from './pipes/split-camel-case/split-camel-case.pipe';
import { FormsModule } from '@angular/forms';
import { TruncateDecimalPipe } from './pipes/truncate-decimal/truncate-decimal.pipe';
import { WebCamFaceValidationComponent } from './components/web-capture/web-cam-face-validation/web-cam-face-validation.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NewWebCaptureComponent } from './components/new-web-capture/new-web-capture.component';
import { NewWebCamComponent } from './components/new-web-capture/new-web-cam/new-web-cam.component';
import { NewWebCamFaceValidationComponent } from './components/new-web-capture/new-web-cam-face-validation/new-web-cam-face-validation.component';

@NgModule({
  declarations: [
    ExtractDatePipe,
    CoverEmptyPipe,
    IsOpenDirective,
    AlertMessageComponent,
    LoaderComponent,
    NoDataComponent,
    FilterPipe,
    FilterDateRangePipe,
    PaginationComponent,
    SwitchButtonComponent,
    AlertModalComponent,
    LoaderModalComponent,
    ErrorPageComponent,
    IdcIconComponent,
    IdcDynamicTableComponent,
    IdcStackedComponent,
    IdcGroupedBarChartComponent,
    ProgressBarComponent,
    TacometerComponent,
    ExtractTimePipe,
    IdcDoughnutChartComponent,
    RoundOffPipe,
    AlertModalNewComponent,
    FilterCountryCodePipe,
    WebCaptureComponent,
    WebCamComponent,
    SplitUnderscorePipe,
    UppercaseFirstLetterPipe,
    SplitCamelCasePipe,
    TruncateDecimalPipe,
    WebCamFaceValidationComponent,
    NewWebCaptureComponent,
    NewWebCamComponent,
    NewWebCamFaceValidationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    NgxSliderModule,
    NgxSpinnerModule
  ],
  exports: [
    ExtractDatePipe,
    CoverEmptyPipe,
    IsOpenDirective,
    AlertMessageComponent,
    LoaderComponent,
    NoDataComponent,
    FilterPipe,
    FilterDateRangePipe,
    PaginationComponent,
    SwitchButtonComponent,
    AlertModalComponent,
    LoaderModalComponent,
    ErrorPageComponent,
    IdcIconComponent,
    IdcStackedComponent,
    IdcGroupedBarChartComponent,
    IdcDynamicTableComponent,
    ProgressBarComponent,
    TacometerComponent,
    ExtractTimePipe,
    IdcDoughnutChartComponent,
    RoundOffPipe,
    AlertModalNewComponent,
    FilterCountryCodePipe,
    WebCaptureComponent,
    WebCamComponent,
    SplitUnderscorePipe,
    UppercaseFirstLetterPipe,
    SplitCamelCasePipe,
    TruncateDecimalPipe,
    WebCamFaceValidationComponent,
    NewWebCaptureComponent,
    NewWebCamComponent,
    NewWebCamFaceValidationComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class SharedModule { }
