<div class="body_content_section h-100">

	<!--IDC LOGO-->
	<img class="idc-logo" [src]="IDC_LOGO">

	<!-- <app-alert-modal 
		*ngIf="successText && successText.alertText" 
		[hideCloseButton]="true"
		[custom_yes_button]="'Login'"
		(modalAction)="navigateToLogin()"
		[iconType]="successText.iconType"
		[alertText]="successText.alertText" 
		[alertSubText]="successText.alertSubText"
		[subHeader]="successText.subHeader" 
	></app-alert-modal> -->

	<app-alert-modal-new
		*ngIf="successText && successText.alertText" 
		[hideCloseButton]="true"
		[hideCancelButton]="true"
		[yes_button_name]="'Login'"
		(modalAction)="navigateToLogin()"
		[iconType]="successText.iconType"
		[alertText]="successText.alertSubText" 
		[alertHeader]="successText.subHeader" 
	></app-alert-modal-new>

	<!--LOGIN SECTION-->
	<div class="login_section">

		<div class="login-container">
			<div class="login-header">
				<ng-container *ngIf="portalType === PORTAL_ACTIVATION">
					<h5>Set Password</h5>
					<p>Get Started with IDcentral for your business</p>
				</ng-container>

				<ng-container *ngIf="portalType === PORTAL_FORGOT_PASS">
					<h5>Reset Password</h5>
				</ng-container>

				<img src="assets/img/IDC_LOGIN_HEADER.png" alt="IDC_IMG_HEADER.png">
			</div>

			<div class="login-form-container">

				<div class="alert alert-danger" *ngIf="this.errorText"><strong>Error </strong>{{errorText}}</div> 

				<form [formGroup]="accountActivationForm" (ngSubmit)="formSubmitted(accountActivationForm.value)">
					<div class="form-group">
						
						<div class="custom-input"
							[ngClass]="(isFormSubmitted || accountActivationForm.controls['newPassword'].touched) && accountActivationForm.controls['newPassword'].errors ? 'error-input' : ''"
						>
							<label>New Password  <span class="required-icon">*</span></label>
							<input 
								type="password" 
								id="new-password" 
								placeholder="Enter new password.." 
								name="newPassword"
								formControlName="newPassword"								
							>
						</div>

						<span *ngIf="(isFormSubmitted || 
						accountActivationForm.controls['newPassword'].touched) && accountActivationForm.controls['newPassword'].errors?.required" class="validation-error">Password is a mandatory field!</span>
					</div>

					<div class="validation-error password-validation-error" 
						*ngIf="(isFormSubmitted || accountActivationForm.controls['newPassword'].touched) && accountActivationForm.controls['newPassword'].errors?.pattern"		
							>
						The password must contain:
						<ul>
							<li>At least one upper case and one lower case letter</li>
							<li>At least one digit</li>
							<li>At least one special character</li>
							<li>Minimum eight in length</li>
						</ul>
					</div>
											
					<div class="form-group">
						<div class="custom-input"
							[ngClass]="(isFormSubmitted || accountActivationForm.controls['confirmPassword'].touched) && accountActivationForm.controls['confirmPassword'].errors ? 'error-input' : ''"
						>
							<label>Confirm Password  <span class="required-icon">*</span></label>
							<input 
								type="password" 
								id="confirm-password" 
								placeholder="Confirm password.." 
								name="confirmPassword"
								formControlName="confirmPassword"
							>
						</div>
						<span *ngIf="(isFormSubmitted || accountActivationForm.controls['confirmPassword'].touched) && accountActivationForm.controls['confirmPassword'].errors?.required" class="validation-error">Confirm Password is a mandatory field!</span>
					</div>	
						
					<button type="submit" class="btn btn-primary full-width-blue-button set-pass-button" #setPasswordButton
					[style.cursor]="isReady ? 'pointer' : 'default'" [style.opacity]="isReady ? 1 : 0.7">
						<ng-container *ngIf="!isReady">
							<app-loader [loaderColor]="'light-grey'"></app-loader>
						</ng-container>
						<ng-container *ngIf="isReady">
							Set Password
						</ng-container>
					</button>
				</form>
			</div>			
		</div>
	</div>
</div>