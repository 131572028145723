import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/app-constants.constants';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  public IDC_LOGO = AppConstants.IDC_LOGO;

  constructor() { }

  ngOnInit() {
  }

}
