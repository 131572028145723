<div id="alert-modal-new" class="modal">
  <div class="modal-content position-relative">

    <!--HEADER-->
    <div class="header">
      <img [src]="alertIcon" class="alert-icon mr-2"> 
      <span class="header-text" *ngIf="alertHeader else showAlertText">{{alertHeader}}</span> 
      <ng-template #showAlertText>
        <span class="text ml-0" [innerHtml]="alertText"></span>
      </ng-template>
      <span class="close" *ngIf="!hideCloseButton" (click)="modalAction.emit('CLOSE')">&#10005;</span>
    </div>

    <!--ALERT TEXT-->
    <div class="text mt-2 mb-2" *ngIf="alertHeader" [innerHtml]="alertText"></div>

    <!--BUTTONS-->
    <div class="button-holder mt-3" *ngIf="!hideButtons">
      <button 
        (type)="button" 
        class="btn btn-primary generic-button-blue yes-button mr-2" 
        (click)="modalAction.emit('YES')">
        <ng-container *ngIf="yes_button_name else yes">{{yes_button_name}}</ng-container>
        <ng-template #yes>Yes</ng-template>
      </button>

      <button *ngIf="!hideCancelButton"
        (type)="button" 
        class="btn btn-primary generic-button-white no-button" 
        (click)="modalAction.emit('CANCEL')">

        <ng-container *ngIf="no_button_name else no">{{no_button_name}}</ng-container>
        <ng-template #no>No</ng-template>

      </button>
    </div>
  </div>
</div>