import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app-constants.constants';
import { BulkUploadStatusService } from '../bulk-upload-status/bulk-upload-status.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor(private router: Router, private _bulkUploadStatusService: BulkUploadStatusService) { }

  /*----------HELPER METHOD TO SET THE USER DATA TO SESSION STORAGE----------*/
  setSession(token,loginDetails) {
    
    window.sessionStorage.setItem('token', token);
    window.sessionStorage.setItem('loginDetails', JSON.stringify(loginDetails));    
  }

  /*----------HELPER METHOD TO FETCH DATA FROM SESSION STORAGE----------*/
  getSession(itemName) {
    return window.sessionStorage.getItem(itemName);
  }

  /*----------HELPER METHOD TO FETCH ACCOUNTTYPE FROM SESSION STORAGE----------*/
  setAccountType(AccountType): void{
    window.sessionStorage.setItem('AccountType', JSON.stringify(AccountType));
  }

  /*----------HELPER METHOD TO FETCH Email FROM SESSION STORAGE----------*/
  setEmail(Email): void{
    window.sessionStorage.setItem('Email', JSON.stringify(Email));
  }

  /*----------HELPER METHOD TO FETCH CLIENT LOGIN DETAILS FROM SESSION STORAGE----------*/
  getClientLoginDetails(userType, key) {
    let loginDetails = null;
    if(userType === 'CLIENT')
    {
      const sessionData = window.sessionStorage.getItem('loginDetails');
      if (sessionData)
      {
        if (key)
        {
          loginDetails = JSON.parse(JSON.parse(window.sessionStorage.getItem('loginDetails')))[key];
        }
        else
        {
          loginDetails = JSON.parse(JSON.parse(window.sessionStorage.getItem('loginDetails')));
        }
      }
    }
    return loginDetails;
  }

  /*----------HELPER METHOD TO LOGOUT THE USER----------*/
  logout() {
    this._bulkUploadStatusService.resetData(); // RESTTING BULK UPLOAD DATA
    this.router.navigateByUrl(AppConstants.LOGIN_URL);
    this.removeSession();
  }

  /*----------HELPER METHOD TO REMOVE THE USER DATA FROM SESSION STORAGE----------*/
  removeSession() {
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('loginDetails');
  } 
}
