<ng-container *ngIf="iconType === 'MONEY_HAND_ICON'">
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" width="20px" height="20px" viewBox="0 0 426.722 426.722" style="enable-background:new 0 0 426.722 426.722;"
    xml:space="preserve" #svgPath>
    <g>
      <g>
        <path d="M368.758,287.617c-21.18,1.309-49.047,22.476-75.924,34.649c-32.945,14.916-84.221,2.924-84.227,2.924
          c7.911-3.712,40.055-11.369,46.529-14.208c34.411-15.146,31.491-46.688,15.103-46.423c-21.651,0.345-34.363,5.659-77.563,11.55
          c-32.747,4.452-71.476,2.824-90.053,9.918c-26.224,10.016-68.456,77.409-68.456,77.409l65.301,63.285
          c0,0,40.427-39.816,60.096-39.816c44.823,0,46.642-0.6,88.255-2.864c17.697-0.947,21.391-1.675,31.527-5.087
          c53.943-18.242,111.875-66.819,112.938-72.662C394.748,292.757,379.947,286.941,368.758,287.617z" />
        <path
          d="M239.811,63.419c9.084-5.406,17.193-12.917,24.35-20.545c6.033-6.422,14.008-14.662,13.145-24.183
          c-0.604-6.702-6.232-10.025-12.385-10.787c-1.979-0.248-3.969-0.312-5.967-0.283c-1.342,0.019-4.057-0.339-5.108,0.683
          c-2.205,2.143-4.397,4.282-6.586,6.439c-0.47-1.463-0.929-2.929-1.394-4.413c-0.274-0.874-1.047-1.211-1.91-1.087
          c-6.075,0.96-12.025,2.155-18.139,1.028c-7.507-1.385-14.895-3.486-22.312-5.223c-0.853-0.206-1.65,0.256-1.917,1.085
          c-0.601,1.906-1.207,3.81-1.805,5.713c-2.621-2.594-5.255-5.158-7.9-7.73c-0.627-0.601-1.492-1.91-2.438-2.068
          c-1.759-0.32-3.525-0.623-5.3-0.902c-2.766-0.431-5.544-0.753-8.336-0.97c-6.132-0.477-17.21-0.58-17.552,7.905
          c-0.322,13.584,11.665,24.348,20.26,33.364c5.32,5.584,11.703,12.042,15.28,19.344c-37.986,12.278-70.376,58.399-70.376,108.015
          c0,57.964,27.508,73.853,89.944,73.853c62.429,0,89.933-15.889,89.933-73.853C303.297,122.215,274.742,78.721,239.811,63.419z
          M239.783,177.332c-2.287,3.413-5.44,6.149-9.062,8.06c-1.91,0.986-3.917,1.771-5.97,2.378c-0.291,0.098-2.97,0.826-2.97,0.826
          v7.489c0,3.484-0.666,7.175-3.787,9.245c-3.411,2.241-8.458,1.572-10.99-1.688c-1.855-2.38-1.961-5.511-1.961-8.401
          c0-2.192,0-6.651,0-6.651s-1.709-0.519-2.075-0.612c-1.199-0.326-2.388-0.713-3.564-1.113c-0.697-0.246-1.379-0.504-2.069-0.775
          c-0.494,0.331-1.013,0.634-1.547,0.899c-0.94,0.466-1.954,0.799-2.991,0.911c-4.103,0.388-7.709-2.053-8.775-6.011
          c-0.668-2.516-0.479-5.211-0.479-7.803c0-2.581-0.196-5.305,0.479-7.841c1.134-4.183,4.997-6.56,9.256-5.954
          c2.002,0.277,3.811,1.262,5.039,2.868c0.648,0.839,1.116,1.811,1.452,2.801c0.312,0.938,0.54,1.897,1.157,2.681
          c1.217,1.532,3.426,2.325,5.212,2.896c2.373,0.767,4.868,1.144,7.36,1.144c3.994,0,8.934-0.856,11.828-3.886
          c1.975-2.083,1.24-5.711-1.486-6.654c-2.598-0.881-4.812-1.328-7.344-1.791c-2.332-0.442-4.666-0.861-6.989-1.299
          c-6.391-1.172-13.042-2.759-17.736-7.583c-6.001-6.15-7.173-15.882-2.713-23.269c2.193-3.631,5.48-6.573,9.261-8.482
          c1.962-0.999,4.053-1.71,6.184-2.212c0.62-0.154,0.54-0.097,0.54-0.654c0-0.47,0-0.956,0-1.444c0-2.287-0.133-4.641,0.072-6.919
          c0.345-4.042,2.537-7.679,6.807-8.375c4.174-0.662,8.068,1.586,9.291,5.665c0.762,2.526,0.568,5.257,0.568,7.879
          c0,1.102,0,2.201,0,3.303c0,0.101,0,0.224,0,0.328c0,0.048,1.084,0.23,1.207,0.253c0.604,0.119,1.207,0.273,1.8,0.438
          c0.487,0.128,1.294,0.584,1.694,0.261c3.438-2.789,8.961-2.683,11.879,0.784c2.176,2.611,2.121,6.77,2.121,9.979
          c0,2.965,0.239,6.099-0.99,8.868c-1.742,3.902-6.379,5.55-10.303,4.101c-1.918-0.689-3.509-2.124-4.412-3.952
          c-0.271-0.535-0.479-1.094-0.639-1.666c-0.072-0.26-0.139-0.524-0.195-0.788c-0.197-0.127-0.401-0.249-0.613-0.355
          c-0.844-0.476-1.743-0.848-2.67-1.153c-2.168-0.686-4.447-0.903-6.708-0.87c-2.086,0.028-4.213,0.204-6.206,0.815
          c-2.503,0.771-6.816,3.8-3.409,6.253c2.032,1.48,4.964,1.889,7.35,2.361c2.124,0.405,4.243,0.83,6.368,1.236
          c6.312,1.244,13.182,2.531,18.23,6.815c-3.438-2.91,4.596,3.847,0,0C244.05,157.175,245.365,168.973,239.783,177.332z" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CHECK_CIRCLE_OUTLINE'">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px">
    <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
    <path #svgPath
      d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CHECK_CIRCLE_OUTLINE_LG'">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
    <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
    <path #svgPath
      d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'TODO_CHECK'">
  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" #svgPath>
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'PEOPLE_ICON'">
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" width="20px" height="20px" viewBox="0 0 44.979 44.979" style="enable-background:new 0 0 44.979 44.979;"
    xml:space="preserve" #svgPath>
    <g>
      <g>
        <path
          d="M28.502,17.371c-0.002,0-11.213-0.004-12.011-0.004c0,0-0.005,0-0.006,0c-1.021,0-1.853,0.846-1.85,1.867l0.04,11.565
          c0.004,1.018,0.83,1.863,1.845,1.863c0.002,0,0.005,0,0.007,0c0.574,0,1.086-0.287,1.423-0.701l0.729,11.256
          c0.064,1.006,0.9,1.762,1.909,1.762h3.804c1.008,0,1.842-0.767,1.908-1.772l0.727-11.276c0.338,0.438,0.849,0.731,1.425,0.731
          c0.002,0,0.003,0,0.007,0c1.016,0,1.841-0.86,1.848-1.871l0.037-11.544C30.347,18.235,29.522,17.371,28.502,17.371z" />
        <circle cx="22.491" cy="11.022" r="5.115" />
        <path d="M11.14,9.006c-0.001,0-8.809-0.003-9.435-0.003c0,0-0.004,0-0.005,0c-0.801,0-1.455,0.665-1.453,1.467l0.031,9.085
          c0.003,0.8,0.652,1.464,1.45,1.464c0.001,0,0.004,0,0.005,0c0.451,0,0.854-0.225,1.118-0.55l0.573,8.841
          c0.051,0.789,0.707,1.383,1.5,1.383h2.989c0.791,0,1.446-0.602,1.499-1.391l0.57-8.859c0.266,0.343,0.667,0.575,1.12,0.575
          c0.001,0,0.002,0,0.005,0c0.798,0,1.446-0.677,1.451-1.47l0.03-9.07C12.589,9.685,11.941,9.006,11.14,9.006z" />
        <circle cx="6.418" cy="4.018" r="4.018" />
      </g>
      <g>
        <path
          d="M33.839,9.006c0.001,0,8.809-0.003,9.436-0.003h0.004c0.802,0,1.455,0.665,1.453,1.467l-0.03,9.085
          c-0.003,0.8-0.652,1.464-1.45,1.464c-0.001,0-0.004,0-0.005,0c-0.451,0-0.854-0.225-1.118-0.55l-0.572,8.841
          c-0.052,0.789-0.707,1.383-1.5,1.383h-2.99c-0.791,0-1.445-0.602-1.498-1.391l-0.57-8.859c-0.266,0.343-0.667,0.575-1.12,0.575
          c-0.001,0-0.002,0-0.005,0c-0.799,0-1.447-0.677-1.451-1.47l-0.029-9.07C32.39,9.685,33.038,9.006,33.839,9.006z" />
        <circle cx="38.562" cy="4.018" r="4.018" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'SETTING_REPORT_ICON'">
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" width="24px" height="24px" viewBox="0 0 134.026 134.026" style="enable-background:new 0 0 134.026 134.026;"
    xml:space="preserve" #svgPath>
    <g>
      <g>
        <path d="M77.941,126.248H21.824c-8.592,0-15.588-6.996-15.588-15.588V42.072c0-8.592,6.997-15.588,15.588-15.588h56.118
        c7.521,0,13.822,5.355,15.271,12.453c2.021-0.566,4.056-1.017,6.102-1.267c-2.046-9.925-10.851-17.421-21.373-17.421H44.856
        c1.449-7.109,7.746-12.47,15.271-12.47h30.279c8.592,0,15.588,6.997,15.588,15.588V37.45c2.101,0.095,4.184,0.362,6.235,0.779
        V23.366c0-12.035-9.785-21.824-21.823-21.824H60.127c-10.96,0-19.979,8.163-21.507,18.706H21.824C9.792,20.248,0,30.037,0,42.072
        v68.588c0,12.032,9.792,21.823,21.824,21.823h56.118c9.463,0,17.445-6.089,20.472-14.51c-2.064-0.299-4.086-0.768-6.077-1.377
        C89.998,122.254,84.433,126.248,77.941,126.248z" />
        <rect x="18.706" y="42.852" width="41.504" height="4.676" />
        <rect x="18.706" y="63.634" width="31.956" height="4.676" />
        <rect x="18.706" y="84.422" width="31.956" height="4.677" />
        <rect x="18.706" y="105.204" width="41.504" height="4.677" />
        <path d="M127.312,76.195l5.718-2.106c0.785-0.286,1.188-1.17,0.907-1.961l-4.762-12.879c-0.293-0.785-1.182-1.196-1.961-0.904
        l-5.705,2.104c-0.798,0.292-1.748,0.161-2.126-0.283c-0.39-0.454-1.181-2.183-0.828-2.936l2.54-5.52
        c0.358-0.761,0.018-1.678-0.749-2.024l-12.459-5.751c-0.767-0.347-1.681-0.012-2.033,0.749l-2.546,5.52
        c-0.341,0.764-1.108,1.357-1.698,1.315c-0.579-0.04-2.375-0.686-2.667-1.474l-2.102-5.718c-0.292-0.786-1.169-1.19-1.96-0.904
        l-12.885,4.752c-0.792,0.292-1.2,1.172-0.907,1.963l2.101,5.712c0.292,0.786,0.17,1.748-0.28,2.125
        c-0.438,0.387-2.162,1.182-2.929,0.831l-5.514-2.545c-0.759-0.356-1.669-0.012-2.024,0.749l-5.755,12.474
        c-0.356,0.755-0.015,1.668,0.746,2.021l5.514,2.545c0.768,0.354,1.358,1.121,1.315,1.693c-0.03,0.584-0.686,2.381-1.474,2.673
        l-5.708,2.101c-0.789,0.293-1.194,1.17-0.904,1.961l4.75,12.891c0.286,0.785,1.172,1.193,1.958,0.907l5.714-2.106
        c0.78-0.293,1.742-0.164,2.126,0.279c0.383,0.439,1.175,2.168,0.828,2.936l-2.546,5.505c-0.353,0.761-0.012,1.681,0.743,2.027
        l12.477,5.755c0.768,0.353,1.681,0.012,2.027-0.75l2.539-5.51c0.354-0.768,1.121-1.353,1.699-1.322
        c0.578,0.037,2.369,0.688,2.661,1.48l2.106,5.699c0.293,0.791,1.176,1.199,1.961,0.913l12.885-4.749
        c0.792-0.293,1.193-1.176,0.907-1.967l-2.101-5.712c-0.292-0.786-0.158-1.747,0.28-2.125c0.45-0.39,2.18-1.182,2.935-0.828
        l5.517,2.545c0.768,0.347,1.675,0.013,2.034-0.749l5.736-12.471c0.358-0.761,0.012-1.68-0.743-2.027l-5.517-2.545
        c-0.756-0.354-1.279-2.192-1.279-2.765C125.875,77.206,126.521,76.487,127.312,76.195z M105.33,91.632
        c-7.903,2.91-16.684-1.139-19.601-9.043c-2.911-7.903,1.139-16.684,9.042-19.604c7.904-2.92,16.685,1.129,19.607,9.033
        C117.289,79.935,113.24,88.715,105.33,91.632z" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'PACKAGE_ICON'">
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 473.8 473.8" style="enable-background:new 0 0 473.8 473.8;" height="24px" width="24px"
    xml:space="preserve" #svgPath>
    <g>
      <path d="M454.8,111.7c0-1.8-0.4-3.6-1.2-5.3c-1.6-3.4-4.7-5.7-8.1-6.4L241.8,1.2c-3.3-1.6-7.2-1.6-10.5,0L25.6,100.9
        c-4,1.9-6.6,5.9-6.8,10.4v0.1c0,0.1,0,0.2,0,0.4V362c0,4.6,2.6,8.8,6.8,10.8l205.7,99.7c0.1,0,0.1,0,0.2,0.1
        c0.3,0.1,0.6,0.2,0.9,0.4c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.7,0.1,1,0.2
        c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0,1.3-0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,1-0.2c0.1,0,0.2-0.1,0.3-0.1
        c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.2-0.1,0.4-0.1c0.3-0.1,0.6-0.2,0.9-0.4c0.1,0,0.1,0,0.2-0.1l206.3-100c4.1-2,6.8-6.2,6.8-10.8
        V112C454.8,111.9,454.8,111.8,454.8,111.7z M236.5,25.3l178.4,86.5l-65.7,31.9L170.8,57.2L236.5,25.3z M236.5,198.3L58.1,111.8
        l85.2-41.3L321.7,157L236.5,198.3z M42.8,131.1l181.7,88.1v223.3L42.8,354.4V131.1z M248.5,442.5V219.2l85.3-41.4v58.4
        c0,6.6,5.4,12,12,12s12-5.4,12-12v-70.1l73-35.4V354L248.5,442.5z" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'USER_ICON'">
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" #svgPath>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'DOLLAR_ICON'">
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" #svgPath>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'ARROW_UP_ICON'">
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" #svgPath>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'ARROW_DOWN_ICON'">
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" #svgPath>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'ARROW_RIGHT_ICON_GREY'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.509"
    height="24.518" viewBox="0 0 13.509 24.518">
    <defs>
      <clipPath id="clip-Custom_Size_1">
        <rect width="13.509" height="24.518" />
      </clipPath>
    </defs>
    <g id="Custom_Size_1" data-name="Custom Size – 1" clip-path="url(#clip-Custom_Size_1)">
      <path id="Path_1" data-name="Path 1" d="M8.624,7.125,20,18.5,8.624,29.875" transform="translate(-7.124 -6.625)"
        fill="none" stroke="#707070" stroke-linejoin="round" stroke-width="2.5" />
    </g>
  </svg>
</ng-container>



<ng-container *ngIf="iconType === 'ARROW_RIGHT_ICON'">
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 17.303 27.536" #svgPath>
    <path id="Path_1" data-name="Path 1" d="M404.4,196.8l12,12-12,12" transform="translate(-402.632 -195.032)"
      fill="none" stroke="#0C4DB2" stroke-width="5" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'ARROW_LEFT_ICON'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="13.509" height="24.518" viewBox="0 0 13.509 24.518">
    <path id="Path_1" data-name="Path 1" d="M20,7.125,8.624,18.5,20,29.875" transform="translate(-7.374 -6.241)"
      fill="none" stroke="#707070" stroke-linejoin="round" stroke-width="2.5" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'ARROW_RIGHT_ICON_LIGHT_BLUE'">
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 17.303 27.536" #svgPath>
    <path id="Path_1" data-name="Path 1" d="M404.4,196.8l12,12-12,12" transform="translate(-402.632 -195.032)"
      fill="none" stroke="#00BCF2" stroke-width="2" />
  </svg>
</ng-container>


<ng-container *ngIf="iconType === 'DOWNLOAD_ICON'">
  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" #svgPath>
    <path
      d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'PLUS_ICON'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'MINUS_ICON'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 13H5v-2h14v2z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'TICK_ICON'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'FILE_ICON'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 0 24 24" width="50">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_PLUS_ICON'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_1" data-name="Group 1" transform="translate(-669 -281)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(669 281)"
        fill="#0C4DB2" />
      <line id="Line_1" data-name="Line 1" y2="30" transform="translate(700.5 296.5)" fill="none" stroke="#fff"
        stroke-width="5" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(715.5 311.5) rotate(90)" fill="none"
        stroke="#fff" stroke-width="5" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_PLUS_ICON_SEC'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_1" data-name="Group 1" transform="translate(-669 -281)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(669 281)"
        fill="#00BCF2" />
      <line id="Line_1" data-name="Line 1" y2="30" transform="translate(700.5 296.5)" fill="none" stroke="#fff"
        stroke-width="5" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(715.5 311.5) rotate(90)" fill="none"
        stroke="#fff" stroke-width="5" />
    </g>
  </svg>
</ng-container>
<ng-container *ngIf="iconType === 'CIRCULAR_PLUS_ICON_DISABLED'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_1" data-name="Group 1" transform="translate(-669 -281)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(669 281)" fill="grey" />
      <line id="Line_1" data-name="Line 1" y2="30" transform="translate(700.5 296.5)" fill="none" stroke="#fff"
        stroke-width="5" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(715.5 311.5) rotate(90)" fill="none"
        stroke="#fff" stroke-width="5" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_PLUS_ICON_SECONDARY'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_1" data-name="Group 1" transform="translate(-669 -281)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(669 281)"
        fill="#00BCF2" />
      <line id="Line_1" data-name="Line 1" y2="30" transform="translate(700.5 296.5)" fill="none" stroke="#fff"
        stroke-width="5" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(715.5 311.5) rotate(90)" fill="none"
        stroke="#fff" stroke-width="5" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_PLUS_ICON_LIGHT_BLUE'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_1" data-name="Group 1" transform="translate(-669 -281)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(669 281)"
        fill="#1DA6C2" />
      <line id="Line_1" data-name="Line 1" y2="30" transform="translate(700.5 296.5)" fill="none" stroke="#fff"
        stroke-width="5" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(715.5 311.5) rotate(90)" fill="none"
        stroke="#fff" stroke-width="5" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_PLUS_ICON_GREY'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_PLUS_ICON_WHITE'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_1" data-name="Group 1" transform="translate(-669 -281)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(669 281)"
        fill="#ffffff" />
      <line id="Line_1" data-name="Line 1" y2="30" transform="translate(700.5 296.5)" fill="none" stroke="#0daadd"
        stroke-width="5" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(715.5 311.5) rotate(90)" fill="none"
        stroke="#0daadd" stroke-width="5" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_MINUS_ICON'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_2" data-name="Group 2" transform="translate(-750 -281)">
      <circle id="Ellipse_2" data-name="Ellipse 2" cx="31" cy="31" r="31" transform="translate(750 281)"
        fill="#0C4DB2" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(796.5 311.5) rotate(90)" fill="none"
        stroke="#fff" stroke-width="5" />
    </g>
  </svg>
</ng-container>


<ng-container *ngIf="iconType === 'CIRCULAR_MINUS_ICON_SECONDARY'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_2" data-name="Group 2" transform="translate(-750 -281)">
      <circle id="Ellipse_2" data-name="Ellipse 2" cx="31" cy="31" r="31" transform="translate(750 281)"
        fill="#00BCF2" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(796.5 311.5) rotate(90)" fill="none"
        stroke="#fff" stroke-width="5" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_MINUS_ICON_LIGHT_BLUE'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_2" data-name="Group 2" transform="translate(-750 -281)">
      <circle id="Ellipse_2" data-name="Ellipse 2" cx="31" cy="31" r="31" transform="translate(750 281)"
        fill="#1DA6C2" />
      <line id="Line_2" data-name="Line 2" y2="30" transform="translate(796.5 311.5) rotate(90)" fill="none"
        stroke="#fff" stroke-width="5" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_CROSS'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_6" data-name="Group 6" transform="translate(-819 -587)">
      <circle id="Ellipse_3" data-name="Ellipse 3" cx="12" cy="12" r="12" transform="translate(819 587)"
        fill="#8e8e8e" />
      <g id="Group_3" data-name="Group 3" transform="translate(-13 -14)">
        <line id="Line_3" data-name="Line 3" y2="14.142" transform="translate(848.893 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="2" />
        <line id="Line_4" data-name="Line 4" y1="14.142" transform="translate(848.893 617.893) rotate(135)" fill="none"
          stroke="#fff" stroke-width="2" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_CROSS_RED'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_6" data-name="Group 6" transform="translate(-819 -587)">
      <circle id="Ellipse_3" data-name="Ellipse 3" cx="12" cy="12" r="12" transform="translate(819 587)"
        fill="#E97077" />
      <g id="Group_3" data-name="Group 3" transform="translate(-13 -14)">
        <line id="Line_3" data-name="Line 3" y2="14.142" transform="translate(848.893 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="2" />
        <line id="Line_4" data-name="Line 4" y1="14.142" transform="translate(848.893 617.893) rotate(135)" fill="none"
          stroke="#fff" stroke-width="2" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_CROSS_RED_XS'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
    <g id="Group_6" data-name="Group 6" transform="translate(-819 -587)">
      <circle id="Ellipse_3" data-name="Ellipse 3" cx="12" cy="12" r="12" transform="translate(819 587)"
        fill="#E97077" />
      <g id="Group_3" data-name="Group 3" transform="translate(-13 -14)">
        <line id="Line_3" data-name="Line 3" y2="14.142" transform="translate(848.893 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="2" />
        <line id="Line_4" data-name="Line 4" y1="14.142" transform="translate(848.893 617.893) rotate(135)" fill="none"
          stroke="#fff" stroke-width="2" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_TICK_GREEN'">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 62 62">
    <g id="Group_5" data-name="Group 5" transform="translate(-691 -587)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(691 587)"
        fill="rgb(31, 185, 0)" />
      <g id="Group_4" data-name="Group 4">
        <line id="Line_1" data-name="Line 1" y2="16" transform="translate(707.893 616.893) rotate(-45)" fill="none"
          stroke="#fff" stroke-width="5" />
        <line id="Line_2" data-name="Line 2" y2="30" transform="translate(738.107 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="5" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_TICK_GREEN_SM'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 62 62">
    <g id="Group_5" data-name="Group 5" transform="translate(-691 -587)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(691 587)"
        fill="rgb(31, 185, 0)" />
      <g id="Group_4" data-name="Group 4">
        <line id="Line_1" data-name="Line 1" y2="16" transform="translate(707.893 616.893) rotate(-45)" fill="none"
          stroke="#fff" stroke-width="5" />
        <line id="Line_2" data-name="Line 2" y2="30" transform="translate(738.107 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="5" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_TICK_GREEN_XS'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 62 62">
    <g id="Group_5" data-name="Group 5" transform="translate(-691 -587)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(691 587)"
        fill="rgb(31, 185, 0)" />
      <g id="Group_4" data-name="Group 4">
        <line id="Line_1" data-name="Line 1" y2="16" transform="translate(707.893 616.893) rotate(-45)" fill="none"
          stroke="#fff" stroke-width="5" />
        <line id="Line_2" data-name="Line 2" y2="30" transform="translate(738.107 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="5" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_TICK_GREY_SM'">
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 62 62">
    <g id="Group_5" data-name="Group 5" transform="translate(-691 -587)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(691 587)"
        fill="rgb(127, 127, 127)" />
      <g id="Group_4" data-name="Group 4">
        <line id="Line_1" data-name="Line 1" y2="16" transform="translate(707.893 616.893) rotate(-45)" fill="none"
          stroke="#fff" stroke-width="5" />
        <line id="Line_2" data-name="Line 2" y2="30" transform="translate(738.107 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="5" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_TICK_ORANGE_SM'">
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 62 62">
    <g id="Group_5" data-name="Group 5" transform="translate(-691 -587)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(691 587)"
        fill="rgb(255,139,71)" />
      <g id="Group_4" data-name="Group 4">
        <line id="Line_1" data-name="Line 1" y2="16" transform="translate(707.893 616.893) rotate(-45)" fill="none"
          stroke="#fff" stroke-width="5" />
        <line id="Line_2" data-name="Line 2" y2="30" transform="translate(738.107 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="5" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'EDIT'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'EDIT_GREY'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill="#b7b7b7"
      d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'DRAGGABLE'">
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="#8E8E8E">
    <path id="Path_2" data-name="Path 2"
      d="M10,20h4V16H10Zm0-6h4V10H10ZM16,4V8h4V4ZM10,8h4V4H10Zm6,6h4V10H16Zm0,6h4V16H16Z"
      transform="translate(-10 -4)" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CIRCULAR_BLUE_TICK'">
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 62 62">
    <g id="Group_5" data-name="Group 5" transform="translate(-691 -587)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="31" cy="31" r="31" transform="translate(691 587)"
        fill="#1DA6C2" />
      <g id="Group_4" data-name="Group 4">
        <line id="Line_1" data-name="Line 1" y2="16" transform="translate(707.893 616.893) rotate(-45)" fill="none"
          stroke="#fff" stroke-width="5" />
        <line id="Line_2" data-name="Line 2" y2="30" transform="translate(738.107 607.893) rotate(45)" fill="none"
          stroke="#fff" stroke-width="5" />
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CALENDAR'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'CASE_SETTINGS'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="14.354" height="13" viewBox="0 0 14.354 13">
    <g id="controls" transform="translate(0 -1.158)">
      <path id="Path_365" data-name="Path 365"
        d="M17.108,3H11.315a4.134,4.134,0,0,1,0,3.589h5.793a1.794,1.794,0,0,0,0-3.589Z"
        transform="translate(-4.547 -0.804)" fill="#1ebaed" />
      <circle id="Ellipse_131" data-name="Ellipse 131" cx="3" cy="3" r="3" transform="translate(0 1.158)"
        fill="#1ebaed" />
      <path id="Path_366" data-name="Path 366"
        d="M7.177,16.794A4.149,4.149,0,0,1,7.587,15H1.794a1.794,1.794,0,1,0,0,3.589H7.587A4.149,4.149,0,0,1,7.177,16.794Z"
        transform="translate(0 -5.627)" fill="#1ebaed" />
      <circle id="Ellipse_132" data-name="Ellipse 132" cx="3" cy="3" r="3" transform="translate(8 8.158)"
        fill="#1ebaed" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'FILTER'">
  <svg id="filter-filled-tool-symbol_1_" data-name="filter-filled-tool-symbol (1)" xmlns="http://www.w3.org/2000/svg"
    width="13" height="13.119" viewBox="0 0 13 13.119">
    <path #svgPath id="Path_131" data-name="Path 131"
      d="M9.347,6.2a.81.81,0,0,1,.213.548v5.966a.405.405,0,0,0,.69.289l1.664-1.907c.223-.267.346-.4.346-.664V6.749a.816.816,0,0,1,.213-.548l4.775-5.182A.608.608,0,0,0,16.8,0H5.018a.607.607,0,0,0-.447,1.019Z"
      transform="translate(-4.41)" fill="#1ebaed" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'SORT_UP'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="14" height="9.33" viewBox="0 0 24 16">
    <path id="Polygon_1" data-name="Polygon 1" d="M12,0,24,16H0Z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'SORT_DOWN'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" width="14" height="9.33" viewBox="0 0 24 16">
    <path id="Polygon_1" data-name="Polygon 1" d="M12,0,24,16H0Z" transform="translate(24 16) rotate(180)" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'SEARCH'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'HAMBURGER_MENU'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'DELETE'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'DELETE_GREY'">
  <svg #svgPath xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill="#b7b7b7" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
  </svg>
</ng-container>

<ng-container *ngIf="iconType === 'ANIMATED_TICK_GREEN'">
  <svg class="animated-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
    <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
      r="62.1" />
    <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round"
      stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
  </svg>
</ng-container>
<ng-container *ngIf="iconType === 'ANIMATED_FAIL_RED'">
  <svg class="animated-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
    <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
      r="62.1" />
    <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10"
      x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
    <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10"
      x1="95.8" y1="38" x2="34.4" y2="92.2" />
  </svg>
</ng-container>
<ng-container *ngIf="iconType === 'ERROR_ICON'">
  <svg id="error-icon" #svgPath width="25px" fill="none" height="25px" fill="red" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
       <symbol id="u" overflow="visible">
        <path d="m18.766-1.125c-0.96875 0.5-1.9805 0.875-3.0312 1.125-1.043 0.25781-2.1367 0.39062-3.2812 0.39062-3.3984 0-6.0898-0.94531-8.0781-2.8438-1.9922-1.9062-2.9844-4.4844-2.9844-7.7344 0-3.2578 0.99219-5.8359 2.9844-7.7344 1.9883-1.9062 4.6797-2.8594 8.0781-2.8594 1.1445 0 2.2383 0.13281 3.2812 0.39062 1.0508 0.25 2.0625 0.625 3.0312 1.125v4.2188c-0.98047-0.65625-1.9453-1.1406-2.8906-1.4531-0.94922-0.3125-1.9492-0.46875-3-0.46875-1.875 0-3.3516 0.60547-4.4219 1.8125-1.0742 1.1992-1.6094 2.8555-1.6094 4.9688 0 2.1055 0.53516 3.7617 1.6094 4.9688 1.0703 1.1992 2.5469 1.7969 4.4219 1.7969 1.0508 0 2.0508-0.14844 3-0.45312 0.94531-0.3125 1.9102-0.80078 2.8906-1.4688z"/>
       </symbol>
       <symbol id="d" overflow="visible">
        <path d="m13.734-11.141c-0.4375-0.19531-0.87109-0.34375-1.2969-0.4375-0.41797-0.10156-0.83984-0.15625-1.2656-0.15625-1.2617 0-2.2305 0.40625-2.9062 1.2188-0.67969 0.80469-1.0156 1.9531-1.0156 3.4531v7.0625h-4.8906v-15.312h4.8906v2.5156c0.625-1 1.3438-1.7266 2.1562-2.1875 0.82031-0.46875 1.8008-0.70312 2.9375-0.70312 0.16406 0 0.34375 0.011719 0.53125 0.03125 0.19531 0.011719 0.47656 0.039062 0.84375 0.078125z"/>
       </symbol>
       <symbol id="b" overflow="visible">
        <path d="m17.641-7.7031v1.4062h-11.453c0.125 1.1484 0.53906 2.0078 1.25 2.5781 0.70703 0.57422 1.7031 0.85938 2.9844 0.85938 1.0312 0 2.082-0.14844 3.1562-0.45312 1.082-0.3125 2.1914-0.77344 3.3281-1.3906v3.7656c-1.1562 0.4375-2.3125 0.76562-3.4688 0.98438-1.1562 0.22656-2.3125 0.34375-3.4688 0.34375-2.7734 0-4.9297-0.70312-6.4688-2.1094-1.5312-1.4062-2.2969-3.3789-2.2969-5.9219 0-2.5 0.75391-4.4609 2.2656-5.8906 1.5078-1.4375 3.582-2.1562 6.2188-2.1562 2.4062 0 4.332 0.73047 5.7812 2.1875 1.4453 1.4492 2.1719 3.3828 2.1719 5.7969zm-5.0312-1.625c0-0.92578-0.27344-1.6719-0.8125-2.2344-0.54297-0.57031-1.25-0.85938-2.125-0.85938-0.94922 0-1.7188 0.26562-2.3125 0.79688s-0.96484 1.2969-1.1094 2.2969z"/>
       </symbol>
       <symbol id="k" overflow="visible">
        <path d="m9.2188-6.8906c-1.0234 0-1.793 0.17188-2.3125 0.51562-0.51172 0.34375-0.76562 0.85547-0.76562 1.5312 0 0.625 0.20703 1.1172 0.625 1.4688 0.41406 0.34375 0.98828 0.51562 1.7188 0.51562 0.92578 0 1.7031-0.32812 2.3281-0.98438 0.63281-0.66406 0.95312-1.4922 0.95312-2.4844v-0.5625zm7.4688-1.8438v8.7344h-4.9219v-2.2656c-0.65625 0.92969-1.3984 1.6055-2.2188 2.0312-0.82422 0.41406-1.8242 0.625-3 0.625-1.5859 0-2.8711-0.45703-3.8594-1.375-0.99219-0.92578-1.4844-2.1289-1.4844-3.6094 0-1.7891 0.61328-3.1016 1.8438-3.9375 1.2383-0.84375 3.1797-1.2656 5.8281-1.2656h2.8906v-0.39062c0-0.76953-0.30859-1.332-0.92188-1.6875-0.61719-0.36328-1.5703-0.54688-2.8594-0.54688-1.0547 0-2.0312 0.10547-2.9375 0.3125-0.89844 0.21094-1.7305 0.52344-2.5 0.9375v-3.7344c1.0391-0.25 2.0859-0.44141 3.1406-0.57812 1.0625-0.13281 2.125-0.20312 3.1875-0.20312 2.7578 0 4.75 0.54688 5.9688 1.6406 1.2266 1.0859 1.8438 2.8555 1.8438 5.3125z"/>
       </symbol>
       <symbol id="c" overflow="visible">
        <path d="m7.7031-19.656v4.3438h5.0469v3.5h-5.0469v6.5c0 0.71094 0.14062 1.1875 0.42188 1.4375s0.83594 0.375 1.6719 0.375h2.5156v3.5h-4.1875c-1.9375 0-3.3125-0.39844-4.125-1.2031-0.80469-0.8125-1.2031-2.1797-1.2031-4.1094v-6.5h-2.4219v-3.5h2.4219v-4.3438z"/>
       </symbol>
       <symbol id="j" overflow="visible">
        <path d="m12.766-13.078v-8.2031h4.9219v21.281h-4.9219v-2.2188c-0.66797 0.90625-1.4062 1.5703-2.2188 1.9844s-1.7578 0.625-2.8281 0.625c-1.8867 0-3.4336-0.75-4.6406-2.25-1.2109-1.5-1.8125-3.4258-1.8125-5.7812 0-2.3633 0.60156-4.2969 1.8125-5.7969 1.207-1.5 2.7539-2.25 4.6406-2.25 1.0625 0 2 0.21484 2.8125 0.64062 0.82031 0.42969 1.5664 1.0859 2.2344 1.9688zm-3.2188 9.9219c1.0391 0 1.8359-0.37891 2.3906-1.1406 0.55078-0.76953 0.82812-1.8828 0.82812-3.3438 0-1.457-0.27734-2.5664-0.82812-3.3281-0.55469-0.76953-1.3516-1.1562-2.3906-1.1562-1.043 0-1.8398 0.38672-2.3906 1.1562-0.55469 0.76172-0.82812 1.8711-0.82812 3.3281 0 1.4609 0.27344 2.5742 0.82812 3.3438 0.55078 0.76172 1.3477 1.1406 2.3906 1.1406z"/>
       </symbol>
       <symbol id="i" overflow="visible">
        <path d="m10.5-3.1562c1.0508 0 1.8516-0.37891 2.4062-1.1406 0.55078-0.76953 0.82812-1.8828 0.82812-3.3438 0-1.457-0.27734-2.5664-0.82812-3.3281-0.55469-0.76953-1.3555-1.1562-2.4062-1.1562-1.0547 0-1.8594 0.38672-2.4219 1.1562-0.55469 0.77344-0.82812 1.8828-0.82812 3.3281 0 1.4492 0.27344 2.5586 0.82812 3.3281 0.5625 0.77344 1.3672 1.1562 2.4219 1.1562zm-3.25-9.9219c0.67578-0.88281 1.4219-1.5391 2.2344-1.9688 0.82031-0.42578 1.7656-0.64062 2.8281-0.64062 1.8945 0 3.4453 0.75 4.6562 2.25 1.207 1.5 1.8125 3.4336 1.8125 5.7969 0 2.3555-0.60547 4.2812-1.8125 5.7812-1.2109 1.5-2.7617 2.25-4.6562 2.25-1.0625 0-2.0078-0.21094-2.8281-0.625-0.8125-0.42578-1.5586-1.0859-2.2344-1.9844v2.2188h-4.8906v-21.281h4.8906z"/>
       </symbol>
       <symbol id="h" overflow="visible">
        <path d="m0.34375-15.312h4.8906l4.125 10.391 3.5-10.391h4.8906l-6.4375 16.766c-0.64844 1.6953-1.4023 2.8828-2.2656 3.5625-0.86719 0.6875-2 1.0312-3.4062 1.0312h-2.8438v-3.2188h1.5312c0.83203 0 1.4375-0.13672 1.8125-0.40625 0.38281-0.26172 0.67969-0.73047 0.89062-1.4062l0.14062-0.42188z"/>
       </symbol>
       <symbol id="g" overflow="visible">
        <path d="m10.047-11.359c1.1016 0 1.8945-0.20312 2.375-0.60938 0.47656-0.41406 0.71875-1.0938 0.71875-2.0312 0-0.92578-0.24219-1.5859-0.71875-1.9844-0.48047-0.40625-1.2734-0.60938-2.375-0.60938h-2.2188v5.2344zm-2.2188 3.6406v7.7188h-5.25v-20.406h8.0312c2.6875 0 4.6562 0.45312 5.9062 1.3594 1.2578 0.89844 1.8906 2.3203 1.8906 4.2656 0 1.3555-0.32812 2.4648-0.98438 3.3281-0.64844 0.86719-1.625 1.5-2.9375 1.9062 0.71875 0.16797 1.3594 0.54297 1.9219 1.125 0.57031 0.57422 1.1484 1.4492 1.7344 2.625l2.8594 5.7969h-5.6094l-2.4844-5.0781c-0.5-1.0195-1.0117-1.7109-1.5312-2.0781-0.51172-0.375-1.1953-0.5625-2.0469-0.5625z"/>
       </symbol>
       <symbol id="a" overflow="visible">
        <path d="m9.6406-12.188c-1.0859 0-1.9141 0.39062-2.4844 1.1719-0.57422 0.78125-0.85938 1.9062-0.85938 3.375s0.28516 2.5938 0.85938 3.375c0.57031 0.77344 1.3984 1.1562 2.4844 1.1562 1.0625 0 1.875-0.38281 2.4375-1.1562 0.57031-0.78125 0.85938-1.9062 0.85938-3.375s-0.28906-2.5938-0.85938-3.375c-0.5625-0.78125-1.375-1.1719-2.4375-1.1719zm0-3.5c2.6328 0 4.6914 0.71484 6.1719 2.1406 1.4766 1.418 2.2188 3.3867 2.2188 5.9062 0 2.5117-0.74219 4.4805-2.2188 5.9062-1.4805 1.418-3.5391 2.125-6.1719 2.125-2.6484 0-4.7148-0.70703-6.2031-2.125-1.4922-1.4258-2.2344-3.3945-2.2344-5.9062 0-2.5195 0.74219-4.4883 2.2344-5.9062 1.4883-1.4258 3.5547-2.1406 6.2031-2.1406z"/>
       </symbol>
       <symbol id="e" overflow="visible">
        <path d="m14.719-14.828v3.9844c-0.65625-0.45703-1.3242-0.79688-2-1.0156-0.66797-0.21875-1.3594-0.32812-2.0781-0.32812-1.3672 0-2.4336 0.40234-3.2031 1.2031-0.76172 0.79297-1.1406 1.9062-1.1406 3.3438 0 1.4297 0.37891 2.543 1.1406 3.3438 0.76953 0.79297 1.8359 1.1875 3.2031 1.1875 0.75781 0 1.4844-0.10938 2.1719-0.32812 0.6875-0.22656 1.3203-0.56641 1.9062-1.0156v4c-0.76172 0.28125-1.5391 0.48828-2.3281 0.625-0.78125 0.14453-1.5742 0.21875-2.375 0.21875-2.7617 0-4.9219-0.70703-6.4844-2.125-1.5547-1.4141-2.3281-3.3828-2.3281-5.9062 0-2.5312 0.77344-4.5039 2.3281-5.9219 1.5625-1.4141 3.7227-2.125 6.4844-2.125 0.80078 0 1.5938 0.074219 2.375 0.21875 0.78125 0.13672 1.5547 0.35156 2.3281 0.64062z"/>
       </symbol>
       <symbol id="t" overflow="visible">
        <path d="m2.3594-21.281h4.8906v11.594l5.625-5.625h5.6875l-7.4688 7.0312 8.0625 8.2812h-5.9375l-5.9688-6.3906v6.3906h-4.8906z"/>
       </symbol>
       <symbol id="s" overflow="visible">
        <path d="m2.3594-15.312h4.8906v15.312h-4.8906zm0-5.9688h4.8906v4h-4.8906z"/>
       </symbol>
       <symbol id="f" overflow="visible">
        <path d="m17.75-9.3281v9.3281h-4.9219v-7.1406c0-1.3203-0.03125-2.2344-0.09375-2.7344s-0.16797-0.86719-0.3125-1.1094c-0.1875-0.3125-0.44922-0.55469-0.78125-0.73438-0.32422-0.17578-0.69531-0.26562-1.1094-0.26562-1.0234 0-1.8242 0.39844-2.4062 1.1875-0.58594 0.78125-0.875 1.8711-0.875 3.2656v7.5312h-4.8906v-15.312h4.8906v2.2344c0.73828-0.88281 1.5195-1.5391 2.3438-1.9688 0.83203-0.42578 1.75-0.64062 2.75-0.64062 1.7695 0 3.1133 0.54688 4.0312 1.6406 0.91406 1.0859 1.375 2.6562 1.375 4.7188z"/>
       </symbol>
       <symbol id="r" overflow="visible">
        <path d="m12.422-21.281v3.2188h-2.7031c-0.6875 0-1.1719 0.125-1.4531 0.375-0.27344 0.25-0.40625 0.6875-0.40625 1.3125v1.0625h4.1875v3.5h-4.1875v11.812h-4.8906v-11.812h-2.4375v-3.5h2.4375v-1.0625c0-1.6641 0.46094-2.8984 1.3906-3.7031 0.92578-0.80078 2.3672-1.2031 4.3281-1.2031z"/>
       </symbol>
       <symbol id="q" overflow="visible">
        <path d="m16.547-12.766c0.61328-0.94531 1.3477-1.6719 2.2031-2.1719 0.85156-0.5 1.7891-0.75 2.8125-0.75 1.7578 0 3.0977 0.54688 4.0156 1.6406 0.92578 1.0859 1.3906 2.6562 1.3906 4.7188v9.3281h-4.9219v-7.9844-0.35938c0.007813-0.13281 0.015625-0.32031 0.015625-0.5625 0-1.082-0.16406-1.8633-0.48438-2.3438-0.3125-0.48828-0.82422-0.73438-1.5312-0.73438-0.92969 0-1.6484 0.38672-2.1562 1.1562-0.51172 0.76172-0.77344 1.8672-0.78125 3.3125v7.5156h-4.9219v-7.9844c0-1.6953-0.14844-2.7852-0.4375-3.2656-0.29297-0.48828-0.8125-0.73438-1.5625-0.73438-0.9375 0-1.6641 0.38672-2.1719 1.1562-0.51172 0.76172-0.76562 1.8594-0.76562 3.2969v7.5312h-4.9219v-15.312h4.9219v2.2344c0.60156-0.86328 1.2891-1.5156 2.0625-1.9531 0.78125-0.4375 1.6406-0.65625 2.5781-0.65625 1.0625 0 2 0.25781 2.8125 0.76562 0.8125 0.51172 1.4258 1.2305 1.8438 2.1562z"/>
       </symbol>
       <symbol id="p" overflow="visible">
        <path d="m17.75-9.3281v9.3281h-4.9219v-7.1094c0-1.3438-0.03125-2.2656-0.09375-2.7656s-0.16797-0.86719-0.3125-1.1094c-0.1875-0.3125-0.44922-0.55469-0.78125-0.73438-0.32422-0.17578-0.69531-0.26562-1.1094-0.26562-1.0234 0-1.8242 0.39844-2.4062 1.1875-0.58594 0.78125-0.875 1.8711-0.875 3.2656v7.5312h-4.8906v-21.281h4.8906v8.2031c0.73828-0.88281 1.5195-1.5391 2.3438-1.9688 0.83203-0.42578 1.75-0.64062 2.75-0.64062 1.7695 0 3.1133 0.54688 4.0312 1.6406 0.91406 1.0859 1.375 2.6562 1.375 4.7188z"/>
       </symbol>
       <symbol id="o" overflow="visible">
        <path d="m2.5781-20.406h5.875l7.4219 14v-14h4.9844v20.406h-5.875l-7.4219-14v14h-4.9844z"/>
       </symbol>
       <symbol id="n" overflow="visible">
        <path d="m2.1875-5.9688v-9.3438h4.9219v1.5312c0 0.83594-0.007813 1.875-0.015625 3.125-0.011719 1.25-0.015625 2.0859-0.015625 2.5 0 1.2422 0.03125 2.1328 0.09375 2.6719 0.070313 0.54297 0.17969 0.93359 0.32812 1.1719 0.20703 0.32422 0.47266 0.57422 0.79688 0.75 0.32031 0.16797 0.69141 0.25 1.1094 0.25 1.0195 0 1.8203-0.39062 2.4062-1.1719 0.58203-0.78125 0.875-1.8672 0.875-3.2656v-7.5625h4.8906v15.312h-4.8906v-2.2188c-0.74219 0.89844-1.5234 1.5586-2.3438 1.9844-0.82422 0.41406-1.7344 0.625-2.7344 0.625-1.7617 0-3.1055-0.53906-4.0312-1.625-0.92969-1.082-1.3906-2.6602-1.3906-4.7344z"/>
       </symbol>
       <symbol id="m" overflow="visible">
        <path d="m2.5781-20.406h8.7344c2.5938 0 4.582 0.57812 5.9688 1.7344 1.3945 1.1484 2.0938 2.7891 2.0938 4.9219 0 2.1367-0.69922 3.7812-2.0938 4.9375-1.3867 1.1562-3.375 1.7344-5.9688 1.7344h-3.4844v7.0781h-5.25zm5.25 3.8125v5.7031h2.9219c1.0195 0 1.8047-0.25 2.3594-0.75 0.5625-0.5 0.84375-1.2031 0.84375-2.1094 0-0.91406-0.28125-1.6172-0.84375-2.1094-0.55469-0.48828-1.3398-0.73438-2.3594-0.73438z"/>
       </symbol>
       <symbol id="l" overflow="visible">
        <path d="m2.3594-15.312h4.8906v15.031c0 2.0508-0.49609 3.6172-1.4844 4.7031-0.98047 1.082-2.4062 1.625-4.2812 1.625h-2.4219v-3.2188h0.85938c0.92578 0 1.5625-0.21094 1.9062-0.625 0.35156-0.41797 0.53125-1.2461 0.53125-2.4844zm0-5.9688h4.8906v4h-4.8906z"/>
       </symbol>
      </defs>
      <g>
       <path d="m348.77 78.414c-8.9453 0.39063-17.066 5.2109-21.523 12.773l-208.95 352.27c-4.6523 7.8906-4.6289 17.598 0.0625 25.465 4.6914 7.8672 13.34 12.703 22.688 12.684h417.9c9.3477 0.019531 17.996-4.8164 22.688-12.684 4.6914-7.8672 4.7148-17.574 0.0625-25.465l-209.12-352.27c-2.3828-4.0469-5.8633-7.3789-10.062-9.6328-4.1953-2.25-8.9492-3.3398-13.738-3.1445zm1.2266 106.4c6.1875 0 11.199 5.0156 11.199 11.199v156.8c0 6.1875-5.0156 11.199-11.199 11.199-6.1875 0-11.199-5.0156-11.199-11.199v-156.8c0-6.1875 5.0156-11.199 11.199-11.199zm0 201.6c9.2773 0 16.801 7.5234 16.801 16.801s-7.5234 16.801-16.801 16.801-16.801-7.5195-16.801-16.801c0-9.2773 7.5234-16.801 16.801-16.801z"/>
      </g>
     </svg>
</ng-container>