import * as CryptoJS from 'crypto-js';

export class EncryptDecryptUtility {

  constructor() {}

  /*----------METHOD TO ENCRYPT----------*/
  encrypt(value, secretKey): string {
    return CryptoJS.AES.encrypt(value, secretKey.trim()).toString();
  }

  /*----------METHOD TO DECRYPT----------*/
  decrypt(value, secretKey) {
    return CryptoJS.AES.decrypt(value, secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }
}
