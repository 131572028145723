<div id="idc-grouped-bar-chart">

  <div class="bar-indicator" *ngIf="keyNames && keyNames.length > 0">
    <div class="all" (click)="filterChart(null,'all')" [ngClass]="selectedTab == 'all' ? 'selected-filter' : ''">
      <span>All</span>
    </div>
    <ng-container *ngFor="let data of keyNames; let i = index">
      <div (click)="filterChart(data, null)" [ngClass]="selectedTab == data ? 'selected-filter' : ''">
        <span class="box" [style.background-color]="colors[i]"></span>
        <span>{{data | titlecase}}</span>
      </div>
    </ng-container>
    
  </div>

    <!--CHART CONTAINER-->
    <div class="chart-container d3-chart" #groupedBarChart>
    </div>
    <!--COLORS-->
    <div class="color-indicator" *ngIf="showColorIndicator">
      <div *ngFor="let data of keyNames; let i = index">
          <span class="box" [style.background-color]="colors[i]"></span>
          <span>{{data}}</span>
      </div>
    </div>
  </div>