import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  public progressWidth;
  public progressColor;
  @Input() public showProgressText = false;
  @Input() public indicatorColor;
  @Input() public set progress(progress: string | number) {
    this.convertToPercentage(progress);
  }
  @Input() showIndicators = false;

  constructor() { }

  /*----------METHOD CHECKS IF THE INPUT PROGRESS IF IN %, IF NOT CONVERTS THE PROGRESS AMOUNT TO PERCENTAGE------------*/
  convertToPercentage(progress) {
    progress = progress.toString();
    const entity = {
      'width': null,
      'background-color': this.indicatorColor ? this.indicatorColor : '#707070'
    };

    this.progressColor = this.indicatorColor ? this.indicatorColor : '#707070';

    if (progress) {
      progress.trim();
      if (!progress.endsWith('%')) {
        entity.width = progress + '%';
      } else {
        entity.width = progress;
      }
    }
    this.progressWidth = entity;
  }

  ngOnInit() {}
}
