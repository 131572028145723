<div class="body-scroll" id="reg-success-os">
  <div class="wrapper clearfix" id="wrapperParallax">
    <!--   
    Header
    =============================================  
    -->
    <!--
    <header class="header header-transparent header-sticky">
      <nav class="navbar navbar-sticky navbar-expand-lg" id="primary-menu"> 
        <div class="container"> <a class="logo navbar-brand" href="index.html"><img class="logo logo-dark" src="assets/images/logo/logo-dark.png" alt="Ebookyo Logo"/><img class="logo logo-light" src="assets/images/logo/logo-light.png" alt="Ebookyo Logo"/></a>
          <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarContent" aria-expanded="false"><span class="navbar-toggler-icon"></span></button>
          <div class="collapse navbar-collapse" id="navbarContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item active"><a class="nav-link" data-scroll="scrollTo" href="#hero">Home</a></li>
              <li class="nav-item"><a class="nav-link" data-scroll="scrollTo" href="#feature">Features</a></li>
              <li class="nav-item"><a class="nav-link" data-scroll="scrollTo" href="#about">about</a></li>
              <li class="nav-item"><a class="nav-link" data-scroll="scrollTo" href="#author">Author</a></li>
              <li class="nav-item"><a class="nav-link" data-scroll="scrollTo" href="#testimonials">Testimonials</a></li>
              <li class="nav-item"><a class="nav-link" data-scroll="scrollTo" href="#pricing">Pricing</a></li>
            </ul>
            <div class="module-container">

              <div class="module module-cta"><a class="btn" data-scroll="scrollTo" href="#action"> <span>Download Now</span></a></div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  -->
    <!-- End Header-->
    <!-- Start hero #1-->
    <section class="hero hero-lead bg-primary-alt" id="hero" style="padding-bottom: 28vh;min-height: 87vh;">
      <div class="container">
        <div class="hero-cotainer">
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="hero-content">
                <p><a class="navbar-brand" href="#"><img src="http://info.idcentral.io/wp-content/uploads/2021/03/logo-white.png" height="60"></a></p>
                <br>
                <h1 class="text-white">Thank you for showing interest in IDcentral's 6-months free eKYC solution.</h1>
<h3 class="text-white">We will reach out to you shortly, on your registered email-id!</h3>
<h5 class="text-white">Please visit <a class="text-white" href="https://www.idcentral.io/">www.idcentral.io</a> for more information!</h5>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>


    <footer class="footer" id="footer" style="padding-top:0px;margin-top:0;">
      
      <div class="footer-bottom">
        <div class="container">
          <hr/>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 text--center">
              <div class="footer-copyright"><span>© Copyright <a href="https://www.idcentral.io/" target="_blank">IDcentral</a> 2021. All Rights Reserved.</span></div>
            </div>
          </div>
        </div>
      </div>
      <!-- End .container  -->
    </footer>
  </div>
  
</div>