import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reg-success-page',
  templateUrl: './reg-success-page.component.html',
  styleUrls: ['./reg-success-page.component.scss']
})
export class RegSuccessComponent {

  constructor(private title: Title) {
    this.title.setTitle('Identity Verification | Id Verification | Digital Onboarding | eKYC - IDcentral');
  }
}
