import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent {

  @Input() public alertObject: any = null; // TODO: CREATE AN INTERFACE

  @Output() public resetAlertEvent = new EventEmitter();

  constructor() {}

  resetAlert() {
    this.alertObject = {
      'title': null,
      'message': null,
      'type': null
    };
    this.resetAlertEvent.emit(this.alertObject);
  }
}
