import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { SessionStorageService } from '../session-storage-service/session-storage-service.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { EncryptDecryptUtility } from 'src/app/shared/utilities/encrypt-decrypt.util';
import { AppConstants } from 'src/app/app-constants.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public DOBformat: any;

  public API_TIMEOUT = AppConstants.API_TIMEOUT; // 30 seconds

  private ProofIdentityData = new BehaviorSubject<string>('');
  private dayLeveldeatils = new BehaviorSubject<string>('');
  private productWisedeatils = new BehaviorSubject<string>('');
  step3val: any;

  constructor(
    private http: HttpClient,
    private _session: SessionStorageService,
    private snackBar: MatSnackBar
  ) {}

  /*----------METHOD TO ADD HEADERS TO API CALLS-----------*/
  private getUserHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION------------*/
  postOperation(url, data): Observable<any> {
    return this.http
      .post(url, data, this.getUserHeader())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------METHOD TO ADD HEADERS TO API CALLS WITH THE API-KEY-----------*/
  private getHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
  }
  private getFormHeader() {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
  }

  /*----------METHOD TO ADD HEADERS TO API CALLS FOR FILE UPLOADs-----------*/
  getHeaderForFileUpload() {
    return {
      reportProgress: true,
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
  }
  /*----------METHOD TO ADD HEADERS TO API CALLS FOR FILE UPLOADs-----------*/
  getHeaderForFileDownload() {
    return {
      reportProgress: true,
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        // 'api-key': this._session.getSession('apiKey')
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
  }

  /*----------METHOD TO FETCH THE Token-----------*/
  private getPassword() {
    const encryptedPass = this._session.getClientLoginDetails(
      'CLIENT',
      'userKey'
    );
    return new EncryptDecryptUtility().decrypt(
      encryptedPass,
      this._session.getSession('token')
    );
  }

  /*----------METHOD TO ADD HEADERS TO API CALLS WITH THE token AND QUERY PARAMS-----------*/ /* method to get casemanagement table data */
  private getHeaderWithQueryParams(queryParams) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
        'Access-Control-Allow-Origin': '*',
      }),
      params: queryParams,
    };
  }

  private getHeaderwithParams() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
        'Access-Control-Allow-Origin': '*',
      }),
    };
  }

  Email: any;
  getAgentSummary(url): Observable<any> {
    this.Email = sessionStorage.getItem('Email');
    const params = new HttpParams().set('email', this.Email);
    // return this.http.get(url + '/' + 'vishal.bangera@subex.com' + '/', this.getHeaderwithParams())
    return this.http
      .get(url, this.getHeaderwithParams())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------METHOD TO ADD THE HOME URL FOR REQUESTED APIS----------*/
  public addLocation(entity) {
    entity.url = window.location.origin;
    return entity;
  }

  /*----------METHOD TO FETCH OUTPUT CONSOLE DATA----------*/
  fetchOutputConsoleData(url, data): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
        'x-api-key': this.getPassword(),
      }),
    };
    return this.http
      .post(url, data, headers)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE GET OPERATION------------*/
  getOperation(url): Observable<any> {
    return this.http
      .get(url)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH CUSTOM API KEY------------*/
  deleteOperationWithApiKey(url): Observable<any> {
    return this.http
      .delete(url, this.getHeader())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }
  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH CUSTOM API KEY------------*/
  getHeaderParams(headerparams: any) {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this._session.getSession('token')}`,
    };
    headers = { ...headerparams, ...headers };
    return {
      headers: new HttpHeaders(headers),
    };
  }

  deleteWithHeaderApiKeyAndParams(url: any, headers: any): Observable<any> {
    return this.http
      .delete(url, this.getHeaderParams(headers))
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }
  /*----------COMMON METHOD TO HANDLE GET OPERATION------------*/
  getOperationWithCustomTimeOut(url, customTimeout): Observable<any> {
    return this.http
      .get(url)
      .pipe(timeout(customTimeout))
      .catch(this.handleError.bind(this));
  }

  /*----------METHOD TO FETCH PDF DATA------------*/
  fetchPdfData(url, data, callType) {
    const headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      Authorization: `Bearer ${this._session.getSession('token')}`,
    });
    const requestOptions = {
      headers: headerOptions,
      responseType: 'blob' as 'blob',
    };
    if (callType === 'get') {
      return this.http
        .get(url, requestOptions)
        .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this));
    } else if (callType === 'post') {
      return this.http
        .post(url, data, requestOptions)
        .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this));
    }
  }

  /*----------METHOD TO FETCH PDF DATA------------*/
  fetchPdfDataWithoutApiKey(url, data, callType) {
    const headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    });
    const requestOptions = {
      headers: headerOptions,
      responseType: 'blob' as 'blob',
    };
    if (callType === 'get') {
      return this.http
        .get(url, requestOptions)
        .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this));
    } else if (callType === 'post') {
      return this.http
        .post(url, data, requestOptions)
        .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this));
    }
  }

  /*----------METHOD TO FETCH PDF DATA------------*/
  fetchCSVData(url, data, callType) {
    const headerOptions = new HttpHeaders({
      'Content-Type': 'application/vnd.ms-excel',
      Accept: 'application/vnd.ms-excel',
    });
    const requestOptions = {
      headers: headerOptions,
      responseType: 'blob' as 'blob',
    };
    if (callType === 'get') {
      return this.http
        .get(url, requestOptions)
        .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this));
    } else if (callType === 'post') {
      return this.http
        .post(url, data, requestOptions)
        .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this));
    }
  }

  /*----------METHOD TO FETCH XLS/CSV DATA------------*/
  getFile(url, data, callType) {
    const headerOptions = new HttpHeaders({
      Authorization: `Bearer ${this._session.getSession('token')}`,
    });
    const requestOptions = {
      headers: headerOptions,
      responseType: 'blob' as 'blob',
    };
    if (callType === 'get') {
      return this.http
        .get(url, requestOptions)
        .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this));
    } else if (callType === 'post') {
      return this.http
        .post(url, data, requestOptions)
        .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this));
    }
  }

  getImage(url, data) {
    const headerOptions = new HttpHeaders({
      Authorization: `Bearer ${this._session.getSession('token')}`,
    });
    const requestOptions = {
      headers: headerOptions,
      responseType: 'blob' as 'blob',
    };
    // else if (callType === 'post') {
    return this.http
      .post(url, data, requestOptions)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
    // }
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION------------*/
  activateAccount(url, data, isNewAccount): Observable<any> {
    return this.http
      .post(url, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: { isNewAccount: isNewAccount },
      })
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------METHOD TO ADD THE CUSTOM HEADERS----------*/
  getCustomHeaders(headerArray, contentType) {
    const entity = {};
    entity['Content-Type'] = contentType;
    for (const x of headerArray) {
      entity[x.key] = x.value;
    }
    return entity;
  }

  /*----------GET METHOD USING CUSTOM HEADERS----------*/
  getUsingCustomHeaders(url, headers): Observable<any> {
    return this.http
      .get(url, {
        headers: new HttpHeaders(
          this.getCustomHeaders(headers, 'application/json')
        ),
      })
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------METHOD TO FETCH THE OCR AND FACE MATCH DATA----------*/
  getOcrData(url, data, isConsentGranted): Observable<any> {
    return (
      this.http
        .post(url, data, {
          headers: new HttpHeaders({
            isConsentGranted: JSON.stringify(isConsentGranted),
            Authorization: `Bearer ${this._session.getSession('token')}`,
          }),
        })
        // .pipe(timeout(this.API_TIMEOUT))
        .catch(this.handleError.bind(this))
    );
  }

  /*----------GET METHOD USING CUSTOM HEADERS----------*/
  postUsingCustomHeaders(url, data, headers): Observable<any> {
    return this.http
      .post(url, data, {
        headers: new HttpHeaders(
          this.getCustomHeaders(headers, 'application/json')
        ),
      })
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------METHOD TO FETCH PDF DATA------------*/
  getUserDetailsByEmail(url, email) {
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          email: email,
        }),
      })
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------METHOD TO FETCH PDF DATA------------*/
  getUserDataForActivation(url, isNewAccount, activationID) {
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          activationID: activationID,
          isNewAccount: isNewAccount.toString(),
        }),
      })
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH CUSTOM API KEY------------*/
  postOperationWithCustomApiKey(url, data, apiKey) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
    return this.http
      .post(url, data, headers)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH CUSTOM API KEY------------*/
  uploadWithCustomApiKey(url, data): Observable<any> {
    return this.http
      .post(url, data, this.getHeaderForFileUpload())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH CUSTOM API KEY------------*/
  uploadWithCustomApiKeyNoTimeout(url, data): Observable<any> {
    return this.http
      .post(url, data, this.getHeaderForFileUpload())
      .catch(this.handleError.bind(this));
  }
  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH CUSTOM API KEY FOR DOWNLOAD FILE------------*/
  downloadWithCustomApiKeyNoTimeout(url: any, data: any): Observable<any> {
    return this.http
      .post(url, data, this.getHeaderForFileDownload())
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE GET OPERATION WITH CUSTOM API KEY------------*/
  getOperationWithCustomApiKey(url, apiKey): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
    return this.http
      .get(url, headers)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE GET OPERATION WITH API KEY------------*/ /* method to get casemanagement table data */
  getOperationWithApiKey(url): Observable<any> {
    return this.http
      .get(url, this.getHeader())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH API KEY------------*/
  postOperationWithApiKey(url, data): Observable<any> {
    return this.http
      .post(url, data, this.getHeader())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH API KEY------------*/
  postOperationWithFormDataAndFile(url: string, data: any): Observable<any> {
    return this.http
      .post(url, data, this.getFormHeader())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH API KEY------------*/
  postOperationWithApiKeyNoTimeout(url, data): Observable<any> {
    return this.http
      .post(url, data, this.getHeader())
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH API KEY------------*/
  patchOperationWithApiKey(url, data): Observable<any> {
    return this.http
      .patch(url, data, this.getHeader())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE GET OPERATION WITH API KEY------------*/
  postOperationWithApiKeyAndQueryParams(url, queryParams): Observable<any> {
    return this.http
      .post(url, null, this.getHeaderWithQueryParams(queryParams))
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------METHOD TO FETCH PANE VIEW DATA----------*/
  getOutputPaneViewData(url, data, customTimeout): Observable<any> {
    return this.http
      .post(url, data, this.getHeader())
      .pipe(timeout(customTimeout))
      .catch(this.handleError.bind(this));
  }

  /*----------GENERIC METHOD TO HANDLE/THROW ERROR MESSAGE------------*/
  private handleError(error: Response | any) {
    if (error.error.message === 'Token Expired') {
      this.snackBar.open('Session Expired. Logged Out', 'close', {
        verticalPosition: 'top',
        panelClass: ['red-snackbar'],
      });
      this._session.logout();
      return throwError(new Error(error.status));
    } else {
      return throwError(error);
    }
  }

  /*---------- METHOD TO Get Documentation URL------------*/
  getViewDocumentationUrl(url): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
    return this.http
      .get(url, headers)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO Get PAN details------------*/
  getPANdetails(url, data) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
    return this.http
      .post(url, data, headers)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO Get Passport details------------*/
  getPassportdetails(url, data) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
    return this.http
      .post(url, data, headers)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO Get Passport details------------*/
  getDLdetails(url, data) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
      }),
    };
    return this.http
      .post(url, data, headers)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------Date of Birth format Changes , The format is yyyy-mm-dd ------------*/

  OnDOBformate(dob) {
    this.DOBformat = dob
      .replace(/\D/g, ' ')
      .replace(/\s{3,}/g, '-')
      .trim()
      .substring(1);
    this.DOBformat = this.DOBformat.split('-').reverse().join('-');
    return this.DOBformat;
  }

  /*..........Method to set the Proof of identity data......*/
  setProofIdentityData(data) {
    this.ProofIdentityData.next(data);
  }

  getProofIdentityData() {
    return this.ProofIdentityData.asObservable();
  }

  /*----------COMMON METHOD TO HANDLE GET KYC status------------*/ /* method to get casemanagement table data */
  getOKYstatus(url): Observable<any> {
    return this.http
      .get(url, this.getHeader())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  /*----------COMMON METHOD TO HANDLE GET PAN Aadhaar linked status------------*/
  getPANInkedstatus(url, data) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._session.getSession('token')}`,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this.http
      .post(url, data, headers)
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }

  //New Dashboard Methods

  /*..........Method to set the day level API uage data......*/
  storedaylevelData(data) {
    this.dayLeveldeatils.next(data);
  }

  getdaylevelData() {
    return this.dayLeveldeatils.asObservable();
  }

  /*..........Method to set the product wise data......*/
  storeproductWiseData(data) {
    this.productWisedeatils.next(data);
  }

  getproductWiseData() {
    return this.productWisedeatils.asObservable();
  }

  storenextStep(val){
    this.step3val = val;
  }

  getnextStep(){
    return this.step3val;
  }

  /*----------COMMON METHOD TO HANDLE POST OPERATION WITH Dashboard aggregated data------------*/
  postDashboardaggregateddata(url, data): Observable<any> {
    return this.http
      .post(url, data, this.getHeader())
      .pipe(timeout(this.API_TIMEOUT))
      .catch(this.handleError.bind(this));
  }
}
