<div id="progress-idc" class="w-100">
  <div class="progress w-100"> 
    <div class="progress-bar" role="progressbar" [style.width]="progressWidth.width" [style.background-color]="progressColor">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="w-100 number-indicators" *ngIf="showIndicators">
    <span></span>
    <span>{{progressWidth.width}}</span>
  </div>
</div>