import { AlertModalEntity } from '../interfaces/alert-modal-entity.interface';

export class ManageAlertTextUtility {
    constructor() {}

    /*----------HELPER METHOD TO CREATE THE ALERT ENTITY----------*/
    getAlertEntity(iconType, subheader, mainText, subText) {
        const entity: AlertModalEntity = {
            'iconType': iconType,
            'subHeader': subheader,
            'alertText': mainText,
            'alertSubText': subText
        };
        return entity;
    }

    /*----------HELPER METHOD TO RESET THE ALERT ENTITY----------*/
    resetAlertEntity(type) {
        type['iconType'] = null;
        type['subHeader'] = null;
        type['alertText'] = null;
        type['alertSubText'] = null;
    }
}
