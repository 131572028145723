import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../../app-constants.constants';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '../../services/session-storage-service/session-storage-service.service';
import { LoginModel } from '../../models/login.model';
import { CommonService } from '../../services/common-service/common-service.service';
import { environment } from 'src/environments/environment';
import { AlertEntity } from 'src/app/shared/interfaces/alert-entity.interface';
import { ErrorConstants } from 'src/app/shared/constants/error.constants';
import { EncryptDecryptUtility } from 'src/app/shared/utilities/encrypt-decrypt.util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnDestroy, OnInit {

  public IDC_LOGO: string = AppConstants.IDC_LOGO_WHITE;
  public LOGIN_PAGE_KEY = 'LOGIN_PAGE';
  public PASSWORD_RESET_PAGE_KEY = 'PASSWORD_RESET_PAGE';
  public ALERT_SUCCESS_KEY = 'SUCCESS';
  public ALERT_ERROR_KEY = 'ERROR';
  public portalType: string = null;
  private encryptDecryptUtility = new EncryptDecryptUtility();
  public isReady = true;

  public pageType: string = this.LOGIN_PAGE_KEY;
  public loginForm: FormGroup;
  public passResetForm: FormGroup;
  public isFormSubmitted = false;
  public alertObject: AlertEntity = {
    'title': null,
    'message': null,
    'type': null
  };

  private subscriptionObj: Subscription;

  constructor(
    public router: Router,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private _sessionStorageService: SessionStorageService,
    private activatedroute: ActivatedRoute) { }

  /*---------HELPER METHOD TO CREATE LOGIN/REGISTRATION/PASSWORD RESET FORM----------*/
  createForm(portalType) {
    /*----------LOGIN FORM----------*/
    if (portalType === 'IDC_CLIENT_APP') {
      this.loginForm = this.fb.group({
        'email': [null, [Validators.required, Validators.pattern(AppConstants.EMAIL_REGEX)]],
        'pass': [null, Validators.required]
      });
    }

    /*----------PASSWORD RESET FORM----------*/
    this.passResetForm = this.fb.group({
      'resetEmail': [null, [Validators.required, Validators.pattern(AppConstants.EMAIL_REGEX)]]
    });
  }

  ngOnInit() {
    /*----------EXTRACTING THE PORTAL TYPE (CLIENT/ADMIN) FROM THE ROUTE DATA----------*/
    this.activatedroute.data.subscribe(data => {
      if (data && data.portalType) {
        this.portalType = data.portalType;
        this.createForm(data.portalType);
      }
    });
  }

  /*----------HELPER METHOD TO SET THE PAGE TYPE-----------*/
  setPageType(selectedPageType) {
    this.isReady = true;
    this.isFormSubmitted = false;
    this.pageType = selectedPageType;
  }

  /*----------HELPER METHOD TO SET THE ALERT OBJECT-----------*/
  setAlert(title, message, type) {
    this.alertObject.title = title;
    this.alertObject.message = message;
    this.alertObject.type = type;
  }

  /*------------METHOD TRIGGERRED WHEN USER SUBMITS RESET PASSWORD FORM----------*/
  resetPassword(formValue) {
    if (this.isReady) {
      this.isFormSubmitted = true;
      if (this.passResetForm.valid) {
        this.isReady = false;
        const entity = {
          'email': formValue.resetEmail
        };
        this.subscriptionObj = this._commonService.postOperation(environment.PASSWORD_RESET, this._commonService.addLocation(entity))
          .subscribe((response) => {
            this.isReady = true;
            if (response) {
              if (response.status === 'success') {
                this.setAlert('Success', ErrorConstants.PASSWORD_RESET_MSG, this.ALERT_SUCCESS_KEY);
              } else if (response.status === 'failed' && response.error) {
                this.setAlert('Error', response.error, this.ALERT_ERROR_KEY);
              } else {
                this.setAlert('Error', ErrorConstants.UNEXPECTED_ERROR_MSG, this.ALERT_ERROR_KEY);
              }
            } else {
              this.setAlert('Error', ErrorConstants.UNEXPECTED_ERROR_MSG, this.ALERT_ERROR_KEY);
            }
          }, (error: Error) => {
            this.isReady = true;
            this.setAlert('Error', ErrorConstants.UNEXPECTED_ERROR_MSG, this.ALERT_ERROR_KEY);
          });
      }
    }
  }

  /*------------METHOD TRIGGERRED WHEN USER CLICKS ON LOGIN----------*/
  login(formValue) {
    this.isFormSubmitted = true;
    if (this.loginForm.valid && this.isReady) {
      this.loginUser(formValue);
    }
  }

  /*----------METHOD TRIGGERRED WHEN USER TRIES TO LOGIN----------*/
  loginUser(formValue) {
    if (formValue && formValue.email && formValue.pass && '' !== formValue.email && '' !== formValue.pass) {
      this.isReady = false;

      const loginUrl: string = environment.LOGIN_URL;
      const loginData = new LoginModel(formValue.email,
        this.encryptDecryptUtility.encrypt(formValue.pass, AppConstants.IDC_DEFAULT_ENCRYPTION_KEY));

      this.subscriptionObj = this._commonService.postOperation(loginUrl, loginData).subscribe((response) => {

        this.isReady = true;
        if (undefined !== response && null !== response && undefined !== response.data && null !== response.data
          && undefined !== response.data.token && null !== response.data.token && '' !== response.data.token) {
          if (response.status === 'success') {
            response.data.userKey = this.encryptDecryptUtility.encrypt(formValue.pass, response.data.token);
            this._sessionStorageService.setSession(response.data.token, JSON.stringify(response.data));
            this._sessionStorageService.setAccountType(JSON.stringify(response.data.accountType));
            this._sessionStorageService.setEmail(JSON.stringify(response.data.email));
            this.router.navigate(['/home'], { replaceUrl: true });
          }        
        } else {
          if (undefined !== response.error && null !== response.error) {
            if (response.error === 'Password not Yet Generated') {
              this.setAlert('Login Failed', ErrorConstants.PASSWORDS_NOT_GENERATED_YET, this.ALERT_ERROR_KEY);
            } else {
              this.setAlert('Login Failed', response.error, this.ALERT_ERROR_KEY);
            }
          } else {
            this.setAlert('Login Failed', ErrorConstants.UNEXPECTED_ERROR_MSG, this.ALERT_ERROR_KEY);
          }
        }
      },
        (error: Error) => {
          this.isReady = true;
          this.setAlert('Login Failed', ErrorConstants.UNEXPECTED_ERROR_MSG, this.ALERT_ERROR_KEY);
        });
    }
  }

  ngOnDestroy() {
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
    }
  }
}
