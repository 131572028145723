import { URL_CONSTANTS } from './app-base-url.constants';

export class AppConstants {
  public static IDC_LOGO = 'assets/img/logos/IDC_NEW_LOGO.png';
  public static IDC_LOGO_WHITE = 'assets/img/logos/IDC_NEW_LOGO_WHITE.png';

  public static IDC_HELP_MAIL_ID = 'support@idcentral.io';
  public static IDC_SUPPORT_MAIL_ID = 'support@idcentral.io';
  public static PRIVACY_POLICY = 'https://www.idcentral.io/privacy-policy/';
  public static IDC_ONBOARDING_PAGE_TITLE =
    'Identity Verification Solution | Id Verification Software| Digital Onboarding | eKYC | OCR - IDcentral';
  public static IDC_ONBOARDING_PAGE_DESC =
    "Get IDcentral's 6-months free e-KYC solution and redesign your customer’s digital experience! It is an AI based, self-serve platform with super simple integration which can help you save huge costs and protect your system from the rising fear of identity thefts.";
  public static IDC_LANDING_PAGE_URL =
    'https://info.idcentral.io/free-ekyc-solution/';

  /*----------VALIDATION REGEX----------*/
  // public static NAME_REGEX = '^(.*[a-z]){3}$'; // Minimum 3 characters. No numbers
  public static NAME_REGEX = '^([a-zA-Z ]*)$'; // Only alphabets and spaces
  public static ORG_REGEX = '^.{3,}$'; // Minimum 3 length
  public static EMAIL_REGEX = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  public static PHONE_REGEX = '^[0-9]{10}$';
  public static ONLY_DIGITS_REGEX = '^[0-9]+[0-9]$';
  public static POSITIVE_DECIMAL = '^[0-9]([0-9]+(?:[.][0-9]*)?|.[0-9]+)$';
  public static OFFICIAL_EMAIL_REGEX =
    '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  /*---------PASSWORD VALIDATION REGEX----------*/
  // At least one upper case English letter, (?=.*?[A-Z])
  // At least one lower case English letter, (?=.*?[a-z])
  // At least one digit, (?=.*?[0-9])
  // At least one special character, (?=.*?[#?!@$%^&*-])
  // Minimum eight in length .{8,} (with the anchors)
  public static PASSWORD_REGEX =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

  /*--------URLS FOR API----------*/
  public static BASE_URL = URL_CONSTANTS.BASE_URL;
  public static BASE_URL_ADMIN = URL_CONSTANTS.BASE_URL_ADMIN_PROD; // PROD SETTING --> ENABLE PROD URLS
  public static BASE_URL_USER = URL_CONSTANTS.BASE_URL_USER_PROD;

  

  public static KYCX_APP_LINK =
    'https://play.google.com/store/apps/details?id=com.idcentral.kycx';
  public static OS_VALID_EXTENSIONS_FOR_UPLOAD = [
    'jpeg',
    'jpg',
    'jpe',
    'jif',
    'jfif',
    'jfi',
    'png',
  ];
  public static IDC_DEFAULT_ENCRYPTION_KEY = 'defaultKey';
  public static PANE_VIEW_API_TIMEOUT = '90000'; // 90 SECONDS
  public static API_TIMEOUT = 90000; // 90 seconds

  public static LOGIN_URL = '/login';
  public static HOME_URL = '/home';
  public static PROFILE_URL = '/home/profile';
  public static LANDING_PAGE_SET_PASSWORD_LINK = '/app/#/set-password/'; // PROD SETTING --> REMOVE # :: /app/#/set-password/
  public static AML_SEARCH_DETAILS_LINK = 'home/aml/case-management/result/';
  public static AML_SEARCH_LINK = 'home/aml/search';
  public static FACE_ID_VIEW = 'home/face-id/view';
  public static ACCOUNT_VIEW = 'home/usage';
  public static AML_SEARCH_LIST_LINK = 'home/aml/case-management';
  public static VERIFICATION_INDICATOR_MATECHED =
    'assets/img/verification-indicators/matched.svg';
  public static VERIFICATION_INDICATOR_NOT_MATCHED =
    'assets/img/verification-indicators/not_found.svg';
  public static VERIFICATION_INDICATOR_UNAVALABLE =
    'assets/img/verification-indicators/not_available.svg';

  /*----------BULK UPLOAD TIMERS----------*/
  public static BU_PROCESSING_TIME = [
    {
      fromParam: 1,
      toParam: 99,
      time: 120, // 2 minutes // 120 seconds
      processingTime: '1-2 minutes',
    },
    {
      fromParam: 100,
      toParam: null,
      time: 240, // 4 minutes // 120 seconds
      processingTime: '3-4 minutes',
    },
  ];
}
