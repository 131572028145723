import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-idc-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  public startIndex = 1;
  public pageNumber = null;
  public endPageIndex: number = null;
  public paginationTotalRecords = 0;

  @Input('autoHide') public autoHide = false;
  @Input('currentPage') public paginationCurrentPage = 1;
  @Input('threshold') public paginationThreshold = 10;

  @Input('totalRecords') public set setPaginationTotalRecords(totalRecords: number) {
    if (totalRecords) {
      this.paginationTotalRecords = totalRecords;
      this.endPageIndex = Math.ceil(this.paginationTotalRecords / this.paginationThreshold);
    }
  }

  @Output() public pageChangeEvent = new EventEmitter();

  public PAGINATION_FIRST_PAGE = 'FIRST_PAGE';
  public PAGINATION_PREV_PAGE = 'PREV_PAGE';
  public PAGINATION_CURR_PAGE = 'CURRENT_PAGE';
  public PAGINATION_NEXT_PAGE = 'NEXT_PAGE';
  public PAGINATION_LAST_PAGE = 'LAST_PAGE';

  constructor() {}

  paginate(selectionType) {
    this.pageNumber = null;
    if (selectionType === this.PAGINATION_FIRST_PAGE) {
      this.pageNumber = 1;
    } else if (selectionType === this.PAGINATION_LAST_PAGE) {
      this.pageNumber = this.endPageIndex;
    } else if (selectionType === this.PAGINATION_NEXT_PAGE) {
      if (this.paginationCurrentPage < this.endPageIndex) {
        this.pageNumber = this.paginationCurrentPage + 1;
      }
    } else if (selectionType === this.PAGINATION_PREV_PAGE) {
      if (this.paginationCurrentPage > this.startIndex) {
        this.pageNumber = this.paginationCurrentPage - 1;
      }
    }

    if (this.pageNumber) {
      this.pageChangeEvent.emit(this.pageNumber);
    }
  }
}
