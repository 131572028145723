import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-alert-modal-new',
  templateUrl: './alert-modal-new.component.html',
  styleUrls: ['./alert-modal-new.component.scss']
})
export class AlertModalNewComponent {

  public ERROR_ICON = 'assets/img/modal-icons/error.svg';
  public SUCCESS_ICON = 'assets/img/modal-icons/success.svg';
  public INFO_ICON = 'assets/img/modal-icons/info.svg';
  public alertIcon;

  // WARNING - SUCCESS - INFO
  @Input('iconType') public set setIconType(iconType){
    if(iconType === 'WARNING') {
      this.alertIcon = this.ERROR_ICON;
    }
    else if(iconType === 'SUCCESS') {
      this.alertIcon = this.SUCCESS_ICON;
    }
    else if(iconType === 'INFO') {
      this.alertIcon = this.INFO_ICON;
    }
  }

  @Input() alertHeader: string = null;
  @Input() alertText: string = null;

  @Input() yes_button_name: string = null;
  @Input() no_button_name: string = null;

  @Input() hideCloseButton = false;
  @Input() hideCancelButton = false;
  @Input() hideButtons = false;
  
  @Output() modalAction = new EventEmitter;
}
