import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { SessionStorageService } from './core/services/session-storage-service/session-storage-service.service';
import { LoginComponent } from './core/authentication/login/login.component';
import { CommonService } from './core/services/common-service/common-service.service';
import { ActivateAccountComponent } from './core/components/activate-account/activate-account.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { HttpXsrfInterceptor } from './core/services/http-xsrf-interceptor/http-xsrf.interceptor';
import { BulkUploadService } from './core/services/bulk-upload-service/bulk-upload.service';
import { SharedModule } from './shared/shared.module';
import { LandingPageComponent } from './core/components/landing-page/landing-page.component';
import { RegisterFormComponent } from './core/components/landing-page/register-form/register-form.component';
import { PrivacyPolicyComponent } from './core/components/landing-page/privacy-policy/privacy-policy.component';
import { PaymentGatewayComponent } from './core/components/payment-gateway/payment-gateway.component';
import { SetPasswordComponent } from './core/components/set-password/set-password.component';
import { TitleCasePipe } from '@angular/common';
import { TermsAndConditionsComponent } from './core/components/set-password/terms-and-conditions/terms-and-conditions.component';
import { RegSuccessComponent } from './core/components/landing-page/reg-success-page/reg-success-page.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ActivateAccountComponent,
    PageNotFoundComponent,
    LandingPageComponent,
    RegisterFormComponent,
    RegSuccessComponent,
    PrivacyPolicyComponent,
    PaymentGatewayComponent,
    SetPasswordComponent,
    TermsAndConditionsComponent,
    
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    MatSnackBarModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
    }),
    AppRouting
  ],
  providers: [
    CommonService,
    SessionStorageService,
    BulkUploadService,
    TitleCasePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
