import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent implements OnInit {

  @Input() isActive = true;
  @Input() type = 'SET-1';
  @Input() isDisabled = false;

  @Output() public switchEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {}

}
