import { Component, ElementRef, ViewChild, Input, AfterContentInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-idc-stacked-bar-chart',
  templateUrl: './idc-stacked-bar-chart.component.html',
  styleUrls: ['./idc-stacked-bar-chart.component.scss']
})
export class IdcStackedComponent implements AfterContentInit {

	@Input('data') public chartData;
  	public data = [];
	@ViewChild('stackedBarChart', { static: true }) private chartContainer: ElementRef;
	@Input('width') private w = 0;	
	@Input('height') private h = 340;
	@Input('margin') margin = {top: 50, right: 50, bottom: 0, left: 100};
	@Input('colors') colors = ['#baddf3', '#91c3e1', '#6da2c7', '#588cb3', '#35648e', '#235781'];
	@Input('metricName') public metricName = 'metric';
	@Input() public showColorIndicator = true;

	private width = null;
	private height = null;
	private x: any;
	private y: any;
	private svg: any;
	private g: any;
	private stack: any;
	private chart: any;
	private layersBarArea: any;
	private layersBar: any;
	private xAxis: any;
	private yAxis: any;
	private legend: any;
	private legendItems: any;
	private tooltip: any;
	private stackedSeries: any;
	public colorIndicator = [];
	public selectedTab = 'all';

	constructor(private container: ElementRef) {}

	ngAfterContentInit() {
		this.data = [...this.chartData];
		this.renderChart(false);
	}

	/*----------METHOD TO FILTER THE CHART BASED ON THE SELECTED TAB----------*/
	filterChart(data, type) {
		this.selectedTab = null;
		if(type === 'all') {
			this.selectedTab = 'all';
			this.data = [...this.chartData];
			// REMOVING THE EXISTING SVG
			d3.select(this.container.nativeElement)
			.select('.chart-container').selectAll('svg').remove();
			this.renderChart(true);
		} else {
			if (data) {
				this.selectedTab = data;
				const filteredArray = [];
				for(const x of this.chartData) {
					const entity = {};
					entity[data] = x[data];
					entity[this.metricName] = x[this.metricName];
					filteredArray.push(entity);
				}
				this.data = [...filteredArray];
				d3.select(this.container.nativeElement)
				.select('.chart-container').selectAll('svg').remove();
				this.renderChart(true);
			}
		}
	}

	/*----------METHOD TO RENDER THE CHART----------*/
	renderChart(isFilter) {
		this.w = this.chartContainer.nativeElement.offsetWidth - 20;
		this.width = this.w - this.margin.left - this.margin.right;
		this.height = this.h - this.margin.top - this.margin.bottom;
		const keys = [];
		const allKeys = Object.keys(this.data[0]);
		let z = 0;
		for(let x=0; x<allKeys.length; x++)
		{
			if(allKeys[x] !== this.metricName)
			{
				keys.push(allKeys[x]);
				if(!isFilter)
				{
					this.colorIndicator.push({
						'color': this.colors[z],
						'labelName': allKeys[x]
					});
					z++;
				}
			}
		}

		this.stack = d3.stack().keys(keys);
		this.initScales();
		this.initSvg();
		this.createStack(this.data);
		this.drawAxis();
	}

	private initScales(){
		this.x = d3.scaleBand()
			.rangeRound([0, this.width])
			.padding(0.05);

		this.y = d3.scaleLinear()
			.range([this.height, 0]);
	}

	private initSvg() {

		this.tooltip = d3.select(this.container.nativeElement).append('div')
			.classed('chart-tooltip', true)
			.style('display', 'none');
	  

		this.svg = d3.select(this.container.nativeElement)
			.select('.chart-container')
			.append('svg')
			.attr('preserveAspectRatio', 'xMinYMin meet')
			.attr('class', 'chart')
			.attr('width', this.w)
			.attr('height', this.h)
			.attr('viewBox', '0 0 600 400');

		this.chart = this.svg.append('g')
			.classed('chart-contents', true)
			.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

		this.layersBarArea = this.chart.append('g')
			.classed('layers', true);
	}

	private drawAxis() {
		this.xAxis = this.chart.append('g')
			.classed('x axis', true)
			.attr('transform', 'translate(0,' + this.height + ')')
			.call(d3.axisBottom(this.x));

		this.chart.append('text')
			.attr('y', this.height + 40)
			.attr('x', (this.width/2))
			.classed('axis-title', true)
			.style('text-anchor', 'middle')
			.style('stroke', 'none')
			.text('Date');

		this.yAxis = this.chart.append('g')
			.classed('y axis', true)
			.call(d3.axisLeft(this.y)
				.ticks(7));

		this.chart.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('y', 0 - 60)
			.attr('x', 0 - (this.height / 2))
			.style('text-anchor', 'middle')
			.style('stroke', 'none')
			.classed('axis-title', true)
			.text('Count');
	}

	private createStack(stackData:any){
		this.stackedSeries = this.stack(stackData);
		this.drawChart(this.stackedSeries);
	}

	private drawChart(data:any){
		this.layersBar = this.layersBarArea.selectAll('.layer')
			.data(data)
			.enter()
			.append('g')
			.classed('layer', true)
			.style('fill', (d:any,i:any)=>{
				return this.colors[i];
			});

		this.x.domain(this.data.map((d:any)=>{
			return d[this.metricName];
		}));
		
		this.y.domain([0, +d3.max(this.stackedSeries, function(d:any){
			return d3.max(d, (_d:any)=>{
				return _d[1];
			});
		})]);

		const bars = this.layersBar.selectAll('rect')
			.data((d:any)=>{
				return d;
			})
			.enter()
			.append('rect')
			.attr('test', (d:any)=>{
				return this.y(d[1]);
			})
			.attr('y', (d:any)=>{
				return this.y(d[1]);
			})
			.attr('x', (d:any, i:any)=>{
				return this.x(d.data[this.metricName]);
			})
			.attr('width', this.x.bandwidth())
			.attr('height', (d:any, i:any)=>{
				return this.y(d[0]) - this.y(d[1]);
			});

			this.addTooltip(bars);
	}

	/*-----------METHOD TO ADD THE TOOLTIP-----------*/
	addTooltip(bars) {
		bars.transition()
		.ease(d3.easeCubic)
		.duration(1000)
		.attr('height', (d: any, i: any) => {
			return this.y(d[0]) - this.y(d[1]);
		})
		.attr('y', (d: any) => {
			return this.y(d[1]);
		});
		bars.on('mouseover', (d, i, b)=>
		{
			d3.select(this.container.nativeElement).select('.chart-tooltip').style('display', null);
		})
		.on('mouseout', ()=>{
			d3.select(this.container.nativeElement).select('.chart-tooltip').style('display', 'none');
		})
		// .on('mousemove', (d:any)=>{
		// 	// let key = d3.select((<HTMLElement>event.target).parentNode).datum()['key'];
		// 	key = key + ': ';
		// 	d3.select(this.container.nativeElement).select('.chart-tooltip')
		// 		.style('left', d3.event.pageX - 20 + 'px')
		// 		.style('top', d3.event.pageY - 50 + 'px')
		// 		.text(key + (d[1] - d[0]));
		// });  
	}
}
