import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataUriToBlobUtil } from '../../utilities/data-uri-to-blob.util';
@Component({
  selector: 'app-web-capture',
  templateUrl: './web-capture.component.html',
  styleUrls: ['./web-capture.component.scss']
})
export class WebCaptureComponent implements OnInit {

  @Input() title: string;
  @Output() capturedData = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Input() fileSizeLimit = null; 

  public showWebcam = false;
  @Input() faceValidationRequired = false;
  public capturedImage = null;
  public capturedImageMultipart = null;
  public compressedImage = null;
  public errorText = null; 
  public imgResultBeforeCompress: string;
  public imgResultAfterCompress: string;
  public livenessResult = null;
  public _dataUriToBlobUtil = new DataUriToBlobUtil();

  constructor() {}

  ngOnInit() {
    this.startWebcam();
  }

  /*----------METHOD TO CAPTURE THE CLICKED IMAGE DATA----------*/
  imageCaptured($event) {
    this.capturedImage = $event.rawData;
    this.capturedImageMultipart = $event.blobData;
    this.compressedImage = $event.compressedBlobData;
  }
  
  /*----------METHOD TO CAPTURE THE CLICKED IMAGE DATA----------*/
  imageCapturedWithFaceValidation($event) {
    this.showWebcam = false;
    this.capturedImage = $event.capturedImage;
    this.capturedImageMultipart = $event.blobData;
    this.compressedImage = $event.compressedBlobData;
    this.livenessResult = $event.result;
  }
  
  /*----------METHOD TO INITIATE THE WEBCAM COMPONENT----------*/
  startWebcam() {
    this.capturedImage = null;
    this.capturedImageMultipart = null;
    this.showWebcam = true;
  }

  resizeMe(img) {
    const canvas = <HTMLCanvasElement>document.getElementById('web-cam-canvas-preview');
    let width = img.width;
    let height = img.height;
  
    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > 300) {
        // height *= max_width / width;
        height = Math.round(height *= 300 / width);
        width = 300;
      }
    } 
    // else {
    //   if (height > max_height) {
    //     //width *= max_height / height;
    //     width = Math.round(width *= max_height / height);
    //     height = max_height;
    //   }
    // }
    
    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL('image/jpeg',0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
  }

  /*----------METHOD TO PASS THE CAPTURED IMAGE DATA TO PARENT COMPONENT----------*/
  confirmUpload() {
    const entity = {
      'imageSrc': this.capturedImage,
      'capturedImageMultipart': this.capturedImageMultipart,
      'compressedFile': this.compressedImage,
      'withFaceValidation': this.faceValidationRequired,
      'data': this.faceValidationRequired ? this.livenessResult : null
    };
    this.capturedData.emit(entity);
  }
}
