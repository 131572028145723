import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-idc-dynamic-table',
  templateUrl: './idc-dynamic-table.component.html',
  styleUrls: ['./idc-dynamic-table.component.scss']
})
export class IdcDynamicTableComponent implements OnInit {

  @Input() public tableHeaders: string[] = [];
  @Input() public isReady = false;
  @Input() public tableData: any[] = [];
  @Input('pageNumber') public p = 1;
  @Input('paginationThreshold') public paginationThreshold = 10;

  constructor() {}

  ngOnInit() {}
}
