// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppComponent } from 'src/app/app.component';
import { AppConstants } from '../app/app-constants.constants';

export const environment = {
  production: false,

  // RESET_PASSWORD_URL:  AppConstants.BASE_URL + '/changePassword',
  UPDATE_PAYMENT: AppConstants.BASE_URL + '/updatePayment',
  GET_BILLING_INVOICE: AppConstants.BASE_URL + '/downloadBill',
  GET_CREDIT_LIMIT: AppConstants.BASE_URL + '/getCreditLimit',
  APPROVE_CREDIT_LIMIT_API: AppConstants.BASE_URL + '/getCreditLimitApproval',
  GET_TRACKING_STATUS: AppConstants.BASE_URL + '/getTrackingStatus',
  GET_TRACKING_URL_BY_QUERY: AppConstants.BASE_URL + '/getAllTrackingDetails?',
  GET_TRANSACTION_LOG: AppConstants.BASE_URL + '/getAllTrackingDetails',
  GET_BILLING_HISTORY: AppConstants.BASE_URL + '/getBillingHistory',
  GET_API_FREQUENCY: AppConstants.BASE_URL + '/getAPICount',
  GET_BUCKET_WISE_BILLED_AMOUNT:
    AppConstants.BASE_URL + '/getBucketWiseBilledAmount',

  GET_USER_DETAIL_BY_EMAIL: AppConstants.BASE_URL + '/showUserDetail',
  GET_PARTNER_COUNT_STATUS: AppConstants.BASE_URL + '/getPartnerCountStatus',
  GET_COST_DASHBOARD_CSV: AppConstants.BASE_URL + '/getDashboardReport',
  GET_AVAILABLE_PARTNERS: AppConstants.BASE_URL + '/getAvailablePartners',
  GET_PARTNER_DATA: AppConstants.BASE_URL + '/getDataPartnerData',
  ADD_NEW_DATA_PARTNER: AppConstants.BASE_URL + '/addNewDataPartner',

  /*----------USER----------*/
  GET_SUBSCRIPTION_DATA: AppConstants.BASE_URL_USER + '/trackData',
  GET_PAYMENT_DETAILS: AppConstants.BASE_URL_USER + '/paymentStatus',

  LOGIN_URL: AppConstants.BASE_URL_USER + '/login',
  ACTIVATE_ACCOUNT_URL: AppConstants.BASE_URL_USER + '/setPassword',
  PASSWORD_RESET: AppConstants.BASE_URL_USER + '/forgotPassword',

  /*----------BULK UPLOAD----------*/
  GET_BUCKET_PRICE: AppConstants.BASE_URL_USER + '/report/availableModule',
  BULK_UPLOAD_FILE: AppConstants.BASE_URL_USER + '/report/upload',
  BULK_UPLOAD_VERIFICATION_MODULE:
    AppConstants.BASE_URL_USER + '/report/ktp/upload',
  BULK_UPLOAD_AML_MODULE: AppConstants.BASE_URL_USER + '/report/aml/upload',
  BULK_UPLOAD_DOWNLOAD_TEMPLATE:
    AppConstants.BASE_URL_USER + '/report/downloadTemplate',
  GET_COMPLETE_REPORT_BULK_UPLOAD:
    AppConstants.BASE_URL_USER + '/report/compleatedReport',
  DOWNLOAD_REPORT_BULK_UPLOAD:
    AppConstants.BASE_URL_USER + '/report/downloadExcel',
  BULK_UPLOAD_PROCESSING_STATUS:
    AppConstants.BASE_URL_USER + '/report/uploadStatus',
  GET_WALLET_BALANCE: AppConstants.BASE_URL_USER + '/walletBalance',
  /*----------PANE VIEW----------*/
  GET_AVAILABLE_PANES: AppConstants.BASE_URL_USER + '/availablePanes',
  GET_VERIFICATION_PANES: AppConstants.BASE_URL_USER + '/verificationPanes',
  GET_PANE_VIEW_OUTPUT: AppConstants.BASE_URL_USER + '/paneView',
  GET_VERIFICATION_OUTPUT: AppConstants.BASE_URL_USER + '/verification',

  /*----------LANDING PAGE----------*/
  USER_ONBOARDING: AppConstants.BASE_URL_ADMIN + '/userOnboard',
  DOWNLOAD_PDF_ONBOARDING:
    AppConstants.BASE_URL_USER + '/onboarding/document/api',
  REQUEST_API_KEY: AppConstants.BASE_URL_USER + '/sendMail',
  VIEW_DOCUMENTATION: AppConstants.BASE_URL_USER + '/documentUrl',

  /*---------NEW DASHBOARD----------------*/
  DASHBOARD_AGGREGATED_DATA:
    AppConstants.BASE_URL_USER + '/dashboard-aggregated-data',

  /*----------ONBOARDING SOLUTIONS----------*/
  OS_GET_COUNTRIES: AppConstants.BASE_URL_USER + '/onboarding/country',
  OS_GET_ID_BASED_COUNTRIES: AppConstants.BASE_URL_USER + '/onboarding/id_type',
  OS_GET_V2_OCR_DATA: AppConstants.BASE_URL_USER + '/v2/ocr',
  OS_GET_V1_OCR_DATA: AppConstants.BASE_URL_USER + '/onboarding/ocr',
  OS_GET_FACE_MATCH_DATA: AppConstants.BASE_URL_USER + '/onboarding/face/match',
  OS_GET_LIVENESS_RESULT:
    AppConstants.BASE_URL_USER + '/onboarding/check_liveness',
  OS_GET_AADHAR_OTP:
    AppConstants.BASE_URL_USER + '/v2/aadhaar/okyc/generate-otp',
  OS_SUBMIT_AADHAR_OTP:
    AppConstants.BASE_URL_USER + '/v2/aadhaar/okyc/submit-otp',
  OS_GET_FACEDETAIL: AppConstants.BASE_URL_USER + '/onboarding/facedetail',
  OS_GET_PANDETAIL: AppConstants.BASE_URL_USER + '/v2/pan/pan-details',
  OS_GET_PASSPORTDETAIL: AppConstants.BASE_URL_USER + '/passport-detail',
  OS_GET_DLDETAIL: AppConstants.BASE_URL_USER + '/driving-license',
  OS_GET_FACE_EXTRACT: AppConstants.BASE_URL_USER + '/onboarding/face/extract',
  OS_GET_BASE64_IMAGE: AppConstants.BASE_URL_USER + '/image/base64ToImage',
  OS_GET_OKYC_STATUS: AppConstants.BASE_URL_USER + '/v2/okyc_status',
  OS_PAN_LINKED_STATUS:
    AppConstants.BASE_URL_USER + '/v2/aadhaar/pan-linking-status',
  // OS_GET_OCR_DATA: 'assets/jsons/ocr.json',
  // OS_GET_FACE_MATCH_DATA: 'assets/jsons/face-match.json',
  // OS_GET_LIVENESS_RESULT: 'assets/jsons/passive-liveness.json',

  /*----------AML+----------*/
  CA_SEARCH_SUMMARY: AppConstants.BASE_URL_USER + '/aml/v2/search',
  CA_UPDATE_CASE_STATUS: AppConstants.BASE_URL_USER + '/aml/v2/search',
  CA_UPDATE_CARD_STATUS: AppConstants.BASE_URL_USER + '/aml/v2/search',
  CA_BULK_UPLOAD: AppConstants.BASE_URL_USER + '/aml/v2/upload',

  /*----------USAGE REPORT----------*/
  GET_USAGE_DATA: AppConstants.BASE_URL_USER + '/report/report',
  GET_USAGE_STATUS:
    AppConstants.BASE_URL_USER + '/report/download/usage/status',
  GET_USAGE_REPORT_HISTORY_DATA:
    AppConstants.BASE_URL_USER + '/report/download/usage/history',
  DOWNLOAD_USAGE_DATA: AppConstants.BASE_URL_USER + '/report/download-report',
  GET_APIS_USAGE: AppConstants.BASE_URL_USER + '/report/apiList',
  SEND_API_KEY: AppConstants.BASE_URL_USER + '/sendApiKey',
  SEND_ALL_API_KEYS: AppConstants.BASE_URL_USER + '/sendAllApiKeys',

  /*--------------SUB ACCOUNTS-------*/
  ADD_SUB_ACCOUNT: AppConstants.BASE_URL_USER + '/subAccount/addAccount',
  GET_SUB_LIST: AppConstants.BASE_URL_USER + '/subAccount/getSubList',
  DELETE_SUB_ACCOUNT: AppConstants.BASE_URL_USER + '/subAccount/deleteAccount',
  UPDATE_SUB_ACCOUNT: AppConstants.BASE_URL_USER + '/subAccount/updateAccount',
  SEND_SUB_ACCOUNT_API_KEY:
    AppConstants.BASE_URL_USER + '/subAccount/sendApiKey',
  SEND_ALL_SUB_ACCOUNT_API_KEY:
    AppConstants.BASE_URL_USER + '/subAccount/sendAllApiKeys',

  /*----------------AGENT USERS------------------*/
  GET_AGENT_ACCOUNT: AppConstants.BASE_URL_USER + '/agentAccount',
  ADD_AGENT_ACCOUNT: AppConstants.BASE_URL_USER + '/agentAccount/addAccount',
  DELETE_AGENT_ACCOUNT:
    AppConstants.BASE_URL_USER + '/agentAccount/deleteAccount',
  GET_AGENT_PARENT_EMAILs:
    AppConstants.BASE_URL_USER + '/agentAccount/parenteEmailId',
  UPDATE_AGENT_ACCOUNT:
    AppConstants.BASE_URL_USER + '/agentAccount/updateAccount',

  /*----------------AGENT USERS SUMMARY------------------*/
  // AGENT_SEARCH_SUMMARY: AppConstants.BASE_URL_USER + '/aml/v2/search/assignee',
  AGENT_SEARCH_SUMMARY: AppConstants.BASE_URL_USER + '/aml/v2/search',
  GET_AGENT_EMAILs: AppConstants.BASE_URL_USER + '/aml/v2/client/users',
  AGENT_UPDATE_STATUS: AppConstants.BASE_URL_USER + '/aml/v2/search/assign',

  /*----------FACE CATALOGUE----------*/
  GET_CATALOGUE: AppConstants.BASE_URL_USER + '/face-catalog/face-id',
  CREATE_CATALOGUE: AppConstants.BASE_URL_USER + '/face-catalog',
  ADD_FACE_TO_CATALOGUE:
    AppConstants.BASE_URL_USER + '/face-catalog/face/upload',
  DELETE_FACE_ID: AppConstants.BASE_URL_USER + '/face-catalog/face',
  SEARCH_FACE_FROM_CATALOGUE:
    AppConstants.BASE_URL_USER + '/face-catalog/face-search',
  GET_FACES_FROM_FACE_ID: AppConstants.BASE_URL_USER + '/face-catalog/face-id',

  GENERATE_TOKEN: AppConstants.BASE_URL_USER + '/token',
  PAN_TO_NAME: AppConstants.BASE_URL_USER + '/pan',
  PAN_VERIFICATION: AppConstants.BASE_URL_USER + '/pan/verify',
  AADHAR_GENERATE_OTP: AppConstants.BASE_URL_USER + '/aadhar',
  AADHAR_SUBMIT_OTP: AppConstants.BASE_URL_USER + '/aadhar/otp',
  GET_API_ACCESS_DETAILS: AppConstants.BASE_URL_USER + '/api/details',
  DL_VERIFICATION: AppConstants.BASE_URL_USER + '/driving-license',
  VOTER_ID_VERIFICATION: AppConstants.BASE_URL_USER + '/voter-id',
  BANK_ACC_VERIFICATION: AppConstants.BASE_URL_USER + '/bank',
  FORGERY_DETECTION_IN: AppConstants.BASE_URL_USER + '/detect/forgery',

  /*----------FACE ID BULK UPLOAD----------*/
  FACE_ID_BULK_UPLOAD_GET_DATA:
    AppConstants.BASE_URL_USER + '/face-catalog/upload/data',
  FACE_ID_START_BULK_UPLOAD:
    AppConstants.BASE_URL_USER + '/face-catalog/upload',
  FACE_ID_BULK_UPLOAD_REFRESH:
    AppConstants.BASE_URL_USER + '/face-catalog/upload/status',
  FACE_ID_BULK_UPLOAD_DOWNLOAD_REPORT:
    AppConstants.BASE_URL_USER + '/face-catalog/download-report',
  FACE_ID_BULK_UPLOAD_ABORT:
    AppConstants.BASE_URL_USER + '/face-catalog/upload/stop',
  FACE_CATALOG_DETAILS: AppConstants.BASE_URL_USER + '/face-catalog',
  FACE_ID_BULK_UPLOAD_CSV:
    AppConstants.BASE_URL_USER + '/face-catalog/upload/csv',

  GET_AVAILABLE_TEMPLATES:
    AppConstants.BASE_URL_USER + '/esign/document/template',
  GET_AVAILABLE_DOCUMENTS:
    AppConstants.BASE_URL_USER + '/esign/documents/status',
  DOWNLOAD_ESIGN_DOCUMENT:
    AppConstants.BASE_URL_USER + '/esign/request/document',




  /********** APIs for new Demo flow **************/

  //DOC1 and DOC2
  POST_GENERATE_DOC_OCR:
    AppConstants.BASE_URL_USER + '/v2/ocr',

  GET_AADHAR_OKYC_HEALTH:
    AppConstants.BASE_URL_USER + '/v2/okyc_status',
  
  POST_GENERATE_AADHAR_OTP:
    AppConstants.BASE_URL_USER + '/v2/aadhaar/okyc/generate-otp',

  POST_VALIDATE_AADHAR_OTP:
    AppConstants.BASE_URL_USER + '/v2/aadhaar/okyc/submit-otp',

  POST_VALIDATE_PAN:
    AppConstants.BASE_URL_USER + '/v2/pan/pan-details',

  POST_VALIDATE_VID:
    AppConstants.BASE_URL_USER + '/v2/voter-id',

  POST_VALIDATE_PSPT:
    AppConstants.BASE_URL_USER + '/passport-detail',

  POST_VALIDATE_DL: 
    AppConstants.BASE_URL_USER + '/v2/driving-license',

  POST_EXTRACT_FACE:
    AppConstants.BASE_URL_USER + 'onboarding/face/extract',


    
    //Selfie
  POST_CHECK_LIVENESS:
    AppConstants.BASE_URL_USER + '/v2/liveness',

  

  //Result
      
  POST_FACE_PROFILE:
    AppConstants.BASE_URL_USER + '/onboarding/facedetail',
    
  POST_FACE_SEARCH:
    AppConstants.BASE_URL_USER + '/face-catalog/face-search?threshold=80&faceSizeThreshold=0.1',

  POST_FACE_MATCH:
    AppConstants.BASE_URL_USER + '/v2/face-match',

  POST_FORGERY_DETECTION: 
  AppConstants.BASE_URL_USER + '/v2/forgery-detect',


  POST_NAME_MATCH: 
  AppConstants.BASE_URL_USER + '/onboarding/match',

  POST_FACE_EXTRACTION: AppConstants.BASE_URL_USER + '/onboarding/face/extract',

  
    //Deep Scan//
  POST_DEEP_SCAN:
    AppConstants.BASE_URL_USER + '/v2/document-extraction',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
