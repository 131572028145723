import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractDate'
})
export class ExtractDatePipe implements PipeTransform {

  transform (value: any, format: string = 'mm-dd-yyyy'): any {
    try {
      const d = new Date(value);
      let month = (d.getMonth() + 1).toString(); // month starts from 0
      let day = d.getDate().toString();
      month = month.length < 2 ? '0' + month : month;
      day = day.length < 2 ? '0' + day : day;
      return month + '-' + day + '-' + d.getFullYear();
    } catch (e) {
      // console.error('ExtractDatePipe :: Exception occurred :: ',e);
    }
    return value;
  }
}
