export enum URL_CONSTANTS {
  BASE_URL = '',

  BASE_URL_ADMIN_LOCAL = 'http://172.204.0.19:8081',
  BASE_URL_USER_LOCAL = 'http://10.113.113.200:8082',

  BASE_URL_ADMIN_PROD = 'https://api.idcentral.io/admin-service',
  BASE_URL_USER_PROD = 'https://api.idcentral.io/idc',

  BASE_URL_ADMIN_QA = 'http://172.204.0.19:8081',
  BASE_URL_USER_QA = 'http://172.204.0.19:8088/idc',

  BASE_URL_ADMIN_DEV = 'http://localhost:8084/admin-service',
  // BASE_URL_USER_DEV =   'http://15.207.149.221:8080/idc',
  BASE_URL_USER_DEV = 'http://localhost:8085/idc',

  BASE_URL_ADMIN_STAGGING = 'https://stage.idcentral.io/admin-service',
  BASE_URL_USER_STAGGING = 'https://stage.idcentral.io/idc',
}
