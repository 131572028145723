import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[isOpen]',
  exportAs: 'isOpen'
})
export class IsOpenDirective {

  @Input() public isOpen = false; // BY DEFAULT EXPANDED

  /*--------LISTENS TO CLICK EVENT ON THE LINK AND TOGGLES THE OPEN/CLOSE VARIABLE----------*/
  @HostListener('click') toggleopen() {
    this.isOpen = !this.isOpen;
  }

  constructor() {}
}
