<div id="alert-modal" class="modal" *ngIf="alertText">

  <!-- Modal content -->
  <div class="modal-content">
    <span class="alert-modal-header" (click)="modalAction.emit('CLOSE')">
      <span class="sub-header">{{subHeader}}</span>
      <span class="close-icon" *ngIf="!hideCloseButton">&times;</span>
    </span>

    <div class="divider-30"></div> <!--DIVIDER-->

    <div *ngIf="iconType && iconType === 'WARNING'" class="modal-icon-holder">
      <img [src]="ERROR_ICON" class="warning-icon">
    </div>

    <div *ngIf="iconType && iconType === 'SUCCESS'" class="modal-icon-holder">
      <img [src]="SUCCESS_ICON" class="success-icon">
    </div>

    <div *ngIf="iconType && iconType === 'INFO'" class="modal-icon-holder">
      <img [src]="INFO_ICON" class="info-icon">
    </div>

    <div class="alert-text-content" [ngClass]="iconType ? '' : 'padding-top-bottom-20'">
      <span class="main-text" [innerHTML]="alertText"></span>
      <span class="sub-text" [innerHTML]="alertSubText"></span>
    </div>

    <div class="divider-30" *ngIf="hideButtons"></div> <!--DIVIDER TO COVER FOR BUTTONS-->

    <ng-container *ngIf="!hideButtons">
      <ng-container *ngIf="custom_yes_button || custom_no_button else generalButtons">
        <div class="buttons-holder">
          <button *ngIf="custom_no_button" (type)="button" class="btn btn-primary generic-button-white no-button" style="border: none" (click)="modalAction.emit('NO')">{{custom_no_button}}</button>
          <button *ngIf="custom_yes_button" (type)="button" class="btn btn-primary generic-button-blue" (click)="modalAction.emit('YES')">{{custom_yes_button}}</button>
        </div>
      </ng-container>

      <ng-template #generalButtons>

        <ng-template #okButton>
          <div class="buttons-holder">
            <button (type)="button" class="btn btn-primary generic-button-blue ok-button" (click)="modalAction.emit('CLOSE')">OK</button>
          </div>
        </ng-template>

        <div class="buttons-holder" *ngIf="buttonsType === 'YES_OR_NO' else okButton">
          <button (type)="button" class="btn btn-primary generic-button-white no-button" style="border: none" (click)="modalAction.emit('CANCEL')">Cancel</button>
          <button (type)="button" class="btn btn-primary generic-button-blue yes-button" (click)="modalAction.emit('YES')">Yes</button>
        </div>
      </ng-template>
    </ng-container>  
    
  </div>

</div>