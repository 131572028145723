import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractTime'
})
export class ExtractTimePipe implements PipeTransform {

  transform(value: any, format: string = 'hh:mm:ss'): any {
    try {
      const d = new Date(value);
      let hours = (d.getHours()).toString(); // month starts from 0
      let minutes = (d.getMinutes()).toString();
      let seconds = (d.getSeconds()).toString();
      hours = hours.length < 2 ? + '0' + hours : hours;
      minutes = minutes.length < 2 ? + '0' + minutes : minutes;
      seconds = seconds.length < 2 ? + '0' + seconds : seconds;

      if (format === 'hh:mm:ss') {
        return hours + ':' + minutes + ':' + seconds;
      } else if (format === 'hh:mm') {
        return hours + ':' + minutes;
      }
    } catch (e) {
      // console.error('ExtractTimePipe :: transform() :: Exception occurred :: ',e);
    }
    return value;
  }
}
