import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorConstants } from 'src/app/shared/constants/error.constants';
import { AlertModalEntity } from 'src/app/shared/interfaces/alert-modal-entity.interface';
import { ManageAlertTextUtility } from 'src/app/shared/utilities/manage-alert-text.util';

declare var paypal;

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements AfterContentInit {
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;
  @Input() public paymentDetails: any = null;
  @Output() public exitPaymentView = new EventEmitter();

  public alertUtility = new ManageAlertTextUtility();
  public successMessage: AlertModalEntity = this.alertUtility.getAlertEntity(null, null, null, null);

  constructor() {}

  ngAfterContentInit() {
    this.handlePaypal();
  }

  /*----------METHOD TO NAVIGATE THE USER TO HOMEPAGE----------*/
  returnToHomepage(paymentData) {
    // TODO REPLACE THE DUMMY DATA WITH THE ACTUAL DATA THIS OBJECT WILL BE PASSED TO PARENT COMPONENT
    this.exitPaymentView.emit(paymentData);
  }

  /*----------METHOD TO HANDLE PAYPAL----------*/
  handlePaypal() {
    // <!--REMOVED PAYPAL-->
    // paypal
    // .Buttons({
    //   createOrder: (data, actions) => {
    //     // This function sets up the details of the transaction, including the amount and line item details.
    //     return actions.order.create({
    //       purchase_units: [
    //         {
    //           description: this.paymentDetails.description,
    //           amount: {
    //             value: this.paymentDetails.price,
    //             currency_code: 'INR'
    //           }
    //         }
    //       ]
    //     });
    //   },
    //   onApprove: async (data, actions) => {
    //     // This function captures the funds from the transaction.
    //     const order = await actions.order.capture();
    //     this.successMessage = this.alertUtility.getAlertEntity('SUCCESS', 'Payment Status', 'Success',
    //     ErrorConstants.PAYMENT_SUCCESS_MESSAGE);

    //     this.returnToHomepage(this.mapPaymentsData(order));
    //     // return actions.order.capture().then(function(details) {
    //     // This function shows a transaction success message to your buyer.
    //     // alert('Transaction completed by ' + data);
    //     //  });
    //   }, onError: err => {
    //     // console.error('PaymentGatewayComponent :: handlePaypal() :: Exception occurred :: ', err);
    //   }
    // }).render(this.paypalElement.nativeElement);
  }

  private mapPaymentsData(order) {
    let paymentData: any = {};
    if (order) {
      if (order.id) {
        paymentData.orderId = order.id;
      }

      if (order.status) {
        paymentData.paymentStatus = order.status;
      }

      if (order.payer) {
        paymentData.payerDetails = order.payer;
      }

      const purchaseInfo = order.purchase_units[0];

      if (purchaseInfo) {
        if (purchaseInfo.payments) {
          const captures = purchaseInfo.payments.captures;

          if (captures && captures.length > 0) {
            const payments: any = {};
            if (captures[0].id) {
              payments.transactionId = captures[0].id;
            }

            if (captures[0].status) {
              payments.status = captures[0].status;
            }

            if (captures[0].amount) {
              payments.totalAmount = captures[0].amount;
            }

            if (captures[0].seller_receivable_breakdown) {
              payments.totalAmountBreakdown = captures[0].seller_receivable_breakdown;
            }

            if (captures[0].create_time) {
              payments.creationTime = captures[0].create_time;
            }

            if (captures[0].update_time) {
              payments.updationTime = captures[0].update_time;
              paymentData.payments = payments;
            }
          }
        }
      }
    } else {
      paymentData = null;
    }
    return paymentData;
  }
}
