import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitCamelCase'
})
export class SplitCamelCasePipe implements PipeTransform {

  transform (value: any): any {
    let labelName = '';
      if(value) {
        // CONVERTING labelName to Label Name
        const splittedByUppercase = value.split(/(?=[A-Z])/);
        if (splittedByUppercase && splittedByUppercase.length > 1)
        {
          for (let z=0; z<splittedByUppercase.length; z++)
          {
            labelName = labelName + splittedByUppercase[z];
            if (z < splittedByUppercase.length-1)
            {
              labelName = labelName + ' ';
            }
          }
        }
        else {
          labelName = value;
        }
      }
      return labelName;
  }
}
