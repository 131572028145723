import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-idc-grouped-bar-chart',
  templateUrl: './idc-grouped-bar-chart.component.html',
  styleUrls: ['./idc-grouped-bar-chart.component.scss']
})
export class IdcGroupedBarChartComponent implements OnInit {

  @Input('data') private chartData = [];
  private data = [];
  @ViewChild('groupedBarChart', { static: true }) private chartContainer: ElementRef;
  @Input('width') private w = 0;	
  @Input('height') private h = 340;
  @Input('margin') private margin = {top: 30, right: 20, bottom: 30, left: 50};
  @Input('colors') private colors = ['#baddf3', '#91c3e1', '#6da2c7', '#588cb3', '#35648e', '#235781'];
  @Input('metricName') private metricName = 'metric';
  @Input('showColorIndicator') public showColorIndicator = true;
  public keyNames = [];
  public keys = [];
  public selectedTab = 'all';

  private width = null;
	private height = null;

  constructor() {}

  ngOnInit() {
    this.data = [...this.chartData];
    this.renderChart(false);
  }

  /*----------METHOD TO FILTER THE CHART BASED ON THE SELECTED TAB----------*/
	filterChart(data, type) {
		this.selectedTab = null;
		if(type === 'all') {
			this.selectedTab = 'all';
			this.data = [...this.chartData];
			// REMOVING THE EXISTING SVG
			d3.select(this.chartContainer.nativeElement)
			.selectAll('svg').remove();
			this.renderChart(true);
		}
		else
		{
			if(data) {
				this.selectedTab = data;
				const filteredArray = [];
				for(const x of this.chartData){
					const entity = {};
					entity[data] = x[data];
					entity[this.metricName] = x[this.metricName];
					filteredArray.push(entity);
				}
        this.data = [...filteredArray];
				d3.select(this.chartContainer.nativeElement)
				.selectAll('svg').remove();
				this.renderChart(true);
			}
		}
  }

  /*---------METHOD TO RENDER THE CHART----------*/
  public renderChart(isFilter) {

    this.w = this.chartContainer.nativeElement.offsetWidth - 20;
		this.width = this.w - this.margin.left - this.margin.right;
    this.height = this.h - this.margin.top - this.margin.bottom;

    this.keys = Object.keys(this.data[0]);
    this.keys.splice(this.keys.indexOf(this.metricName),1); // REMOVING THE METRIC (MODEL NAME)

    if(!isFilter)
    {
      this.keyNames = this.keys;
    }

    const container = d3.select(this.chartContainer.nativeElement),
      barPadding = .2,
      axisTicks = {qty: 5, outerSize: 0, dateFormat: '%m-%d'};
    
      const svg = container
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height)
        .append('g')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    const xScale0 = d3.scaleBand().range([0, this.width - this.margin.left - this.margin.right]).padding(barPadding);
    const xScale1 = d3.scaleBand()  ;
    const yScale = d3.scaleLinear().range([this.height - this.margin.top - this.margin.bottom, 0]);
    const xAxis = d3.axisBottom(xScale0).tickSizeOuter(axisTicks.outerSize);  
    const yAxis = d3.axisLeft(yScale).ticks(axisTicks.qty).tickSizeOuter(axisTicks.outerSize);
    
    xScale0.domain(this.data.map(d => d[this.metricName]));
    
    xScale1.domain(this.keys).range([0, xScale0.bandwidth()]);
    // yScale.domain([0, d3.max(models, d => d.field1 > d.field2 ? d.field1 : d.field2)])
    yScale.domain([0, d3.max(this.data, d => d[this.keys[0]])]);
    
    const model_name = svg.selectAll(`.${this.metricName}`)
    .data(this.data)
    .enter().append('g')
    .attr('class', 'model_name')
    .attr('transform', d => `translate(${xScale0(d[this.metricName])},0)`);
    let index = 0;
    for(const x of this.keys)
    {
      model_name.selectAll(`.bar.${x}`)
      .data(d => [d])
      .enter()
      .append('rect')
      .attr('class', `bar ${x}`)
      .style('fill',this.colors[index])
      .attr('x', d => xScale1(x))
      .attr('y', d => yScale(d[x]))
      .attr('width', xScale1.bandwidth())
      .attr('height', d => {
        const test = yScale(d[x]) ? yScale(d[x]) : 0;
        return this.height - this.margin.top - this.margin.bottom - yScale(d[x]);
      });
      index++;
    } 

    // Add the X Axis
    svg.append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${this.height - this.margin.top - this.margin.bottom})`)
        .call(xAxis);

    // Add the Y Axis
    svg.append('g')
        .attr('class', 'y axis')
        .call(yAxis);
    svg.append('text')
			.attr('y', this.height - 30)
			.attr('x', (this.width/2-30))
			.classed('axis-title', true)
      .style('text-anchor', 'middle')
      .style('font-size', '14px')
			.style('stroke', 'none')
			.text('Date');
    
  svg.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('y', -30)
			.attr('x', -100)
      .style('text-anchor', 'middle')
      .style('font-size', '14px')
			.style('stroke', 'none')
			.classed('axis-title', true)
      .text('Count');


  }
}
