<div class="" style="position: relative;">
<div class="video-container-LD" #videoContainer>
    <video
      [style.display]="capturedImage ? 'none' : 'block'"
      #previewVideo
      id="previewVideo"
      [width]="PREVIEW_WIDTH"
      [height]="PREVIEW_HEIGHT"
      autoplay
      style="position: absolute;left: -6.8rem;"
    ></video>
  
    <video
      [style.display]="'none'"
      #video
      id="video"
      [width]="WIDTH"
      [height]="HEIGHT"
      autoplay
    ></video>
    <canvas
      [style.display]="'none'"
      #canvas
      id="canvas"
      [width]="WIDTH"
      [height]="HEIGHT"
    ></canvas>
  
    <canvas
      [style.display]="'none'"
      #canvasSmall
      id="canvas-small"
      [width]="PREVIEW_WIDTH"
      [height]="PREVIEW_HEIGHT"
    ></canvas>
  </div>
  
  <img [src]="capturedImage" *ngIf="capturedImage">
  
  <div class="snap-container text-center" style="position: absolute; left: 10rem;">
    <button
      class="btn btn-primary generic-button-blue capture-button"
      *ngIf="!errorMsg && !capturedImage"
      (click)="capture()"
    >
    </button>
  </div>
  </div>