import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/app-constants.constants';
import { CommonService } from 'src/app/core/services/common-service/common-service.service';
import { AllCountryCodeConstants } from 'src/app/shared/constants/all-country-codes.constants';
import { ErrorConstants } from 'src/app/shared/constants/error.constants';
import { ConstructAlertTextUtility } from 'src/app/shared/utilities/construct-alert-text.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-page-reg-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit, OnDestroy {

  public landingPageForm: FormGroup;
  public formSubmitted = false;
  private constructAlertTextUtility = new ConstructAlertTextUtility();
  private registrationSubscription: Subscription;
  public officialMailIdError = false;
  // <!--OFFICIAL MAIL CHANGES-->
  public unofficialMailIdList = []; // '@gmail.com', '@hotmail.com', '@yahoo.com', '@live.com', '@outlook.com', '@msn.com',
  // '@gmx.com', '@yandex.com', '@mail.com', '@aol.com'
  public countryCodeDDError =  false;
  public countryCodeList = AllCountryCodeConstants.ALL_COUNTRY_CODE_ARRAY;
  public country = null;
  public showDropdown = false;
  public isReady = true;
  public privacyPolicyLink = AppConstants.PRIVACY_POLICY;

  @Output() registrationMessage = new EventEmitter();
  @Output() showPrivacyPolicy = new EventEmitter();

  constructor(private fb: FormBuilder, private commonService: CommonService, private titlecasePipe: TitleCasePipe ) { 
    this.createForm(); 
  }

  ngOnInit() {}

  // @HostListener('click') onClick() {
  //   let classList = (event.target as Element).className;
  //   if(!(classList && classList.indexOf('dd-element')>-1)) {
  //     console.error('Drop down element');
  //     this.showDropdown = false;
  //   };
  // }

  /*---------METHOD TO CREATE THE REGISTRATION FORM----------*/
  createForm() {
    this.landingPageForm = this.fb.group({
      'name': [null, [Validators.required, Validators.pattern(AppConstants.NAME_REGEX)]],
      'organization': [null, [Validators.required, Validators.pattern(AppConstants.ORG_REGEX)]],
      'countryCode': [null, [Validators.required]],
      'phoneNumber': [null, [Validators.required, Validators.pattern(AppConstants.ONLY_DIGITS_REGEX)]],
      'email': [null, [Validators.required, Validators.pattern(AppConstants.OFFICIAL_EMAIL_REGEX)]]
    });
  }

  /*----------METHOD TO VALIDATE THE FORM VALUES BEFORE SUBMITTING THE VALUES----------*/
  validateFormValues() {
    let isMailIdValid = true;
    this.formSubmitted = true;
    this.landingPageForm.markAsTouched();
    for(const x of this.unofficialMailIdList) {
      if(this.landingPageForm.get('email').value.endsWith(x)) {
        isMailIdValid = false;
        this.officialMailIdError = true;
        break;
      }
    }
    
    return isMailIdValid && this.landingPageForm.valid;
  }

  /*----------METHOD TRIGGERRED WHEN USER SUBMITS THE REG FORM FROM LANDING PAGE----------*/
  submitForm(formValues) {
    if(this.validateFormValues()) {
      
      const registrationEntity =  {
        'email': formValues.email,
        'name': formValues.name,
        'organization': formValues.organization,
        'phoneNumber': formValues.countryCode + formValues.phoneNumber,
        'url': window.location.origin + AppConstants.LANDING_PAGE_SET_PASSWORD_LINK
      };

      if(formValues.countryCode) {
        this.isReady = false;
        this.commonService.postOperation(environment.USER_ONBOARDING, registrationEntity)
        .subscribe((data)=> {
          this.isReady = true;
          if(data && data.status === 'success') {
            this.registrationMessage.emit(
              this.constructAlertTextUtility.getAlertEntity('Registration Successful',
              'SUCCESS',ErrorConstants.REG_SUCCESS_MSG_LP));
          } else if(data && data.error && data.error !== '') {
            this.registrationMessage.emit(
              this.constructAlertTextUtility.getAlertEntity('Registration Failed',
              'WARNING',data.error));
          } else {
            this.registrationMessage.emit(
              this.constructAlertTextUtility.getAlertEntity('Registration Failed',
              'WARNING',ErrorConstants.UNEXPECTED_ERROR_MSG));
          }
        },(error) => {
          this.isReady = true;
          this.registrationMessage.emit(
            this.constructAlertTextUtility.getAlertEntity('Registration Failed',
            'WARNING',ErrorConstants.UNEXPECTED_ERROR_MSG));
        });
      }
      else {
        this.countryCodeDDError = true;
      }
    }
  }

  setCountry(data,isOpenObjectThree) {
    this.landingPageForm.get('countryCode').patchValue(data.countryCode);
    this.country = this.titlecasePipe.transform(data.country);
  }

  ngOnDestroy() {
    if(this.registrationSubscription) {
      this.registrationSubscription.unsubscribe();
    }
  }

}
