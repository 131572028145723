import { Injectable } from '@angular/core';
import { CanLoad, Router, CanActivate } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage-service/session-storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class IDCClientLoginGuard implements CanLoad, CanActivate {
    constructor(private router: Router, private _sessionStorageService: SessionStorageService) { }

    canLoad() {
        if (undefined !== this._sessionStorageService.getSession('loginDetails')
        && null !== this._sessionStorageService.getSession('loginDetails')) {
            return true;
        } else {
            this.router.navigate(['/login']);
        }
        return false;
    }

    canActivate() {
        if (undefined !== this._sessionStorageService.getSession('loginDetails')
        && null !== this._sessionStorageService.getSession('loginDetails')) {
            this.router.navigate(['/home']);
            return false;
        }
        return true;
    }
}
