import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/authentication/login/login.component';
import { IDCClientLoginGuard } from './core/authentication/guards/login-guard';
import { ActivateAccountComponent } from './core/components/activate-account/activate-account.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { LandingPageComponent } from './core/components/landing-page/landing-page.component';
import { SetPasswordComponent } from './core/components/set-password/set-password.component';
import { RegSuccessComponent } from './core/components/landing-page/reg-success-page/reg-success-page.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      portalType: 'IDC_CLIENT_APP'
    },
    canActivate: [IDCClientLoginGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./idc-client-app/idc-client-app.module').then(m => m.IdcClientAppModule),
    canLoad: [IDCClientLoginGuard]
  },
  {
    path: 'account-activation/:id',
    data: {
      type: 'ACTIVATION'
    },
    component: ActivateAccountComponent
  },
  {
    path: 'set-password/:id',
    data: {
      type: 'ACTIVATION'
    },
    component: SetPasswordComponent
  },
  {
    path: 'forgot-password/:id',
    data: {
      type: 'FORGOT_PASSWORD'
    },
    component: ActivateAccountComponent
  },
  {
    path: 'onboarding-solutions',
    component: LandingPageComponent
  },
  {
    path: 'registration-successful',
    component: RegSuccessComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

export const AppRouting = RouterModule.forRoot(appRoutes); // PROD SETTING --> REMOVE {useHash: true}
