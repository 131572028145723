import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDateRange',
  pure: false
})
export class FilterDateRangePipe implements PipeTransform {

  transform (inputArray: any[], key: string, startRange: any, endRange: any): any {
    const filteredArray = [];
    const fromDate = new Date(startRange).getTime();
    const toDate = new Date(endRange).getTime();

    // BOTH START AND END DATES ARE AVAILABLE
    if (fromDate && fromDate.toString() !== 'NaN' && toDate && toDate.toString() !== 'NaN') {
      for (const data of inputArray) {
        if (data[key] <= fromDate && data[key] >= toDate) {
          filteredArray.push(data);
        }
      }
    } else if (fromDate && fromDate.toString() !== 'NaN') {
      for (const data of inputArray) {
        if (data[key] >= fromDate) {
          filteredArray.push(data);
        }
      }
      return filteredArray;
    } else if (toDate && toDate.toString() !== 'NaN') {
      for (const data of inputArray) {
        if (data[key] <= fromDate) {
          filteredArray.push(data);
        }
      }
      return filteredArray;
    }
    return inputArray;
  }
}
