import { AlertEntity } from '../interfaces/alert-entity.interface';
import { AlertModalEntity } from '../interfaces/alert-modal-entity.interface';

export class ConstructAlertTextUtility {
    constructor() {}

    /*----------HELPER METHOD TO CREATE THE ALERT ENTITY----------*/
    getAlertEntity(title, type, message) {
        const entity: AlertEntity = {
            'title': title,
            'type': type,
            'message': message
        };
        return entity;
    }

    /*----------HELPER METHOD TO RESET THE ALERT ENTITY----------*/
    resetAlertEntity(type) {
        type['title'] = null;
        type['type'] = null;
        type['message'] = null;
    }
}
