import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-tacometer',
  templateUrl: './tacometer.component.html',
  styleUrls: ['./tacometer.component.scss']
})
export class TacometerComponent implements AfterViewInit {

  @Input('gaugeId') public gaugeId;
  @Input('data') public data;
  @Input('labelName') public labelName;
  @Input('labelValue') public labelValue;
  @Input('colors') public colorsArr = ['#00FF00','#1582FF','#FFD815','#FF9A15','#FF4338'];
  public gaugemap = {};

  constructor() { }

  ngAfterViewInit() {
    this.draw();
  }

  /*----------METHOD TO DRAW THE TACOMETER----------*/
  draw() {
    const self = this;
    const gauge = function (container, configuration) {
      const config = {
        size: 710,
        clipWidth: 200,
        clipHeight: 110,
        ringInset: 16,
        ringWidth: 20,

        pointerWidth: 20,
        pointerTailLength: 3,
        pointerHeadLengthPercent: 0.65,

        minValue: 0,
        maxValue: 10,

        minAngle: -90,
        maxAngle: 90,

        transitionMs: 750,

        majorTicks: 5,
        labelFormat: d3.format('d'),
        labelInset: 10,
      };
      let range;
      let r;
      let pointerHeadLength;
      const value = 0;

      let svg;
      let arc;
      let scale;
      let ticks;
      let tickData;
      let pointer;

      const donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function newAngle(d) {
        const ratio = scale(d);
        const newAngleCalc = config.minAngle + (ratio * range);
        return newAngleCalc;
      }

      function configure(_configuration) {
        let prop;
        for (prop in _configuration) {
          if (config[prop]) {
            config[prop] = _configuration[prop];
          }
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
              .innerRadius(r - config.ringWidth - config.ringInset)
              .outerRadius(r - config.ringInset)
              .startAngle(function (d, i) {
                const ratio = Number(d) * i;
                return deg2rad(config.minAngle + (ratio * range));
              })
              .endAngle(function (d, i) {
                const ratio = Number(d) * (i + 1);
                return deg2rad(config.minAngle + (ratio * range));
              });
      }
      self.gaugemap['configure'] = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap['isRendered'] = isRendered;

     function render(newValue) {
       svg = d3.select(container)
         .append('svg:svg')
         .attr('class', 'gauge')
         .attr('width', config.clipWidth)
         .attr('height', config.clipHeight);

       const centerTx = centerTranslation();

       const arcs = svg.append('g')
         .attr('class', 'arc')
         .attr('transform', centerTx);
        
       arcs.selectAll('path')
         .data(tickData)
         .enter().append('path')
         .attr('fill', function (d, i) {
           return self.colorsArr[i];
         })
         .attr('d', arc);

         /*----------HIDING THE INDICATORS----------*/
        //  const lg = svg.append('g')
        //    .attr('class', 'label')
        //    .attr('transform', centerTx);
        //  lg.selectAll('text')
        //    .data(ticks)
        //    .enter().append('text')
        //    .attr('transform', function (d) {
        //      const ratio = scale(d);
        //      const newAngle = config.minAngle + (ratio * range);
        //      return 'rotate(' + newAngle + ') translate(0,' + (config.labelInset - r) + ')';
        //    })
        //    .text(config.labelFormat);

       const lineData = [[config.pointerWidth / 2, 0],
       [0, -pointerHeadLength],
       [-(config.pointerWidth / 2), 0],
       [0, config.pointerTailLength],
       [config.pointerWidth / 2, 0]];
       const pointerLine = d3.line().curve(d3.curveLinear);
       const pg = svg.append('g').data([lineData])
         .attr('class', 'pointer')
         .attr('transform', centerTx);

       pointer = pg.append('path')
         .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
         .attr('transform', 'rotate(' + config.minAngle + ')');

       update(newValue === undefined ? 0 : newValue);
     }
     self.gaugemap['render'] = render;
     function update(newValue, newConfiguration?) {
       if (newConfiguration !== undefined) {
         configure(newConfiguration);
       }
       const ratio = scale(newValue);
       const _newAngle = config.minAngle + (ratio * range);
       pointer.transition()
         .duration(config.transitionMs)
         .ease(d3.easeElastic)
         .attr('transform', 'rotate(' + _newAngle + ')');
     }
     self.gaugemap['update'] = update;

     configure(configuration);

     return self.gaugemap;
    };
    const gaugeId = '#power-gauge'+this.gaugeId;
    const powerGauge = gauge(gaugeId, {
      size: 200,
      clipWidth: 200,
      clipHeight: 100,
      ringWidth: 30,
      maxValue: 100,
      transitionMs: 4000,
    });
    powerGauge['render'](this.data);
  }
}
