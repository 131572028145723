import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coverEmpty'
})
export class CoverEmptyPipe implements PipeTransform {

  transform (value: any, replaceWith: string = '- -'): any {
    if (undefined === value || null === value) {
      return replaceWith;
    } else {
      return value;
    }
  }
}
