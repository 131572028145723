import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseFirstLetter'
})
export class UppercaseFirstLetterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    try {
      if(value) {
        value = value.toLowerCase();
        return value.charAt(0).toUpperCase()+value.slice(1);
      }
    }
    catch(e) {}
    return value;
  }

}
