import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform (inputArray: any, filterKey: any, searchText): any {
    try {
      if (searchText && filterKey && searchText.toLowerCase() !== 'all') {
        const resultArray = [];
        for (const x of inputArray) {
          if (x[filterKey].toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
            resultArray.push(x);
          }
        }
        return resultArray;
      }
    } catch(e) {}
    return inputArray;
  }
}
