import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorConstants } from 'src/app/shared/constants/error.constants';
import { SessionStorageService } from '../session-storage-service/session-storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class BulkUploadService {

  constructor(private _sessionStorageService: SessionStorageService, private http: HttpClient) {}

  /*----------METHOD TO TRIGGER UPLOAD AND TAP THE UPLOAD PERCENTAGE----------*/
  upload(_bulkUploadStatusService, url, formData, numOfParams) {
    /*----------SETTING THE GLOBAL UPLOAD STATUS TO UPLOAD STARTED WITH A 0 PROGRESS----------*/
    _bulkUploadStatusService.updateData({
      'status': 'STARTED',
      'process': 'upload',
      'percentage': 0,
      'numOfParams': numOfParams
    });

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      })
    });

    this.http.request(req).subscribe(event => {
      // Via this API, you get access to the raw event stream.
      // Look for upload progress events.
      if (event.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        _bulkUploadStatusService.updateData({
          'status': 'IN_PROGRESS',
          'process': 'upload',
          'numOfParams': numOfParams,
          'percentage': percentDone === 100 ? 90 : percentDone, // CAPING THE PERCENTAGE TO 90%
        });
      } else if (event instanceof HttpResponse) {
        if (event && event.body && event.body['status'] === 'success') {
          _bulkUploadStatusService.updateData({
            'status': 'COMPLETED',
            'process': 'upload',
            'percentage': 100,
            'numOfParams': numOfParams,
            // 'uploadId': uploadId
          });
        } else {
          _bulkUploadStatusService.updateData({
            'status': 'FAILED',
            'process': 'upload',
            'percentage': 0,
            'numOfParams': numOfParams,
            'errorText': event.body['error'] && event.body['error'] !== '' ? event.body['error'] : ErrorConstants.UNEXPECTED_ERROR_MSG
            // 'uploadId': null
          });
        }
      }
    });
  }
}
