<div style="width: 100%; height: 100%; background-color: #f9f9f9; position: fixed; bottom: 0; right: 0; height: calc(100% - 72px); width: calc(100% - 40px); background-color: #ffffff; z-index: 99999">
  <div style="height: 100%; overflow: auto; background-color: #f9f9f9;">
  <div id="payment-success-modal">
    <app-alert-modal 
      *ngIf="successMessage && successMessage.alertText" 
      (modalAction)="alertUtility.resetAlertEntity(successMessage); returnToHomepage()" 
      [hideCloseButton]="true" 
      [iconType]="successMessage.iconType"
      [custom_yes_button]="'Return to Home Page'"
      [alertText]="successMessage.alertText" 
      [alertSubText]="successMessage.alertSubText"
      [subHeader]="successMessage.subHeader"
    ></app-alert-modal>
  </div>

  <!-- <h4 style="padding: 20px; padding-bottom: 0;">Monthly Threshold</h4> -->

  <h4 style="position: absolute; top: 20px; left: 20px;">Payment Summary</h4>

  <div id="payment-page">
    
    <div>
      <img src="assets/img/icons/payment-img.png" style="height: 300px; width: auto">
    </div>
    <div id="payment-inner-container">
      <div id="payment-container">
        <div style="padding: 20px; border-bottom: 1px dashed lightgray;">
          <label>Order Summary</label>
          <p>
            {{ paymentDetails.description }}
          </p>

          <label>Amount</label>
          <div class="amt-container">
            <span class="amount-usd">{{ paymentDetails.price }} USD</span>

            <!-- <span class="convert-to-container"><span class="convert-to-text"><u>Convert to INR</u></span> <span class="converted-amt">37,456.25 INR</span></span> -->
          </div>

        </div>

        <div style="padding: 20px; border-bottom: 1px dashed lightgray;">

          <label>Payment Methods</label>

          <p>
            Select your payment method to proceed
          </p>

          <!--REMOVED PAYPAL-->
          <!-- <div class="paypal-container">
            <div style="margin-top: 20px; width: 200px; margin-right: 20px;" #paypal></div>
            <div class="paypal-info">Takes the user to paypal checkout page</div>
          </div> -->
          
        </div>

        <div style="padding: 20px; display: flex;">
          
          <div style="display: inline-block; margin-left: auto; color: rgb(36, 188, 237); font-size: 12px; text-decoration: underline; cursor: pointer;" (click)="this.exitPaymentView.emit(null)"><span style="text-decoration: none;">&#10006;</span> Cancel and take me back</div>
        </div>
      </div>
    </div>
  </div>
  </div>

</div>
