<div id="idc-stacked-bar-chart">
  <!--CHART CONTAINER-->

  <div class="bar-indicator" *ngIf="colorIndicator && colorIndicator.length > 0">
    <div class="all" (click)="filterChart(null,'all')" [ngClass]="selectedTab == 'all' ? 'selected-filter' : ''">
      <span>All</span>
    </div>
    <ng-container *ngFor="let data of colorIndicator">
      <div (click)="filterChart(data.labelName, null)" [ngClass]="selectedTab == data.labelName ? 'selected-filter' : ''">
        <span class="box" [style.background-color]="data.color"></span>
        <span>{{data.labelName | titlecase}}</span>
      </div>
    </ng-container>
    
  </div>

  <div class="chart-container d3-chart" #stackedBarChart>
  </div>

  <!--COLORS-->
  <div class="color-indicator" *ngIf="showColorIndicator">
    <div *ngFor="let data of colorIndicator">
        <span class="box" [style.background-color]="data.color"></span>
        <span>{{data.labelName}}</span>
    </div>
  </div>
</div>