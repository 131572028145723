<div class="generic-card">
  <table class="table mt-4" id="cost-dashboard-table">
    <thead *ngIf="tableHeaders.length > 0">
      <tr>
        <td *ngFor="let data of tableHeaders">{{data | titlecase}}</td>
      </tr>
    </thead>      
    <tbody>
      <ng-container *ngIf="isReady else loader">
        <ng-container *ngIf="tableData.length > 0 else noData">
          <tr *ngFor="let data of tableData | paginate: { itemsPerPage: paginationThreshold, currentPage: p } ; let i = index ">    
            <ng-container *ngFor="let x of tableHeaders;">
              <td>
                {{data[x]}}
              </td>
            </ng-container>   
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #loader>
        <tr>    
            <td>
              <app-loader [showText]="true"></app-loader>
            </td>
        </tr>
      </ng-template>
      <ng-template #noData>
        <tr class="w-100">    
            <td class="w-100 text-center" [attr.colspan]="tableHeaders?.length">
              <app-no-data></app-no-data>
            </td>
        </tr>
      </ng-template>

    </tbody>
  </table>
</div>

<pagination-controls (pageChange)="p = $event" [autoHide]="true"></pagination-controls>
