import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  public LOADER_MD = 'loader-md';
  public LOADER_SM = 'loader-sm';
  public LOADER_LG = 'loader-lg';

  @Input() public showText = false;
  @Input() public loaderText = 'Loading...';
  @Input() public loaderPosition = 'center'; // center, left, right
  @Input() public loaderColor = '#0C4DB2';
  @Input() public loaderSize: string = this.LOADER_SM;

  constructor() {}

}
