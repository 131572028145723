import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BulkUploadStatusService {

  private dataSource = new BehaviorSubject<any>({
    'status': null,
    'process': 'view',
    'percentage': null
  });

  data = this.dataSource.asObservable();

  constructor() { }

  updateData(data) {
    this.dataSource.next(data);
  }

  resetData() {
    this.dataSource.next({
      'status': null,
      'process': 'view',
      'percentage': null
    });
  }
}
