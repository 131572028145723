<div id="loader-modal" class="modal">

  <!-- Modal content -->
  <div class="modal-content">
    
    <div class="loader-sm-container">
      <div [ngClass]="'loader-sm'"></div>
      <span *ngIf="loaderText" id="loading-text">{{loaderText}}</span>
    </div>
    
  </div>

</div>