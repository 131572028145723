export class AllCountryCodeConstants {
    public static ALL_COUNTRY_CODE_ARRAY = [
    {
        'country': 'Indonesia',
        'countryCode': '62'
        },
        {
        'country': 'Afghanistan',
        'countryCode': '93'
        },
        {
        'country': 'Aland Islands',
        'countryCode': '358-18'
        },
        {
        'country': 'Albania',
        'countryCode': '355'
        },
        {
        'country': 'Algeria',
        'countryCode': '213'
        },
        {
        'country': 'American Samoa',
        'countryCode': '1-684'
        },
        {
        'country': 'Andorra',
        'countryCode': '376'
        },
        {
        'country': 'Angola',
        'countryCode': '244'
        },
        {
        'country': 'Anguilla',
        'countryCode': '1-264'
        },
        {
        'country': 'Antarctica',
        'countryCode': ''
        },
        {
        'country': 'Antigua and Barbuda',
        'countryCode': '1-268'
        },
        {
        'country': 'Argentina',
        'countryCode': '54'
        },
        {
        'country': 'Armenia',
        'countryCode': '374'
        },
        {
        'country': 'Aruba',
        'countryCode': '297'
        },
        {
        'country': 'Australia',
        'countryCode': '61'
        },
        {
        'country': 'Austria',
        'countryCode': '43'
        },
        {
        'country': 'Azerbaijan',
        'countryCode': '994'
        },
        {
        'country': 'Bahamas',
        'countryCode': '1-242'
        },
        {
        'country': 'Bahrain',
        'countryCode': '973'
        },
        {
        'country': 'Bangladesh',
        'countryCode': '880'
        },
        {
        'country': 'Barbados',
        'countryCode': '1-246'
        },
        {
        'country': 'Belarus',
        'countryCode': '375'
        },
        {
        'country': 'Belgium',
        'countryCode': '32'
        },
        {
        'country': 'Belize',
        'countryCode': '501'
        },
        {
        'country': 'Benin',
        'countryCode': '229'
        },
        {
        'country': 'Bermuda',
        'countryCode': '1-441'
        },
        {
        'country': 'Bhutan',
        'countryCode': '975'
        },
        {
        'country': 'Bolivia',
        'countryCode': '591'
        },
        {
        'country': 'Bonaire, Saint Eustatius and Saba ',
        'countryCode': '599'
        },
        {
        'country': 'Bosnia and Herzegovina',
        'countryCode': '387'
        },
        {
        'country': 'Botswana',
        'countryCode': '267'
        },
        {
        'country': 'Bouvet Island',
        'countryCode': ''
        },
        {
        'country': 'Brazil',
        'countryCode': '55'
        },
        {
        'country': 'British Indian Ocean Territory',
        'countryCode': '246'
        },
        {
        'country': 'British Virgin Islands',
        'countryCode': '1-284'
        },
        {
        'country': 'Brunei',
        'countryCode': '673'
        },
        {
        'country': 'Bulgaria',
        'countryCode': '359'
        },
        {
        'country': 'Burkina Faso',
        'countryCode': '226'
        },
        {
        'country': 'Burundi',
        'countryCode': '257'
        },
        {
        'country': 'Cambodia',
        'countryCode': '855'
        },
        {
        'country': 'Cameroon',
        'countryCode': '237'
        },
        {
        'country': 'Canada',
        'countryCode': '1'
        },
        {
        'country': 'Cape Verde',
        'countryCode': '238'
        },
        {
        'country': 'Cayman Islands',
        'countryCode': '1-345'
        },
        {
        'country': 'Central African Republic',
        'countryCode': '236'
        },
        {
        'country': 'Chad',
        'countryCode': '235'
        },
        {
        'country': 'Chile',
        'countryCode': '56'
        },
        {
        'country': 'China',
        'countryCode': '86'
        },
        {
        'country': 'Christmas Island',
        'countryCode': '61'
        },
        {
        'country': 'Cocos Islands',
        'countryCode': '61'
        },
        {
        'country': 'Colombia',
        'countryCode': '57'
        },
        {
        'country': 'Comoros',
        'countryCode': '269'
        },
        {
        'country': 'Cook Islands',
        'countryCode': '682'
        },
        {
        'country': 'Costa Rica',
        'countryCode': '506'
        },
        {
        'country': 'Croatia',
        'countryCode': '385'
        },
        {
        'country': 'Cuba',
        'countryCode': '53'
        },
        {
        'country': 'Curacao',
        'countryCode': '599'
        },
        {
        'country': 'Cyprus',
        'countryCode': '357'
        },
        {
        'country': 'Czech Republic',
        'countryCode': '420'
        },
        {
        'country': 'Democratic Republic of the Congo',
        'countryCode': '243'
        },
        {
        'country': 'Denmark',
        'countryCode': '45'
        },
        {
        'country': 'Djibouti',
        'countryCode': '253'
        },
        {
        'country': 'Dominica',
        'countryCode': '1-767'
        },
        {
        'country': 'Dominican Republic',
        'countryCode': '1-809 and 1-829'
        },
        {
        'country': 'East Timor',
        'countryCode': '670'
        },
        {
        'country': 'Ecuador',
        'countryCode': '593'
        },
        {
        'country': 'Egypt',
        'countryCode': '20'
        },
        {
        'country': 'El Salvador',
        'countryCode': '503'
        },
        {
        'country': 'Equatorial Guinea',
        'countryCode': '240'
        },
        {
        'country': 'Eritrea',
        'countryCode': '291'
        },
        {
        'country': 'Estonia',
        'countryCode': '372'
        },
        {
        'country': 'Ethiopia',
        'countryCode': '251'
        },
        {
        'country': 'Falkland Islands',
        'countryCode': '500'
        },
        {
        'country': 'Faroe Islands',
        'countryCode': '298'
        },
        {
        'country': 'Fiji',
        'countryCode': '679'
        },
        {
        'country': 'Finland',
        'countryCode': '358'
        },
        {
        'country': 'France',
        'countryCode': '33'
        },
        {
        'country': 'French Guiana',
        'countryCode': '594'
        },
        {
        'country': 'French Polynesia',
        'countryCode': '689'
        },
        {
        'country': 'French Southern Territories',
        'countryCode': ''
        },
        {
        'country': 'Gabon',
        'countryCode': '241'
        },
        {
        'country': 'Gambia',
        'countryCode': '220'
        },
        {
        'country': 'Georgia',
        'countryCode': '995'
        },
        {
        'country': 'Germany',
        'countryCode': '49'
        },
        {
        'country': 'Ghana',
        'countryCode': '233'
        },
        {
        'country': 'Gibraltar',
        'countryCode': '350'
        },
        {
        'country': 'Greece',
        'countryCode': '30'
        },
        {
        'country': 'Greenland',
        'countryCode': '299'
        },
        {
        'country': 'Grenada',
        'countryCode': '1-473'
        },
        {
        'country': 'Guadeloupe',
        'countryCode': '590'
        },
        {
        'country': 'Guam',
        'countryCode': '1-671'
        },
        {
        'country': 'Guatemala',
        'countryCode': '502'
        },
        {
        'country': 'Guernsey',
        'countryCode': '44-1481'
        },
        {
        'country': 'Guinea',
        'countryCode': '224'
        },
        {
        'country': 'Guinea-Bissau',
        'countryCode': '245'
        },
        {
        'country': 'Guyana',
        'countryCode': '592'
        },
        {
        'country': 'Haiti',
        'countryCode': '509'
        },
        {
        'country': 'Heard Island and McDonald Islands',
        'countryCode': ' '
        },
        {
        'country': 'Honduras',
        'countryCode': '504'
        },
        {
        'country': 'Hong Kong',
        'countryCode': '852'
        },
        {
        'country': 'Hungary',
        'countryCode': '36'
        },
        {
        'country': 'Iceland',
        'countryCode': '354'
        },
        {
        'country': 'India',
        'countryCode': '91'
        },
        
        {
        'country': 'Iran',
        'countryCode': '98'
        },
        {
        'country': 'Iraq',
        'countryCode': '964'
        },
        {
        'country': 'Ireland',
        'countryCode': '353'
        },
        {
        'country': 'Isle of Man',
        'countryCode': '44-1624'
        },
        {
        'country': 'Israel',
        'countryCode': '972'
        },
        {
        'country': 'Italy',
        'countryCode': '39'
        },
        {
        'country': 'Ivory Coast',
        'countryCode': '225'
        },
        {
        'country': 'Jamaica',
        'countryCode': '1-876'
        },
        {
        'country': 'Japan',
        'countryCode': '81'
        },
        {
        'country': 'Jersey',
        'countryCode': '44-1534'
        },
        {
        'country': 'Jordan',
        'countryCode': '962'
        },
        {
        'country': 'Kazakhstan',
        'countryCode': '7'
        },
        {
        'country': 'Kenya',
        'countryCode': '254'
        },
        {
        'country': 'Kiribati',
        'countryCode': '686'
        },
        {
        'country': 'Kosovo',
        'countryCode': ''
        },
        {
        'country': 'Kuwait',
        'countryCode': '965'
        },
        {
        'country': 'Kyrgyzstan',
        'countryCode': '996'
        },
        {
        'country': 'Laos',
        'countryCode': '856'
        },
        {
        'country': 'Latvia',
        'countryCode': '371'
        },
        {
        'country': 'Lebanon',
        'countryCode': '961'
        },
        {
        'country': 'Lesotho',
        'countryCode': '266'
        },
        {
        'country': 'Liberia',
        'countryCode': '231'
        },
        {
        'country': 'Libya',
        'countryCode': '218'
        },
        {
        'country': 'Liechtenstein',
        'countryCode': '423'
        },
        {
        'country': 'Lithuania',
        'countryCode': '370'
        },
        {
        'country': 'Luxembourg',
        'countryCode': '352'
        },
        {
        'country': 'Macao',
        'countryCode': '853'
        },
        {
        'country': 'Macedonia',
        'countryCode': '389'
        },
        {
        'country': 'Madagascar',
        'countryCode': '261'
        },
        {
        'country': 'Malawi',
        'countryCode': '265'
        },
        {
        'country': 'Malaysia',
        'countryCode': '60'
        },
        {
        'country': 'Maldives',
        'countryCode': '960'
        },
        {
        'country': 'Mali',
        'countryCode': '223'
        },
        {
        'country': 'Malta',
        'countryCode': '356'
        },
        {
        'country': 'Marshall Islands',
        'countryCode': '692'
        },
        {
        'country': 'Martinique',
        'countryCode': '596'
        },
        {
        'country': 'Mauritania',
        'countryCode': '222'
        },
        {
        'country': 'Mauritius',
        'countryCode': '230'
        },
        {
        'country': 'Mayotte',
        'countryCode': '262'
        },
        {
        'country': 'Mexico',
        'countryCode': '52'
        },
        {
        'country': 'Micronesia',
        'countryCode': '691'
        },
        {
        'country': 'Moldova',
        'countryCode': '373'
        },
        {
        'country': 'Monaco',
        'countryCode': '377'
        },
        {
        'country': 'Mongolia',
        'countryCode': '976'
        },
        {
        'country': 'Montenegro',
        'countryCode': '382'
        },
        {
        'country': 'Montserrat',
        'countryCode': '1-664'
        },
        {
        'country': 'Morocco',
        'countryCode': '212'
        },
        {
        'country': 'Mozambique',
        'countryCode': '258'
        },
        {
        'country': 'Myanmar',
        'countryCode': '95'
        },
        {
        'country': 'Namibia',
        'countryCode': '264'
        },
        {
        'country': 'Nauru',
        'countryCode': '674'
        },
        {
        'country': 'Nepal',
        'countryCode': '977'
        },
        {
        'country': 'Netherlands',
        'countryCode': '31'
        },
        {
        'country': 'New Caledonia',
        'countryCode': '687'
        },
        {
        'country': 'New Zealand',
        'countryCode': '64'
        },
        {
        'country': 'Nicaragua',
        'countryCode': '505'
        },
        {
        'country': 'Niger',
        'countryCode': '227'
        },
        {
        'country': 'Nigeria',
        'countryCode': '234'
        },
        {
        'country': 'Niue',
        'countryCode': '683'
        },
        {
        'country': 'Norfolk Island',
        'countryCode': '672'
        },
        {
        'country': 'North Korea',
        'countryCode': '850'
        },
        {
        'country': 'Northern Mariana Islands',
        'countryCode': '1-670'
        },
        {
        'country': 'Norway',
        'countryCode': '47'
        },
        {
        'country': 'Oman',
        'countryCode': '968'
        },
        {
        'country': 'Pakistan',
        'countryCode': '92'
        },
        {
        'country': 'Palau',
        'countryCode': '680'
        },
        {
        'country': 'Palestinian Territory',
        'countryCode': '970'
        },
        {
        'country': 'Panama',
        'countryCode': '507'
        },
        {
        'country': 'Papua New Guinea',
        'countryCode': '675'
        },
        {
        'country': 'Paraguay',
        'countryCode': '595'
        },
        {
        'country': 'Peru',
        'countryCode': '51'
        },
        {
        'country': 'Philippines',
        'countryCode': '63'
        },
        {
        'country': 'Pitcairn',
        'countryCode': '870'
        },
        {
        'country': 'Poland',
        'countryCode': '48'
        },
        {
        'country': 'Portugal',
        'countryCode': '351'
        },
        {
        'country': 'Puerto Rico',
        'countryCode': '1-787 and 1-939'
        },
        {
        'country': 'Qatar',
        'countryCode': '974'
        },
        {
        'country': 'Republic of the Congo',
        'countryCode': '242'
        },
        {
        'country': 'Reunion',
        'countryCode': '262'
        },
        {
        'country': 'Romania',
        'countryCode': '40'
        },
        {
        'country': 'Russia',
        'countryCode': '7'
        },
        {
        'country': 'Rwanda',
        'countryCode': '250'
        },
        {
        'country': 'Saint Barthelemy',
        'countryCode': '590'
        },
        {
        'country': 'Saint Helena',
        'countryCode': '290'
        },
        {
        'country': 'Saint Kitts and Nevis',
        'countryCode': '1-869'
        },
        {
        'country': 'Saint Lucia',
        'countryCode': '1-758'
        },
        {
        'country': 'Saint Martin',
        'countryCode': '590'
        },
        {
        'country': 'Saint Pierre and Miquelon',
        'countryCode': '508'
        },
        {
        'country': 'Saint Vincent and the Grenadines',
        'countryCode': '1-784'
        },
        {
        'country': 'Samoa',
        'countryCode': '685'
        },
        {
        'country': 'San Marino',
        'countryCode': '378'
        },
        {
        'country': 'Sao Tome and Principe',
        'countryCode': '239'
        },
        {
        'country': 'Saudi Arabia',
        'countryCode': '966'
        },
        {
        'country': 'Senegal',
        'countryCode': '221'
        },
        {
        'country': 'Serbia',
        'countryCode': '381'
        },
        {
        'country': 'Seychelles',
        'countryCode': '248'
        },
        {
        'country': 'Sierra Leone',
        'countryCode': '232'
        },
        {
        'country': 'Singapore',
        'countryCode': '65'
        },
        {
        'country': 'Sint Maarten',
        'countryCode': '599'
        },
        {
        'country': 'Slovakia',
        'countryCode': '421'
        },
        {
        'country': 'Slovenia',
        'countryCode': '386'
        },
        {
        'country': 'Solomon Islands',
        'countryCode': '677'
        },
        {
        'country': 'Somalia',
        'countryCode': '252'
        },
        {
        'country': 'South Africa',
        'countryCode': '27'
        },
        {
        'country': 'South Georgia and the South Sandwich Islands',
        'countryCode': ''
        },
        {
        'country': 'South Korea',
        'countryCode': '82'
        },
        {
        'country': 'South Sudan',
        'countryCode': '211'
        },
        {
        'country': 'Spain',
        'countryCode': '34'
        },
        {
        'country': 'Sri Lanka',
        'countryCode': '94'
        },
        {
        'country': 'Sudan',
        'countryCode': '249'
        },
        {
        'country': 'Suriname',
        'countryCode': '597'
        },
        {
        'country': 'Svalbard and Jan Mayen',
        'countryCode': '47'
        },
        {
        'country': 'Swaziland',
        'countryCode': '268'
        },
        {
        'country': 'Sweden',
        'countryCode': '46'
        },
        {
        'country': 'Switzerland',
        'countryCode': '41'
        },
        {
        'country': 'Syria',
        'countryCode': '963'
        },
        {
        'country': 'Taiwan',
        'countryCode': '886'
        },
        {
        'country': 'Tajikistan',
        'countryCode': '992'
        },
        {
        'country': 'Tanzania',
        'countryCode': '255'
        },
        {
        'country': 'Thailand',
        'countryCode': '66'
        },
        {
        'country': 'Togo',
        'countryCode': '228'
        },
        {
        'country': 'Tokelau',
        'countryCode': '690'
        },
        {
        'country': 'Tonga',
        'countryCode': '676'
        },
        {
        'country': 'Trinidad and Tobago',
        'countryCode': '1-868'
        },
        {
        'country': 'Tunisia',
        'countryCode': '216'
        },
        {
        'country': 'Turkey',
        'countryCode': '90'
        },
        {
        'country': 'Turkmenistan',
        'countryCode': '993'
        },
        {
        'country': 'Turks and Caicos Islands',
        'countryCode': '1-649'
        },
        {
        'country': 'Tuvalu',
        'countryCode': '688'
        },
        {
        'country': 'U.S. Virgin Islands',
        'countryCode': '1-340'
        },
        {
        'country': 'Uganda',
        'countryCode': '256'
        },
        {
        'country': 'Ukraine',
        'countryCode': '380'
        },
        {
        'country': 'United Arab Emirates',
        'countryCode': '971'
        },
        {
        'country': 'United Kingdom',
        'countryCode': '44'
        },
        {
        'country': 'United States',
        'countryCode': '1'
        },
        {
        'country': 'United States Minor Outlying Islands',
        'countryCode': '1'
        },
        {
        'country': 'Uruguay',
        'countryCode': '598'
        },
        {
        'country': 'Uzbekistan',
        'countryCode': '998'
        },
        {
        'country': 'Vanuatu',
        'countryCode': '678'
        },
        {
        'country': 'Vatican',
        'countryCode': '379'
        },
        {
        'country': 'Venezuela',
        'countryCode': '58'
        },
        {
        'country': 'Vietnam',
        'countryCode': '84'
        },
        {
        'country': 'Wallis and Futuna',
        'countryCode': '681'
        },
        {
        'country': 'Western Sahara',
        'countryCode': '212'
        },
        {
        'country': 'Yemen',
        'countryCode': '967'
        },
        {
        'country': 'Zambia',
        'countryCode': '260'
        },
        {
        'country': 'Zimbabwe',
        'countryCode': '263'
        }
    ];
}
