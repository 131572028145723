import { Component, Output, EventEmitter, AfterContentInit, OnDestroy, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common-service/common-service.service';
import { SessionStorageService } from 'src/app/core/services/session-storage-service/session-storage-service.service';
import { ErrorConstants } from 'src/app/shared/constants/error.constants';
import { BlobToFileUtility } from 'src/app/shared/utilities/blob-to-file.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-web-cam-face-validation',
  templateUrl: './new-web-cam-face-validation.component.html',
  styleUrls: ['./new-web-cam-face-validation.component.scss']
})
export class NewWebCamFaceValidationComponent implements AfterContentInit, OnDestroy {

  @Output() clickedPhotoEvent = new EventEmitter();
  @Output() webcamerrorMsgEvent = new EventEmitter();
  @Output() public invaliSelfieEvent = new EventEmitter();
  @ViewChild('canvasSmall') canvasSmall;

  public isSessionReady = true; // APP SPECIFIC CHANGES
  public isReady = true; 
  public capturedImage = null;
  public sessionId = null;
  private generateTokenSubscription: Subscription;
  public _blobToFileUtility = new BlobToFileUtility();

  public livenessResult = {
    'capturedImage': null,
    'blobData': null,
    'compressedBlobData': null,
    'livenessError': [],
    'result': null
  };

  PREVIEW_WIDTH = 580;
  PREVIEW_HEIGHT = 300;

  constructor(private commonService: CommonService, private _session: SessionStorageService) { }

  ngAfterContentInit() {
    this.generateToken();
  }

  /*----------METHOD TO GENERATE THE ACCESS TOKEN----------*/
  generateToken() {
    this.isSessionReady = false;
    this.generateTokenSubscription = this.commonService
      .getOperationWithCustomApiKey(environment.GENERATE_TOKEN,this._session.getSession('apiKey'))
      .subscribe((res)=>{
        if(res) {
          if(res.data && res.data.token) {
            this.sessionId = res.data.token;
            this.startLivenessCheck();
          } else if(res.error) {
            this.isSessionReady = true;
            this.webcamerrorMsgEvent.emit(res.error);
          } else {
            this.isSessionReady = true;
            this.webcamerrorMsgEvent.emit(ErrorConstants.UNEXPECTED_ERROR_MSG); // APP SPECIFIC CHANGES
          }
        }
    },(error)=>{
      this.isSessionReady = true;
      this.webcamerrorMsgEvent.emit(ErrorConstants.UNEXPECTED_ERROR_MSG); // APP SPECIFIC CHANGES
    });
  }

  /*----------METHOD TO START THE LIVENESS CHECK-----------*/
  async startLivenessCheck() {
    const livenessStatus = await (window as any).startLivenessCheck(this.sessionId);
    if(!livenessStatus.livenessStarted) {
      this.isSessionReady = true;
      this.webcamerrorMsgEvent.emit(livenessStatus.error);
    } else {
      this.isSessionReady = true;
    }
  }

  /*------------METHOD TO CAPTURE THE STREAM AND CREATE A CANVAS PREVIEW----------*/
  async capture() {
    this.webcamerrorMsgEvent.emit(null); // APP SPECIFIC CHANGES
    if(document.getElementById('pl-capture-button').innerHTML === 'Snap Photo') {
      this.isReady = false;
    }
    this.livenessResult.capturedImage = null;
    this.livenessResult.result = null;
    this.livenessResult.livenessError.length = 0;
    const data = await (window as any).getLivenessStatus(this.sessionId);
    if(data) {
      this.isReady = true;
      this.livenessResult.capturedImage = data.capturedImage;
      this.livenessResult.result = data.livenessResult;
      this.livenessResult.livenessError = data.livenessError; 
      this.livenessResult.blobData = data.blobData;
      this.compress(this._blobToFileUtility.convert(this.livenessResult.blobData, 'captured_selfie_comp.PNG'));
    } 
  }
  
  /*----------METHOD TO EMIT THE RESPONSE DATA TO PARENT COMPONENT----------*/
  emitData() {
    if(this.livenessResult && this.livenessResult.result && this.livenessResult.livenessError.length <= 0) {
        this.clickedPhotoEvent.emit(this.livenessResult);
      } 
      // ERROR IS FROM API :: APP SPECIFIC CHANGES
      else if(this.livenessResult.livenessError.length > 0 && 
        (this.livenessResult.livenessError[0].indexOf(`Liveness couldn\'t be determined`) > -1 
        || this.livenessResult.livenessError[0].indexOf(`Not Available`) > -1
        )) {
          this.livenessResult.result = {
            'threshold': null,
            'image_quality_score': null,
            'score':null,
            'result':this.livenessResult.livenessError
          };
          this.clickedPhotoEvent.emit(this.livenessResult);
      } 
      else if(this.livenessResult.livenessError.length > 0) { // APP SPECIFIC CHANGES
        this.webcamerrorMsgEvent.emit(this.livenessResult.livenessError);
      }
  }

  /*----------METHOD TO COMPRESS THE CAPTURED IMAGE----------*/
  compress(f){
    const fileName = f.name.split('.')[0];
    const img = new Image();
    img.src = URL.createObjectURL(f);
    const canvas = <HTMLCanvasElement>document.getElementById('canvas-small');
    const that = this;
    img.onload = function() {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      canvas.toBlob(function(blob) {
        that.livenessResult.compressedBlobData = that._blobToFileUtility.convert(blob,fileName);
        that.emitData();
      }, 'image/jpeg', 0.5);
    };
  }

  ngOnDestroy() {
    (window as any).abortFaceDetection();
    if(this.generateTokenSubscription) {
      this.generateTokenSubscription.unsubscribe();
    }
  }

}
