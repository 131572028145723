<div id="web-capture-modal" class="modal p-5 h-100 theme_scroll">

  <!-- Modal content -->
  <div class="modal-content">

    <!--Header-->
    <div class="modal-header">
      <h5>{{title | titlecase}}</h5>
      <span class="close-icon" (click)="cancelEvent.emit('CANCEL')">&times;</span>
    </div>
  
    <div class="modal-body web-cam-modal">
      
      <div class="alert alert-danger w-100" *ngIf="errorText"><strong>Error</strong> {{errorText}}</div>
      
      <!--WEB CAM-->
      <app-web-cam
      (webcamerrorMsgEvent) = "errorText = $event"
      *ngIf="showWebcam && !faceValidationRequired" 
      (clickedPhotoEvent)="imageCaptured($event); showWebcam = false">
      </app-web-cam>

      <app-web-cam-face-validation
      *ngIf="showWebcam && faceValidationRequired"
      (webcamerrorMsgEvent) = "errorText = $event"
      (clickedPhotoEvent)="imageCapturedWithFaceValidation($event); showWebcam = false"
      ></app-web-cam-face-validation>

      <img  
        [src]="capturedImage" 
        [style.display]="capturedImage ? 'block' : 'none'" 
        class="captured-image"
        id="web-cam-image-preview"
      >
      <!--COMPRESSED IMAGE-->
      <canvas
        class="captured-image"
        [style.display]="'none'"
        id="web-cam-canvas-preview"
      ></canvas>

      <div>
        <button 
          type="button" 
          (click)="startWebcam();" 
          *ngIf="capturedImage" 
          class="btn btn-primary generic-button-blue capture-button d-inline-block mr-2">Retake
        </button>

        <button 
            type="button" 
            *ngIf="capturedImage" 
            (click)="confirmUpload()"
            class="btn btn-primary generic-button-blue capture-button d-inline-block">Confirm
        </button>
      </div>
      
    </div>
  </div>
    
</div>

<canvas #previewCanvas></canvas>
