import { TitleCasePipe } from '@angular/common';
import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/app-constants.constants';
import { AllCountryCodeConstants } from 'src/app/shared/constants/all-country-codes.constants';
import { ErrorConstants } from 'src/app/shared/constants/error.constants';
import { AlertEntity } from 'src/app/shared/interfaces/alert-entity.interface';
import { AlertModalEntity } from 'src/app/shared/interfaces/alert-modal-entity.interface';
import { ConstructAlertTextUtility } from 'src/app/shared/utilities/construct-alert-text.util';
import { Download } from 'src/app/shared/utilities/download.util';
import { environment } from 'src/environments/environment';
import { FaqConstants } from '../../constants/faq.constants';
import { CommonService } from '../../services/common-service/common-service.service';

declare const gtag: Function;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  ngOnInit() {
    window.location.href = AppConstants.IDC_LANDING_PAGE_URL;
  }

  ngOnDestroy() {}

  // public IDC_LOGO: string = AppConstants.IDC_LOGO_WHITE;
  // public IDC_HELP_MAIL_ID: string = AppConstants.IDC_HELP_MAIL_ID;
  // public isRequestCall = false;
  // public alertEntity: AlertEntity;
  // public alertType = null;
  // private constructAlertTextUtility = new ConstructAlertTextUtility();
  // public showPrivacyPolicy = false;
  // public faqList = FaqConstants.FAQ_LIST;
  // public selectedFaq = this.faqList[0];
  // public selectedSubFaq = this.faqList[0].value[0];
  // public submitInProgress = false;
  // public downloadAsPdfSubscription: Subscription;

  // /*----------REGISTRATION----------*/
  // public landingPageForm: FormGroup;
  // public formSubmitted = false;
  // public officialMailIdError = false;
  // //<!--OFFICIAL MAIL CHANGES-->
  // public unofficialMailIdList = ['@gmail.com', '@hotmail.com', 
  // '@yahoo.com', '@live.com', '@outlook.com', '@msn.com', '@gmx.com', 
  // '@yandex.com', '@mail.com', '@aol.com'];
  // public countryCodeDDError =  false;
  // public countryCodeList = AllCountryCodeConstants.ALL_COUNTRY_CODE_ARRAY;
  // public country = null;
  // public showDropdown = false;

  // private registrationSubscription: Subscription;

  // constructor(private commonService: CommonService, private fb: FormBuilder, private titlecasePipe: TitleCasePipe,
  //   private title: Title, private metaService: Meta) {
  //   this.createForm();
  //   // this.title.setTitle(AppConstants.IDC_ONBOARDING_PAGE_TITLE);

  //   // this.metaService.addTags([
  //   //   {name: 'description', content: AppConstants.IDC_ONBOARDING_PAGE_DESC},
  //   // ]);
  // }

  // /*----------METHOD TRIGGERRED WHEN REG FORM IS SUBMITTED SUCCESS/FAILURE----------*/
  // landingPageFormSubmitted($event) {
  //   if ($event && $event.type) {
  //    this.alertEntity = $event; 
  //   }
  // }

  // /*----------METHOD TO TRIGGER DOWNLOAD OF THE API DOCUMENTATION----------*/
  // downloadApiDoc() {
  //   this.downloadAsPdfSubscription = this.commonService
  //   .fetchPdfDataWithoutApiKey(environment.DOWNLOAD_PDF_ONBOARDING,null,'get')
  //   .subscribe((pdfData) => {
  //     const downloadEntity = new Download();
  //     if(pdfData) {
  //       downloadEntity.downloadFile(pdfData, 'idc-api-documentation');
  //     }
  //   }, (error)=> {
  //     // this.errorMsg = ErrorConstants.UNEXPECTED_ERROR_MSG;
  //     // console.error('OnboardingSolutionsApiComponent :: downloadApiDocument() :: 
  //     // Exception occurred while downloading file :: ',error);
  //   });
  // }

  // /*----------HELPER METHOD TO RESET THE ALERT ENTITY----------*/
  // resetAlert() {
  //   this.constructAlertTextUtility.resetAlertEntity(this.alertEntity);
  // }

  // /*---------METHOD TO CREATE THE REGISTRATION FORM----------*/
  // createForm() {
  //   this.landingPageForm = this.fb.group({
  //     'name': [null, [Validators.required, Validators.pattern(AppConstants.NAME_REGEX), 
  //      Validators.pattern(AppConstants.ORG_REGEX)]], 
  //      // ALLOWING SPACES AND ALPHABETS AND 3 CHARACATER MINIMUM
  //     'organization': [null, [Validators.required, Validators.pattern(AppConstants.ORG_REGEX)]],
  //     'countryCode': [null, [Validators.required]],
  //     'phoneNumber': [null, [Validators.required, Validators.pattern(AppConstants.ONLY_DIGITS_REGEX)]],
  //     'email': [null, [Validators.required, Validators.pattern(AppConstants.OFFICIAL_EMAIL_REGEX)]]
  //   });
  // }

  // /*----------METHOD TO VALIDATE THE FORM VALUES BEFORE SUBMITTING THE VALUES----------*/
  // validateFormValues() {
  //   let isMailIdValid = true;
  //   this.formSubmitted = true;
  //   this.landingPageForm.markAsTouched();
  //   for(const x of this.unofficialMailIdList) {
  //     if(this.landingPageForm.get('email').value.endsWith(x)) {
  //       isMailIdValid = false;
  //       this.officialMailIdError = true;
  //       break;
  //     }
  //   }
    
  //   return isMailIdValid && this.landingPageForm.valid;
  // }

  // /*----------METHOD TRIGGERRED WHEN USER SUBMITS THE REG FORM FROM LANDING PAGE----------*/
  // submitForm(formValues) {
  //   if(this.validateFormValues()) {
      
  //     const registrationEntity =  {
  //       'email': formValues.email,
  //       'name': formValues.name.trim(),
  //       'organization': formValues.organization.trim(),
  //       'phoneNumber': formValues.countryCode + formValues.phoneNumber,
  //       'url': window.location.origin + AppConstants.LANDING_PAGE_SET_PASSWORD_LINK
  //     };

  //     if(formValues.countryCode) {

  //       if(!this.submitInProgress) {
  //         this.submitInProgress = true;

  //         this.commonService.postOperation(environment.USER_ONBOARDING, registrationEntity)
  //         .subscribe((data)=> {
  //           this.submitInProgress = false;
  //           if(data && data.status === 'success') {

  //             // <!--TODO :: ENABLE BELOW GA CODE-->
  //             // this.gtag_report_conversion(null);

  //             this.landingPageFormSubmitted(
  //               this.constructAlertTextUtility.getAlertEntity('Registration Successful',
  //               'SUCCESS',ErrorConstants.REG_SUCCESS_MSG_LP));
  //           } else if(data && data.error && data.error !== '') {
  //             this.landingPageFormSubmitted(
  //               this.constructAlertTextUtility.getAlertEntity('Registration Failed',
  //               'WARNING',data.error));
  //           } else {
  //             this.landingPageFormSubmitted(
  //               this.constructAlertTextUtility.getAlertEntity('Registration Failed',
  //               'WARNING',ErrorConstants.UNEXPECTED_ERROR_MSG));
  //           }
  //         },(error) => {
  //           this.submitInProgress = false;
  //           this.landingPageFormSubmitted(
  //             this.constructAlertTextUtility.getAlertEntity('Registration Failed',
  //             'WARNING',ErrorConstants.UNEXPECTED_ERROR_MSG));
  //         });
  //       }
  //     }
  //     else {
  //       this.countryCodeDDError = true;
  //     }
  //   }
  // }

  // /*----------GOOGLE ADWORDS CODE TO TRACK EVENT WHEN A NEW ACCOUNT IS CREATED-----------*/
  // gtag_report_conversion(url) {
  //   console.log("gtag_");
  //   var callback = function () {
  //     if (typeof(url) != 'undefined') {
  //       // window.location = url;
  //     }
  //   };
  //   gtag('event', 'conversion', {
  //       'send_to': 'AW-412522870/9PQzCNWP3oACEPay2sQB',
  //       'event_callback': callback
  //   });
  //   return false;
  // }

  // /*----------HELPER METHOD TO SET THE COUNTRY CODE WHEN USER SELECTS A COUNTRY FROM THE DROPDOWN----------*/
  // setCountry(data,isOpenObjectThree) {
  //   this.landingPageForm.get('countryCode').patchValue(data.countryCode);
  //   this.country = this.titlecasePipe.transform(data.country);
  // }

  // /*----------HELPER METHOD TO SCROLL INTO THE VIEW OF THE TARGET ELEMENT----------*/
  // scroll(el: HTMLElement) {
  //   el.scrollIntoView({
  //     behavior: "smooth", 
  //     block: "start", 
  //     inline: "nearest"});
  // }

  // closeCoutryDropDown() {
  //   this.showDropdown = false;
  //   !this.landingPageForm.get('countryCode').value ? this.country=null : '';
  // }

  // ngOnDestroy() {
  //   if(this.registrationSubscription) { this.registrationSubscription.unsubscribe(); }
  //   if(this.downloadAsPdfSubscription) { this.downloadAsPdfSubscription.unsubscribe(); }
  // }

}
