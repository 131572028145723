<div class="body_content_section h-100" *ngIf="undefined != portalType">
	<img class="idc-logo" [src]="IDC_LOGO">

	<!--LOGIN SECTION-->
	<ng-container [ngSwitch]="pageType">
		<div class="login_section">
			<div class="login-container">
				<div class="login-header">

					<ng-container *ngSwitchCase="'LOGIN_PAGE'">
						<h5>Hey there, Welcome!</h5>
						<p>Get started with IDcentral for your business</p>
					</ng-container>

					<ng-container *ngSwitchCase="'PASSWORD_RESET_PAGE'">
						<h5>Forgot Password?</h5>
						<p>Enter your email address to reset your password.</p>
					</ng-container>

					<img src="assets/img/IDC_LOGIN_HEADER.png" alt="IDC_IMG_HEADER.png">
				</div>
				<!--FIX:: ERROR MESSAGE SHOULD APPEAR ON BUTTON CLICK NOT TOUCHED STATE CHANGEE-->
				<div class="login-form-container">

					<!--PASSWORD RESET FORM-->
					<ng-container *ngSwitchCase="'PASSWORD_RESET_PAGE'">
						<form [formGroup]="passResetForm" (ngSubmit)="resetPassword(passResetForm.value)">
							<div class="form-group">
								<div class="custom-input" [ngClass]="(isFormSubmitted && passResetForm.controls['resetEmail'].errors?.required) || (passResetForm.controls['resetEmail'].touched && passResetForm.controls['resetEmail'].errors?.pattern) ? 'error-input' : ''">
									<label>Email <span class="required-icon">*</span></label>
									<input 
										type="email" 
										(input)="isFormSubmitted=false"
										id="email" 
										placeholder="Enter email" 
										name="email"
										formControlName="resetEmail"
									>
								</div>

								<!--VALIDATION ERROR-->
								<span *ngIf="isFormSubmitted && passResetForm.controls['resetEmail'].errors?.required" class="validation-error">Please enter the email</span>
								<span *ngIf="passResetForm.controls['resetEmail'].touched && passResetForm.controls['resetEmail'].errors?.pattern" class="validation-error">Invalid E-Mail</span>
							</div>
							<button type="submit" id="submit-button" class="btn btn-primary" [style.cursor]="isReady ? 'pointer' : 'default'" [style.opacity]="isReady ? 1 : 0.7">
								<ng-container *ngIf="!isReady">
									<app-loader [loaderColor]="'light-grey'"></app-loader>
								</ng-container>
								<ng-container *ngIf="isReady">
									Submit
								</ng-container>
							</button>
						</form>

						<label class="signup_button">Remember password?<span (click)="setPageType(LOGIN_PAGE_KEY)">Login</span></label>
					</ng-container>

					<!--LOGIN FORM-->
					<form [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)" *ngSwitchCase="'LOGIN_PAGE'">

						<div class="form-group">
							<div class="custom-input" [ngClass]="(isFormSubmitted && loginForm.controls['email'].errors?.required) || (loginForm.controls['email'].touched && loginForm.controls['email'].errors?.pattern) ? 'error-input' : ''">
								<label>Email <span class="required-icon">*</span></label>
								<input 
									type="email"
									(input)= "isFormSubmitted = false" 
									id="email" 
									placeholder="Enter E-Mail" 
									name="email"
									formControlName="email"
								>
							</div>

							<!--VALIDATION ERROR MESSAGES-->
							<!-- <span *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.required" class="validation-error">Please enter E-Mail</span> -->
							<span *ngIf="isFormSubmitted && loginForm.controls['email'].errors?.required" class="validation-error">Please enter E-Mail</span>
							<span *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.pattern" class="validation-error">Invalid E-Mail</span>
						</div>
						<div class="form-group">
							<div class="custom-input" [ngClass]="(isFormSubmitted && loginForm.controls['pass'].errors?.required) ? 'error-input' : ''">
								<label>Password <span class="required-icon">*</span></label>
								<input 
									type="password"
									(input)= "isFormSubmitted = false"
									id="password" 
									placeholder="Enter password" 
									name="password"
									formControlName="pass"
								>
							</div>

							<!--VALIDATION ERROR MESSAGES-->
							<span *ngIf="isFormSubmitted && loginForm.controls['pass'].errors?.required" class="validation-error">Please enter password</span>
						</div>
						<button type="submit" class="btn btn-primary" id="login-button" [style.cursor]="isReady ? 'pointer' : 'default'" [style.opacity]="isReady ? 1 : 0.7">
							<ng-container *ngIf="!isReady">
								<app-loader [loaderColor]="'light-grey'"></app-loader>
							</ng-container>
							<ng-container *ngIf="isReady">
								Login
							</ng-container>
						</button>
						<div class="form-group forgot_password_label text-left custom-link">
							<span (click)="setPageType(PASSWORD_RESET_PAGE_KEY)">Forgot Password?</span>
						</div>
					</form>
				</div>
			
			</div>
		</div>
	</ng-container>

	<app-alert-message [alertObject]="alertObject" (resetAlertEvent)="this.alertObject = $event"></app-alert-message>
</div>