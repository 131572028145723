export class ErrorConstants {
    // TODO: SEGREGGATE ADMIN AND CLIENT RELATED MESSAGES
    public static UNEXPECTED_ERROR_MSG =
    'Something went wrong. We are working on getting this fixed as soon as we can. Please try again later.';
    public static PASSWORD_RESET_MSG = 'Instructions to reset your password has been sent to your email ID.';
    public static PASSWORDS_DONT_MATCH_MSG = 'Passwords dont match';
    public static PASSWORDS_NOT_GENERATED_YET = 'Password for this account has not been generated yet!';
    public static SELECT_ALTEAST_ONE_BUCKET_ERROR_MSG = 'Select atleast one bucket to continue.';
    public static PRICING_DETAILS_ERROR_MSG = 'Select atleast one bucket to continue.';
    public static PAYMENT_SUCCESS_MESSAGE = 'Payment has been done successfully!';
    public static CONSENT_ERROR_MSG = 'We can\'t proceed with your request since consent has not been explicitly provided';
    public static FILE_EXTENSION_ERROR = 'Invalid file selected.';
    public static REQUEST_API_KEY_MSG = 'API Key(s) has been sent to your registered email id';
    public static REG_SUCCESS_MSG_LP = 'Thank you. We have sent you an email with an activation link, and demo application. Kindly, check the email to proceed.';
    public static OCR_ERROR = 'Something went wrong. We are working on getting this fixed as soon as we can. Please try again later.';
    public static WEBCAM_ISSUE = 'Unable to access your webcam. Please check browser settings.';
    public static CORRUPT_FILE_ERROR = 'The file is corrupt and cannot be openend';
    public static OCR_INVALID_DOC_TYPE_ERROR = 'We couldn\'t recognize this document. Please check the document and re-upload.';
    public static OCR_INVALID_SELFIE_ERROR = 'We couldn\'t recognize your selfie. Please ensure that there is sufficient lighting & you are the only person in the selfie.';
    public static AML_MAX_SCANNED_NAME_ERROR = 'Maximum number of scanned name exceeded';
    public static FACE_CATALOG_MAX_FILE_ERROR = 'Maximum number of files exceeded';
    public static LIVENESS_ERROR = 'Liveness couldn\'t be determined';
    public static RECORD_SUCCESS_MSG = 'Record(s) created successfully!';
    public static RECORD_UPDATE_MSG = 'Record(s) updated successfully!';
}
