import { Component, Input, ViewChild, OnChanges, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-idc-icon',
  templateUrl: './idc-icon.component.html',
  styleUrls: ['./idc-icon.component.scss']
})
export class IdcIconComponent implements OnChanges, AfterViewInit {

  @Input() public iconType = null;
  @Input() public iconColor = null;
  @Input() public iconHeight = null;
  @Input() public iconWidth = 'auto';
  @ViewChild('svgPath') public svgPath;

  constructor() {}

  ngAfterViewInit() {
    if (this.iconColor && this.svgPath) {
      this.svgPath.nativeElement.style.fill = this.iconColor;
    }

    if (this.iconHeight && this.iconWidth && this.svgPath) {
      this.svgPath.nativeElement.style.height = this.iconHeight;
      this.svgPath.nativeElement.style.width = this.iconWidth;
    }
  }

  ngOnChanges() {
    if (this.iconColor && this.svgPath) {
      this.svgPath.nativeElement.style.fill = this.iconColor;
    }

    if (this.iconHeight && this.iconWidth && this.svgPath) {
      this.svgPath.nativeElement.style.height = this.iconHeight;
      this.svgPath.nativeElement.style.width = this.iconWidth;
    }
  }
}
