
<div id="error-page" *ngIf="errorMessage">
  <img [src]="IDC_LOGO" class="logo">
  <div id="error-page-container">
    <span class="error-code"> Oops!</span>
    <div class="text">{{errorMessage}}</div>
    
    <div class="text-sm">Try using the button below to go to main page of the site</div>
    <button type="button" class="btn btn-primary" #navigateToLoginBtn (click)="redirectToLogin()">Go To Login</button>
  </div>
</div>