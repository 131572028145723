<div id="landing-page-reg-form">

  <h5>Register Now</h5>

  <p>
    Fill the form to get access to IDcentral’s (6-months free) onboarding solution
  </p>
  <form [formGroup]="landingPageForm" (ngSubmit)="submitForm(landingPageForm.value)">
    <div class="form-group">
      <label for="name">Name <span class="required-icon">*</span></label>
      <input 
        type="text" 
        [ngClass]="(landingPageForm.get('name').touched && landingPageForm.get('name').errors?.pattern) ||
        (formSubmitted && landingPageForm.get('name').errors?.required) ? 'error-input' : ''" 
        formControlName="name" 
        class="form-control idc-generic-input">

      <div *ngIf="landingPageForm.get('name').touched && landingPageForm.get('name').errors?.pattern" class="validation-error">
        Invalid Name
      </div>

      <div *ngIf="formSubmitted && landingPageForm.get('name').errors?.required" class="validation-error">
        Please enter name
      </div>
    </div>

    <div class="form-group">
      <label for="name">Organization Name <span class="required-icon">*</span></label>
      <input 
        type="text" 
        [ngClass]="(landingPageForm.get('organization').touched && landingPageForm.get('organization').errors?.pattern) ||
        (formSubmitted && landingPageForm.get('organization').errors?.required) ? 'error-input' : ''" 
        formControlName="organization" 
        class="form-control idc-generic-input">

      <div class="validation-error" *ngIf="landingPageForm.get('organization').touched && landingPageForm.get('organization').errors?.pattern">
        Invalid Organization Name
      </div>

      <div class="validation-error" *ngIf="formSubmitted && landingPageForm.get('organization').errors?.required">
        Please enter organization name
      </div>
    </div>

    <div class="form-group">
      <label for="name">Email <span class="required-icon">*</span></label>
      <!-- <label for="name">Official Email <span class="required-icon">*</span></label> --> <!--OFFICIAL MAIL CHANGES-->
      <input 
        type="text" 
        (input)="officialMailIdError = false"
        [ngClass]="officialMailIdError || ((landingPageForm.get('email').touched && landingPageForm.get('email').errors?.pattern) 
          || (formSubmitted && landingPageForm.get('email').errors?.required)) ? 'error-input' : ''" 
        formControlName="email" 
        class="form-control idc-generic-input">

      <div class="validation-error" *ngIf="landingPageForm.get('email').touched && landingPageForm.get('email').errors?.pattern">
        Invalid Mail Id
      </div>

      <div *ngIf="officialMailIdError" class="validation-error">
        Please enter your official mail id
      </div>

      <div class="validation-error" *ngIf="formSubmitted && landingPageForm.get('email').errors?.required">
        Please enter email id
      </div>
    </div>

    <div class="form-group">
      <label for="name">Country <span class="required-icon">*</span></label>

      <div class="position-relative w-100">

        <div class="form-control country-input dd-element" 
          [ngClass]="(formSubmitted && landingPageForm.get('countryCode').errors?.required) ? 'error-input' : ''">
          <input type="text" 
          (click)="showDropdown = !showDropdown"
          (input)="showDropdown = true; landingPageForm.get('countryCode').patchValue(null); countryCodeDDError = false" 
          class="dd-element" [(ngModel)]="country" [ngModelOptions]="{standalone: true}">

          <span>
            <app-idc-icon 
              *ngIf="!showDropdown"
              [iconType]="'ARROW_DOWN_ICON'" 
              [iconColor]="'#9B9B9B'"></app-idc-icon>
          </span>


          <span>
            <app-idc-icon 
              *ngIf="showDropdown"
              [iconType]="'ARROW_UP_ICON'" 
              [iconColor]="'#9B9B9B'"></app-idc-icon>
          </span>
        </div>
        

        <input type="hidden" formControlName="countryCode"
        
        >
        <!-- {{country}}
        {{(countryCodeList | filterCountryCode : country).length}}
        {{country && (countryCodeList | filterCountryCode : country).length>0}}
        {{isOpenObjectThree.isOpen}} -->

        <ul class="p-0 theme_scroll idc-dropdown dd-element" *ngIf="showDropdown && (countryCodeList | filterCountryCode : country).length>0"
        >
          
          <ng-container>
            <li class="dd-element" *ngFor="let data of countryCodeList | filterCountryCode : country" (click)="setCountry(data); showDropdown = false">
              <span class="dd-element" *ngIf="data.country">{{data.country | titlecase}} {{'(+'+data.countryCode+')'}}</span>
            </li>
          </ng-container>
          
        </ul>
       
        <!-- <select class="form-control idc-generic-input" formControlName="countryCode"
        [ngClass]="(formSubmitted && landingPageForm.get('countryCode').errors?.required) ? 'error-input' : ''">
          <option value="+91">India (+91)</option></select> -->
      </div>

      <div class="validation-error" *ngIf="formSubmitted && landingPageForm.get('countryCode').errors?.required">
        Please select a country from the dropdown
      </div>
    </div>

    <div class="form-group phone-number">
      <label for="name">Phone Number <span class="required-icon">*</span></label>

      <div>
        <input 
          type="number" 
          [ngClass]="(landingPageForm.get('phoneNumber').touched && landingPageForm.get('phoneNumber').errors?.pattern) ||
          (formSubmitted && landingPageForm.get('phoneNumber').errors?.required) ? 'error-input' : ''" 
          formControlName="phoneNumber" 
          class="form-control idc-generic-input" 
        >
      </div>
      
      <div class="validation-error" *ngIf="landingPageForm.get('phoneNumber').touched && landingPageForm.get('phoneNumber').errors?.pattern 
      || landingPageForm.get('countryCode').touched && landingPageForm.get('countryCode').errors?.pattern">
        Invalid Phone Number
      </div>

      <div class="validation-error" *ngIf="(formSubmitted && (landingPageForm.get('phoneNumber').errors?.required))">
        Please enter phone number
      </div>
    </div>

    <div>
      <button type="submit" class="btn btn-primary generic-button-blue w-100">Submit</button>
    </div>
  </form>

  <span class="m-0 mt-2 privacy-policy">By clicking this you agree to our 
    <!-- <span class="custom-link" (click)="showPrivacyPolicy.emit(true)">privacy policy.</span> -->
    <a [href]="privacyPolicyLink" class="custom-link" target="_blank">privacy policy</a>.
  </span>
</div>

<app-loader-modal
[loaderText]="'Processing..'"
*ngIf="!isReady"
></app-loader-modal>