import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundOff'
})
export class RoundOffPipe implements PipeTransform {

  transform(data: any): any {
    try {
      if (data && data > 0) {
        return Math.round(data * 100) / 100; // ROUNDS UPTO TWO DECIMAL VALUES
      }
    } catch (e) {
      // console.error("RoundOffPipe :: transform() :: Exception occurred :: ",e);
    }
    return data;
  }
}
