<div id="terms-and-conditions">

  <div class="idc-logo">
    <img [src]="IDC_LOGO" alt="IDCentral_Logo.png">
  </div>

  <div class="text-center mb-3 mt-5"><h5>TERMS AND CONDITIONS OF SERVICE(S)</h5></div>

  THESE TERMS AND CONDITIONS (“<strong>TERMS”</strong> OR “<strong>AGREEMENT”</strong>) 
  ARE A LEGAL CONTRACT BETWEEN YOU, EITHER YOU AS A CUSTOMER OR AN ENTITY OR ORGANIZATION ON WHOSE BEHALF YOU ARE ENTERING 
  INTO THESE TERMS AND CONDITIONS (“<strong>CUSTOMER</strong>” OR “<strong>YOU</strong>” OR “<strong>YOUR</strong>”) 
  AND SUBEX DIGITAL LLP (“<strong>SUBEX</strong>”) FOR THE ACCESS TO AND USE OF THIS WEBSITE, 
  WWW.IDCENTRAL.IO, AND/OR API’S WHICH INCLUDES TEXT, MEDIA, DOCUMENTATION, PICTURES AND OTHER CONTENT 
  (COLLECTIVELY, THE “<strong>PLATFORM</strong>”). 
  THESE TERMS ALSO GOVERN THE CUSTOMER’S USE OF IDCENTRAL’S, ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING 
  ENABLED SERVICE WHICH IDCENTRAL OFFERS AS PLATFORM AS A SERVICE.

  <br><br>

  CUSTOMER AND SUBEX MAY BE REFERRED TO HEREIN INDIVIDUALLY AS A “PARTY” AND COLLECTIVELY AS THE “PARTIES” 
  AS THE CONTEXT REQUIRES.
  <br><br>
  
  THESE TERMS GOVERN THE USE AND ACCESS BY THE CUSTOMER OF THE PLATFORM AND OF THE SERVICES UNDER ACCEPTED COMMERCIAL 
  TERMS OR AS A FREE TRIAL, AS THE CASE MAY BE.
  <br><br>

  CUSTOMER(S) DOMICILED OR HAVING BUSINESS TRANSACTIONS IN U.S EMBARGO NATIONS LISTED UNDER U.S. EXPORT REGULATIONS, 
  ARE PROHIBITED FROM ACCESSING & USING THE SERVICES.
  <br><br>

  THIS AGREEMENT IS EFFECTIVE BETWEEN CUSTOMER AND SUBEX AS OF THE DATE OF CUSTOMER’S ACCEPTING THIS AGREEMENT. 
  SUBEX RESERVES THE RIGHT TO CHANGE THE TERMS FROM TIME-TO-TIME.
  <br><br>

  <div><strong>1. DEFINITIONS</strong></div>

  <div style="margin-left: 12px;">
    Capitalized terms have the definitions as set forth below or inline in this Agreement. <br> <br>
    <strong>“Affiliate”</strong> means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity. “Control,” for purposes of this definition, means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.
    <br><br>
    <strong>“Content”</strong> means information obtained by IDCentral from publicly available sources or its third-party data providers used to generate results which are then made available to Customer through the Services or pursuant to a Subscription Form, as more fully described in the Documentation.
    <br><br>
    <strong>“Customer”</strong> the company or other legal entity (not being a Competitor) for which an individual is accepting this Agreement together with Affiliates of that company or entity (for so long as they remain Affiliates) which have entered into Subscription Forms.
    <br><br>
    <strong>“Customer Data” or “Data”</strong> means electronic data and information submitted by or for Customer to the Services, excluding Content and may include details of (Email ID, Phone number, name, address etc.)
    <br><br>
    <strong>“Documentation”</strong> means the applicable Service’s documentation, and its usage guides and policies, as updated from time to time and made available on IDCentral.
    <br><br>
    <strong>“IDCentral”</strong> shall mean the artificial intelligence and machine learning enabled identity analytics solution provided by Subex.
    <br><br>
    <strong>“Intellectual Property Rights”</strong> shall mean any patents, designs, utility models, trademarks, service marks, database rights, and copyright works, moral rights, know-how, trade secrets, trade or business names and any other industrial and proprietary property (whether registered or unregistered) any right to apply and any application for such rights in any country and any licenses under or in respect of such rights.
    <br><br>
    <strong>“Malicious Code”</strong> means code, files, scripts, agents or programs intended to do harm, including, for example, viruses, worms, time bombs and Trojan horses.
    <br><br>
    <strong>“Subscription Form”</strong> means an ordering document ore-mail or online order specifying the Services to be provided hereunder that is entered into between Customer and IDCentral or any of their Affiliates, including any addenda and supplements thereto. By completing the Subscription Form hereunder, an Affiliate agrees to be bound by the terms of this Agreement as if it were an original party hereto.
    <br><br>
    <strong>“Pilot Subscription” or “Pilot Phase”</strong> means Services or use of the Platform/IDCentral for a period of 6 months, which Customer or Customer’s Affiliate has registered for a trial, free of charge or on payment of fee (as described in the Subscription Form), for
    the sole purpose of internal evaluation, by signing a Subscription Form. All or some obligations by Subex will be restricted under the Pilot Phase.
    <br><br>
    <strong>“Purchased Services”</strong> means paid Services or use of the Platform/IDCentral, that Customer or Customer’s Affiliate purchases under a Subscription Form or online purchasing portal, as distinguished from those provided pursuant to a Pilot Phase.
    <br><br>
    <strong>“Report”</strong> the score or verification or score or deliverable generated post the processing of Customer Data by way of artificial intelligence and/or machine learning enabled analytics solution or otherwise, as detailed in the Subscription Form.
    <br><br>
    <strong>“Services”</strong> means the of IDCentral’s, artificial intelligence and machine learning enabled service which is offered as platform as a service that are ordered by Customer under a Subscription Form or online purchasing portal, or provided to Customer under a Pilot Subscription or Purchased Services and made available online by IDCentral. “Services” exclude Content.
    <br><br>
    <strong>“Authorized User”</strong> means an individual who is authorized by Customer to use a Service, for whom Customer has purchased a subscription (or in the case of any Services provided by IDCentral without charge, for whom a Service has been provisioned), and to whom Customer (or, when applicable, IDCentral at Customer’s request) has supplied a user identification and password (for Services utilizing authentication). Authorized Users may include, for example, employees, consultants, contractors and agents of Customer, and third parties with which Customer transacts business.
    </div>

    <div class="mt-2"><strong>2. INFORMATION YOU PROVIDE</strong></div>

    <div class="mt-2"><strong>2.1 Privacy Policy</strong></div>
    <div>
      In addition to these Terms, your use of the Platform is governed by IDCentral’s Privacy Policy, 
      which is posted on the Website, https://www.idcentral.io/privacy-policy/. 
      You agree that Subex’s collection, use and sharing of: (a) any personal information will be as set forth in the 
      Privacy Policy, which may be amended by Subex from time to time; and (b) any information other than personal 
      information shall not be restricted by the terms of the Privacy Policy. The Privacy Policy and any posted terms or 
      guidelines or legal disclaimers on the Platform are hereby incorporated by reference into these Terms. 
      You consent to Subex right to any information other than personal information that you provide to Subex and that 
      IDCentral and/or Services and/or Platform may use such information as specified herein. Unless expressly agreed 
      otherwise, such information will be deemed to be non-confidential. 
      The Terms subsequently address how Subex can deal with information that you provide which is not personal information.
    </div>

    <div class="mt-2"><strong>2.2 Customer Data</strong></div>

    <div>
      Any Customer Data shared under the Terms by You is after taking appropriate consent in writing from data subjects, 
      data processors or other providers (as the case may be ) of the Customer Data 
      (data subjects, who will all be natural persons). 
      All Customer Data provided will be in compliance with all the applicable laws, and requirements on data consent 
      including all applicable laws on data storage, data transfer and data use. You will be solely responsible for ensuring 
      that the purposes for which you have obtained consent from the data subjects corresponds with the Services you 
      have obtained under these Terms and 
      (i) are in no way more excessive than those You have sought from the data subjects 
      for the use of such Customer Data for the purposes and use of the Platform/IDCentral or rendering the Services 
      hereunder and generation of Reports, 
      (ii) are consistent with the use of such Customer Date for processing on the 
      Platform/IDCentral or rendering the Services hereunder and generation of Reports, and 
      (iii) that it has all authority to use such Customer Data for the purposes and use of the Platform/ IDCentral or 
      rendering the Services hereunder and the generation of Reports. Further You will be responsible for any requests for 
      changes, modification and erasure of data subjects data and in the compliance of the same.
    </div>

    <div class="mt-2"><strong>3. ACCESSING THE PLATFORM AND SERVICES</strong></div>
    <div>
      <strong>Pilot Phase:</strong> If specified under a Subscription Form and based upon the warranty from the Customer that it will 
      use the Platform or Services solely for their internal evaluation purposes. Subex shall perform the limited Services 
      or allow limited use of the Platform during the Pilot Phase enumerated hereunder in accordance with the Subscription. 
      Upon completion of the Pilot Phase, the Customer will automatically begin to receive Services as specified in the 
      Subscription Form. Customer understands that they are receiving this Pilot Phase on the sole basis of these 
      being converted into Purchased Services upon the completion of the Pilot Phase.
      <br><br>

      Upon completion of the Pilot Phase, Subex shall charge the Customer for the Services being offered.
      <br><br>
      <strong>Use of the Services:</strong> In consideration of the Fee paid by You and subject to your adherence to these 
      Terms, (where applicable for the Pilot Phase) Subex shall provide You the right to use the Services. 
      as more clearly described in relevant Subscription Form. You may access the Platform solely for the purpose of 
      accessing and using the Services.
      <br><br>
      Subex shall provide the Services under these Terms by APIs. Subex enforces limit on your use of the APIs at 
      10,000 API pulls per day. You agree to, and will not attempt to circumvent, limitation on usage of APIs.
      <br><br><br>
      By accessing the Platform, You represent and warrant:
      <br><br>
      i. that You are legally entitled to enter into these Terms;
      <br><br>
      ii. that any Personal Information provided does not relate to a minor person;
      <br><br>
      iii. You shall not allow any other person or entity to access or use your account; You warrant that You shall not share logon identifiers and passwords etc with anyone who is not an Authorized User and shall keep such logon identifiers and passwords confidential. You further warrant that You will notify any breach of such security at the earliest. Subex shall not be liable for any loss or damage arising from Customer's or any Authorized User's failure to comply with this section. In case of such breach, Customer shall be liable for payment in case of any unauthorized requests.
      <br><br>
      iv. You shall be solely responsible for all activity associated with your account, including any data, text, 
      links, media or other content that you submit to IDCentral via the Platform (collectively, the “<strong>User Content</strong>”) 
      and keeping a duplicate content of User Content. Such User Content also includes any content you submit to IDCentral via IDCentral’s 
      “Contact Us” form on the “Subscribe Our Newsletter” page, or by emailing IDCentral; All User Content that you may post to the Platform shall be your 
      wholly original material (except for material that you are using with the permission of its owner), and does not infringe or violate any copyright, 
      trademark or other rights of any third party.
      <br><br>
      v. You shall not post User Content that is hateful, threatening, pornographic, obscene, abusive, unlawful, harassing, racially or ethnically offensive, 
      libelous or defamatory, or any content that encourages unlawful conduct;
      <br><br>
      vi. that when retrieving information from the Platform, You do not: (a) use or attempt to use spiders, robots, avatars, intelligent agents, or any other 
      extraction, navigation, or search tools, except for a normal browser as used by a natural person, (b) aggregate, copy or duplicate any of the materials or 
      information available from the Platform except for the small amount of materials and information temporarily required for an ordinary single use of the Platform, 
      (c) access data not intended for you or attempt to gain unauthorized access to, interfere with, or disrupt any parts of the Platform, the server on which 
      the Platform is stored, or any server, computer, or database connected to the Platform, (d) access the Platform for the purpose of competing with IDCentral 
      or using the Platform for purposes of benchmarking or other similar purposes, (e) use the Platform in any manner that could disable, overburden, damage, or 
      impair the site or interfere with any other party’s usage of the Platform, or (f) attack the Platform via a denial-of-service attack or a distributed 
      denial-of-service attack.
      <br><br>
      vii. You shall comply all applicable state and local laws including but not limited to laws related to privacy or publicity; Further, you shall not use 
      the Platform for any illegal, unlawful or unauthorized purpose;
      <br><br>
      viii. You shall not transmit worms, viruses or any code of a destructive nature to IDCentral, other users, or the Platform;
      <br><br>
      Any use of the Platform in violation of the foregoing violates these Terms and may result in, among other things, termination or suspension of your 
      rights to use the Platform.
      <br><br>
      You hereby grant Subex irrevocable, royalty-free, worldwide, perpetual license to use any Data which is processed by the Platform or made accessible 
      to Subex while providing the Services under this Agreement. Such use may include but not limited to develop, to enrich and otherwise use for Subex’s 
      business purpose and improvement of Subex products or services.
      <br><br>

      <div class="mt-2"><strong>4. OBLIGATIONS OF THE CUSTOMER</strong></div>
      <div>
        <br>
        4.1 Pursuant to the terms and conditions of this Agreement, Customer shall have the following obligations:
        <br><br>
        a. provide access to Subex where interface is required with any third-party equipment or facility within Customer’s control with regards to the Pilot Phase and/or during the Purchased Services and to grant access to its premises/network to install IDCentral for the performance of the Pilot Phase and/or during the Purchased Services, if required;
        <br><br>
        b. to procure, store, manage and share Customer Data with the requisite permissions and approvals, consistent with applicable data protection regulations and purposes of this Agreement. For all purposes of this Agreement, Customer shall mean the data controller as defined under Minister of Communications & Informatics Regulation No. 20 regarding Protection of Personal Data in an Electronic System;
        <br><br>
        c. the data will materially comply with the requirements set out in these Terms and any associated documentation.
      </div>

      <div class="mt-2"><strong>5. FEES AND PAYMENT</strong></div>

      <div>
        <br>
        5.1 Customer will pay all fees specified in Subscription Forms. Customer agrees that payment obligations are non-cancelable, and fees paid are non-refundable. Any additional gateway charges may be applicable to the Services apart from the fee mentioned above. Customer may issue a valid purchase order in accordance with the relevant Subscription Form, but the same will not constitute a mandatory requirement for invoicing. Subex will invoice Customer in advance and otherwise in accordance with the relevant Subscription Form. Unless otherwise stated in the Subscription Form, invoiced fees are due net seven (07) days from the invoice date. Customer is responsible for providing complete and accurate billing and contact information to Subex and notifying Subex of any changes to such information. If any invoiced amount is not received by Subex by the due date, the same will have a negative impact on creditworthiness of such Customer and then without limiting Subex’ rights or remedies, (a) those charges may accrue late interest at the rate of 1.5% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower, and/or (b) Subex may at its sole discretion, suspend such Customer’s right to access & use any or all Services, without any notice, and/or (c) Subex may at its sole discretion will have a right to refuse any request for a future subscription from such Customer, and/or (d) Subex may condition future subscription renewals and Subscription Forms on payment terms shorter than those specified in the “Invoicing and Payment” section.
        <br><br>
        5.2 Subex’s fees do not include any payment gateway charges, taxes, levies, duties or similar governmental assessments of any nature, including, 
        for example, value-added, sales, use or withholding taxes, assessable by any jurisdiction whatsoever (collectively, <strong>“Taxes”</strong>). 
        Any payment gateway charges which are applicable are additional and will be borne by You. You are responsible for paying all Taxes 
        associated with Services procured hereunder. If Subex has the legal obligation to pay or collect Taxes for which Customer is responsible 
        under this section, Subex will invoice Customer and Customer will pay that amount unless Customer provides Subex with a valid tax exemption 
        certificate authorized by the appropriate taxing authority. For clarity, Subex is solely responsible for taxes assessable against it based on its income, 
        property and employees.
        
      </div>

      <div class="mt-2"><strong>6. PROPRIETARY RIGHTS AND LICENSES</strong></div>
      <div>
        <br>
        <strong>6.1 Reservation of Rights:</strong> Subject to the limited rights expressly granted hereunder, Subex, its Affiliates, its licensors and content 
        providers reserve all of their right, title and interest in and to the Services and Content, including all of their related intellectual property rights. 
        No rights are granted to Customer hereunder other than as expressly set forth herein. Any Intellectual Property Rights subsisting in the Platform and Content 
        and all information in respect of the Pilot Phase made available to You under this Agreement, including but not limited to patent, trademarks, copyright, 
        know-how, shall, at all times, be retained by Subex or its licensors and the Platform shall not be copied, reproduced or used for any purpose other than that 
        contemplated under these Terms. None of the provisions of this Agreement shall be deemed or construed to be a transfer, assignment or waiver of such 
        Intellectual Property Rights. You shall follow all reasonable instructions of Subex with regard to the use of the Intellectual Property Rights or other 
        indications of the proprietary rights of Subex or its licensors in the Platform. As between the parties, Customer owns all intellectual property rights in 
        Customer Data and Subex shall own all intellectual property rights in and to the Services and Content. Except as expressly provided in these Terms, any 
        reproduction, display or other dissemination of information or content contained in this Platform is strictly prohibited and constitutes copyright infringement.
        <br><br>

        <strong>6.2 Access to and Use of Content:</strong> Customer has the right to access and use applicable Content subject to the terms of applicable
        Subscription Forms, this Agreement and the Documentation. 
        <br><br>
        <strong>License by Customer to Use Feedback:</strong> Customer grants to Subex and its Affiliates a worldwide, 
        perpetual, irrevocable, royalty- free license to use and incorporate into its Services any suggestion, enhancement request, recommendation, 
        correction or other feedback provided by Customer or Authorized Users relating to the operation of Subex’s or its Affiliates’ Services. 
        Subex will be entitled to retain or store any Customer Data used for consumption of Services, for the sole purpose of billing and support investigation 
        and Customer hereby consent for such retention or storage. Subex will also be entitled to closely monitor use of Services by the Customer, 
        for the sole purpose of improving the Service offering by Subex.
        <br><br>
        6.3 The copyrights and other intellectual property in the Platform are owned by Subex, its independent contractors, and its licensors. 
        Subject to your compliance with these Terms, you are authorized to view, store, print, reproduce, copy, and distribute any pages within the Platform 
        for non-commercial use within your organization only. All other rights are reserved. This right is non-transferable and non-sublicensable. 
        You may not copy, distribute, transmit, publish, sell, transfer, create derivative works of, or otherwise exploit any such material that you print off. 
        In consideration of this authorization, you agree that (a) any copy of these documents which you make shall retain all copyright and other proprietary 
        notices contained in such documents and (b) these Terms are included with any distribution. Further You are not authorized to store, copy, distribute transmit, 
        publish or sell any Content.
        <br><br>
        6.4 By submitting User Content to the Platform or to Subex, you hereby grant Subex a worldwide, non-exclusive, royalty-free, sub-licenseable and 
        transferable license to use, reproduce, distribute, prepare derivative works of, and display the User Content when operating the Platform and for Subex’s 
        internal business purposes. You acknowledge that Subex is not responsible for any User Content you provide and that Subex may reject or delete such 
        User Content in Subex’s sole discretion. Subex also assumes no responsibility for loss of information, howsoever such information is lost, including 
        information which is permanently deleted either through the action of an employee or consultant or other who has access to the information, 
        through failure of a computer network, or through any policy or directive of Subex for destruction of data.
      </div>

      <div class="mt-2"><strong>7. RESCTRICTIONS ON USE OF PLATFORM</strong></div>

      <div>
        <br>
        Except as these Terms expressly permits, the Customer, shall not itself or through any affiliate, agent or third party:
        <br><br>
        7.1 copy the Platform, or reproduce either the Platform in which it is stored either in whole or in part;
        <br><br>
        7.2 sell, lease, license, sub-license, modify, correct, adapt, translate, enhance, or otherwise prepare derivative works or improvements of the Platform;
        <br><br>
        7.3 use the Platform to provide processing services to third parties, commercial timesharing, rental or sharing arrangements, or on a “service bureau” basis or otherwise use or allow others to use the Platform for the benefit of any third party;
        <br><br>
        7.4 reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code or underlying ideas or algorithms of the Platform, in whole or in part;
        <br><br>
        7.5 bypass or breach any security device or protection used for or contained in the Platform;
        <br><br>
        7.6 use the Platform in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right, this Agreement or other right of any person, or that violates any applicable Law;
        <br><br>
        7.7 use the Platform for purposes of: (i) benchmarking or competitive analysis of the Platform; (ii) developing, using, or providing a competing software product or service; or (iii) any other purpose that is detrimental or commercial disadvantageous to the Platform;
        <br><br>
        7.8 transport, export, or re-export the Platform, in whole or in part.
        <br><br>
        7.9 Customer shall not use or allow any of its employees or consultants who have access or visibility to the Platform to investigate, research, develop or design any platform or product or service from the information available from or on the Platform or use any form of Platform information including but not limited to its technology know-how or Intellectual Property Rights or other technical information in furtherance of any other purpose other than that which is contemplated in this Agreement during the Trial Service and for a period of twenty-four (24) months after the expiration or termination of this Agreement.

        <div class="mt-2"><strong>8. CONFIDENTIALITY</strong></div>

        <div>
          <br>
          <strong>8.1 Definition of Confidential Information:</strong> “Confidential Information” means all information disclosed by a party 
          (<strong>“Disclosing Party”</strong>) to the other party (<strong>“Receiving Party”</strong>), whether orally or in writing, that is designated as 
          confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. 
          Confidential Information of Customer includes Customer Data; Confidential Information of Subex includes the Services and Content, and the terms and conditions 
          of this Agreement and all Subscription Forms (including pricing). 
          Confidential Information of each party includes business and marketing plans, technology and technical information, product plans and designs, and 
          business processes disclosed by such party. Any and all logon identifiers and passwords provided hereunder are deemed Confidential Information. 
          However, Confidential Information does not include any information that (i) is or becomes generally known to the public without breach of any obligation 
          owed to the Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed 
          to the Disclosing Party, (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or (iv) was independently 
          developed by the Receiving Party. For the avoidance of doubt, the non-disclosure obligations set forth in this “Confidentiality” section apply to 
          Confidential Information exchanged between the parties in connection with the evaluation of additional IDCentral services.
          <br><br>
          <strong>8.2 Protection of Confidential Information:</strong>  
          As between the parties, each party retains all ownership rights in and to its Confidential Information. 
          The Receiving Party will use the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind 
          (but not less than reasonable care) to (i) hold in confidence and not disclose to any third party any Confidential Information of Discloser; 
          (ii) protect such Confidential Information with at least the same degree of care that Recipient uses to protect its own Confidential Information, 
          but in no case less than reasonable care; (iii) use the Discloser's Confidential Information for no purpose other than as provided herein; and 
          (iv) limit access to Discloser's Confidential Information to those of Recipient's employees or Authorized User’s who have a need-to-know or who need 
          that access for purposes consistent with this Agreement and who have signed confidentiality agreements containing, or are otherwise bound by, 
          confidentiality obligations at least as restrictive as those contained herein. Neither party will disclose the terms of this Agreement or any 
          Subscription Form to any third party other than its Affiliates, legal counsel and accountants without the other party’s prior written consent, 
          provided that a party that makes any such disclosure to its Affiliate, legal counsel or accountants will remain responsible for such Affiliate’s, 
          legal counsel’s or accountant’s compliance with this “Confidentiality” section. Notwithstanding the foregoing, Subex may disclose the terms of this 
          Agreement and any applicable Subscription Form to a subcontractor to the extent necessary to perform Subex’s obligations under this Agreement, 
          under terms of confidentiality materially as protective as set forth herein.
          <br><br>
          <strong>8.3 Compelled Disclosure:</strong> The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent compelled by law to do so, provided the Receiving Party gives the Disclosing Party prior notice of the compelled disclosure (to the extent legally permitted) and reasonable assistance, at the Disclosing Party's cost, if the Disclosing Party wishes to contest the disclosure, unless the Receiving Party is legally duty bound to disclose.
          <br><br>
          <strong>8.4</strong> Customer will ensure that all its Authorized Users comply with the provisions of this clause 8 and have appropriate written confirmation of the same prior to creating Authorized Users.
        </div>

        <div class="mt-2"><strong>9. THIRD PARTY PLATFORM</strong></div>

        <div>
          The Platform may contain links to third party Platforms that are not owned or controlled by Subex. Subex (as defined above) has no control over, 
          and assumes no responsibility for, the content, privacy policies (if any), or practices of any third-party Platforms. Subex makes no representation 
          or warranty as to the security of links to other Platforms, nor does Subex make any representation or warranty as to whether such links or such other 
          Platforms are free of viruses or other forms of data corruption. 
          In addition, Subex will not and cannot censor or edit the content of any third-party Platform. 
          By using the Platform, you expressly relieve Subex from any and all liability arising from your use of any third-party Platform. 
          If you choose to use any such third-party Platforms, you do so at your own risk. Accordingly, Subex encourages you to be aware when you leave the 
          Platform and to read the terms and conditions and privacy policy (if any) of each other Platform that you visit.
        </div>

        <div class="mt-2"><strong>10. INDEMNITY</strong></div>
        <div>
          You shall indemnify, defend and hold harmless Subex and each of its officers, directors, members, employees, agents, representatives, partners, 
          and licensors (collectively, the <strong>“Subex Indemnitees”</strong>) from any and all third party claims, liability, damages and/or costs (including attorneys’ fees) 
          arising from or related to (i) any User Content, (ii) your use or misuse of the Platform, (iii) Your violation of these Terms, 
          (iv) Your violation of the Services rendered under these Terms, (v) Your use or misuse of the Report generated, 
          (vi) Your violation of any applicable data protection regulations, laws or notifications or any claims, fine or penalties that occur therefrom or 
          (vii) any infringement or misappropriation of any Intellectual Property Right or any other right of any third person or entity by you. 
          (viii) Any claims that may occur from end users with regard to the use, processing, storage or transfer or Customer Data, 
          You agree to immediately notify Subex of any unauthorized use of your user account or any other breach of security known to you that may affect the 
          security of the Platform.
        </div>

        <div class="mt-2"><strong>11. DISCLAIMERS</strong></div>

        <div>
          Subex provides information through this Platform. While Subex has undertaken efforts to provide accurate information, 
          it is not comprehensive and Subex makes no commitment to update the information at any particular time, 
          and the information on the Platform may be out of date. 
          Information on the Platform may also be changed at any time without notice. As a result, the information may not be accurate, 
          up to date or applicable to the circumstances of any particular case. 
          Any decisions you make based on information contained in this Platform are solely your responsibility.

          <br><br>

          THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITH NO COMMITMENT TO ANY FUTURE FUNCTIONALITY OR FEATURES. 
          YOU ACKNOWLEDGE THAT YOUR USE OF THE PLATFORM IS AT YOUR OWN RISK. THE PLATFORM IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, 
          INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, SUBEXDOES NOT 
          WARRANT THAT THE CONTENT OF THE PLATFORM IS ACCURATE, RELIABLE OR CORRECT; THAT THE PLATFORM WILL MEET YOUR REQUIREMENTS; THAT THE PLATFORM WILL BE 
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE PLATFORM IS FREE OF 
          VIRUSES OR OTHER HARMFUL COMPONENTS. YOUR SOLE REMEDY IN THE EVENT OF ANY DEFICIENCY, ERROR, OR INACCURACY IN THE PLATFORM SHALL BE TO REQUEST THAT 
          SUBEX CORRECT THE MATTER OR, IF SUBEX FAILS TO DO SO, TO DISCONTINUE YOUR USE OF THE PLATFORM. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH 
          THE USE OF THE PLATFORM IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR DEVICE, OR FOR LOSS OF DATA 
          THAT RESULTS FROM SUCH DOWNLOAD. SUBEX DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY 
          A THIRD PARTY THROUGH THE PLATFORM, OR ANY HYPERLINKED PLATFORM OR SERVICE, OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND SUBEX WILL NOT BE A PARTY 
          TO AND IS NOT RESPONSIBLE IN ANY WAY FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES, INCLUDING ANY INDEPENDENT 
          CONTRACTOR PROVIDING THE SERVICES.
        
          <br><br>
          
          CUSTOMER UNDERSTANDS THAT SUBEX IS PERFORMING THE SERVICES HEREUNDER IN RELATION TO DATA THAT HAS BEEN PRODUCED OR SUPPLIED BY TO SUBEX BY THIRD PARTIES 
          OR CUSTOMER, THE ACCURACY AND COMPLETENESS OF WHICH SUBEX HAS NO RESPONSIBILITY OVER. SUBEX MAKES NO REPRESENTATION OR WARRANTY THAT ANY REPORT OR SCORE 
          OR RATING or DELIVERABLE GENERATED AS A CONSEQUENCE OF THE DATA SHARED BY THE THIRD PARTY OR CUSTOMER ARE ACCURATE, COMPLETE, APPRORIATE, RELIABLE OR TIMELY. 
          EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THIS AGREEMENT, THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS. EXCEPT AS EXPRESSLY PROVIDED HEREIN, 
          SUBEX DISCLAIMS ALL WARRANTIES AS TO THE IDCENTRAL MATERIALS, PRODUCT, REPORTS AND SERVICES, EXPRESS OR IMPLIED. EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, 
          SUBEX MAKES NO REPRESENTATION OR WARRANTY THAT ANY THIRD-PARTY MATERIALS ENCOMPASSED IN THE SERVICES OR IDCENTRAL MATERIALS ARE ACCURATE, COMPLETE, APPROPRIATE, 
          RELIABLE OR TIMELY. THE SERVICES AND SUCH WARRANTIES ARE EXPRESSLY MADE IN LIEU OF ANY OTHER EXPRESS OR IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY 
          OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. SUBEX MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, RESPECTING THE FUNCTIONAL CHARACTERISTICS OR 
          PERFORMANCE OF THE SERVICES OR THE BENEFITS TO BE OBTAINED BY CUSTOMER OR ANY END USERS FROM USE OF THE SERVICES.
        </div>

        <div class="mt-2"><strong>12. LIMITATION ON LIABILITY</strong></div>
        
        <div>
          <br>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SUBEX SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, 
          INCLUDING ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, 
          RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE PLATFORM; (B) ANY CONTENT OBTAINED FROM THE PLATFORM. YOU ASSUME TOTAL 
          RESPONSIBILITY FOR YOUR USE OF THE PLATFORM; (C) PRODUCT OR SERVICES RENDERED UNDER THESE TERMS; OR (D) ANY SCORE OR RATING OR REPORT OR DELIVERABLE 
          GENERATED AS A CONSEQUENCE OF THE SERVICES OR PRODUCT CONTEMPLATED UNDER THESE TERMS. YOUR ONLY REMEDY AGAINST SUBEX IN CONNECTION WITH ANY DAMAGES 
          ARISING FROM THE USE OF THE WEBSTE IS TO STOP USING THE PLATFORM AND IN NO EVENT SHALL THE LIABILITY OF SUBEX TO CUSTOMER, ITS AFFILIATES,
          CUSTOMER USERS, END USERS, OR ANY OF THEIR RESPECTIVE EMPLOYEES, WHETHER ARISING OUT OF ANY CLAIM RELATED TO THESE TERMS OR THE SUBJECT MATTER HEREOF, EXCEED (i) IN CASE OF PILOT PHASE 100 USD (ONE HUNDRED UNITED STATES DOLLARS) (ii) IN ANY OTHER CASE, THE AGGREGATE AMOUNT PAID BY CUSTOMER TO SUBEX IN THE TWELVE MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
        </div>

        <div class="mt-2"><strong>13. TERM AND TERMINATION</strong></div>

        <div>
          <br>
          <strong>13.1 Term of Agreement:</strong> This Agreement commences on the Effective Date Customer and continues until all subscriptions hereunder have expired or have been terminated.
          <br><br>
          <strong>13.2 Term of Purchased Subscriptions:</strong> The term of each subscription shall be as specified in the applicable Subscription Form. Except as otherwise specified in a Subscription Form, subscriptions will automatically renew for additional periods equal to the expiring subscription term or one year (whichever is shorter), unless either Party gives the other notice of non-renewal at least 30 days before the end of the relevant subscription term. Subex reserves the right to increase or add new fees to any of its Services by giving thirty (30) days advance electronic notice, prior to the renewal term. The Customer will have an option not to renew the Services, with the change in pricing.
          <br><br>
          <strong>13.3 Termination:</strong> A Party may terminate this Agreement for cause (i) upon 30 days written notice to the other Party of a material breach if such material breach remains uncured at the expiration of such period, or (ii) if the other Party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors which petition or proceeding is not dismissed within ninety (90) days. Subex reserves the right to withdraw the provision of Services at any time by providing a prior notice of seven (7) days.
          <br><br>
          <strong>13.4</strong> On the expiration or termination of these Terms or upon written notice provided by the Subex to the Customer, all rights, licenses and authorizations granted to the Customer hereunder will immediately terminate and Customer shall (i) immediately cease all use of and other activities with respect to the Platform and/or IDCentral and its Documentation. In this instance, Customer’s cooperation shall include but shall not be limited to, assistance, cooperation, making necessary arrangements or provision of requisite authorizations/access for uninstallation, deactivation, removal of the Platform and/or IDCentral from Customer’s premises and/or its networks, (ii) within ten (10) days deliver to Subex or on Subex’s written request, destroy, and permanently erase from all devices and systems which Customer directly or indirectly controls, the Platform and/or IDCentral or its Documentation and Subex’s Confidential Information, including all documents, files, and tangible materials (and any partial and complete copies) containing, reflecting, incorporating, or based on any of the foregoing, whether or not modified or merged into other materials; and (iii) certify to Subex in a written instrument signed by an authorized representative that it has complied with this section.
          <br><br>
          <strong>13.5 Payment upon Termination:</strong> If this Agreement is terminated by Subex in accordance with the “Termination” section above, Customer will pay any unpaid fees covering the remainder of the term of all Subscription Forms to the extent permitted by applicable law. In no event will termination relieve Customer of its obligation to pay any fees payable to Subex for the period prior to the effective date of termination.
          <br><br>
          <strong>13.6 Surviving Provisions:</strong> The sections titled “Fees and Payment,” “Proprietary Rights and Licenses,” “Confidentiality,” “Disclaimers,” “Indemnity,” “Limitation on Liability,” “Surviving Provisions”, and “General Provisions” will survive any termination or expiration of this Agreement, and the section titled “Customer Data” will survive any termination or expiration of this Agreement for so long as Subex retains possession of Customer Data.
        </div>

        <div class="mt-2"><strong>14. GENERAL PROVISIONS</strong></div>

        <div>
          <br>
          <strong>14.1 Force Majeure:</strong> Neither Party shall be liable hereunder by reason of any failure or delay in the performance of its obligations 
          beyond such Party’s reasonable control hereunder on account of strikes, shortages, riots, insurrection, fires, flood, explosions, acts of God, war, 
          governmental action, pandemics, government imposed lockdowns labour conditions, earthquakes, material shortages, terror strikes, civil disobedience, 
          breakdown of law and order, or national or regional shortage of adequate power or telecommunications or transportation or insurrections and/or any 
          other cause which is beyond the reasonable control of such Party, and is both irresistible and unpredictable.
          <br><br>
          <strong>14.2 Governing Law, and Venue:</strong> The law that will apply in any dispute or lawsuit arising out of or in connection with this Agreement, 
          and the courts that have jurisdiction over any such dispute or lawsuit, depend on where Customer is domiciled:

          <br><br>

          <table id="t-and-c-table">
            <tr class="table-header">
              <td>
                If Customer is domiciled in:
              </td>
              <td>
                Governing law is:
              </td>
              <td>
                Courts with exclusive jurisdiction are:
              </td>
            </tr>

            <tr>
              <td>
                The United States of America, Mexico, Central America, South America, Canada and the Caribbean other than Cuba
              </td>

              <td>
                Laws of New York
              </td>

              <td>
                New York, NY
              </td>
            </tr>

            <tr>
              <td>A Country in Europe, Africa, Middle East other than Israel</td>
              <td>Laws of England & Wales</td>
              <td>London, England</td>
            </tr>

            <tr>
              <td>Australia, New Zealand or a country in Asia (other than North Korea)</td>
              <td>Laws of Singapore</td>
              <td>Singapore, Singapore</td>
            </tr>

            <tr>
              <td>India</td>
              <td>Laws of Republic of India</td>
              <td>Bangalore, Karnataka, India</td>
            </tr>

            <tr>
              <td>Any country not listed</td>
              <td colspan="2">Please contact IDCentral</td>
            </tr>
          </table>

          <strong>14.3 Notices:</strong>
          Except as otherwise specified in this Agreement and except in case of revision of terms and conditions of 
          this Agreement, all notices related to this Agreement will be in writing and will be effective upon the third day 
          following deposit in an overnight or expedited courier with signature required except that billing related notices 
          shall be effective on the day sent to an email address designated by the Customer as the relevant billing contact. 
          The address to which Parties should direct notices under this Agreement:

          <br><br>

          IDCentral Address: Subex Digital LLP
          
          <br><br>
          Attn: Legal
          <br>
          Pritech Park - SEZ
          <br>
          Block -09, 4th Floor, B Wing,
          <br>
          Pritech Park (behind RMZ Eco Space)
          <br>
          Outer Ring Road, Adarsh Palm Retreat, Bellandur
          <br>
          Bengaluru - 560103, Karnataka
          <br><br>
          Your Address: As given in Subscription Form.
        </div>

        <div class="text-center mt-3">
          **********
        </div>
</div>