
<div class="switch-container hover-pointer" *ngIf="type === 'SET-1'" [ngClass]="isActive ? 'active-container' : 'in-active-container'" (click)="switchEvent.emit(isActive)">
  <div class="switch-inner-circle" [ngClass]="isActive ? 'active' : 'in-active'"></div>
</div>

<div class="switch-container-2 hover-pointer" *ngIf="type === 'SET-2' && !isDisabled" 
  [ngClass]="isActive ? 'active-container' : 'in-active-container'" 
  (click)="switchEvent.emit(isActive)">
  <div class="switch-inner-circle-2" [ngClass]="isActive ? 'active-2' : 'in-active-2'"></div>
</div>

<div class="switch-2-disabled" *ngIf="type === 'SET-2' && isDisabled">
  <div class="switch-container-2" 
  [ngClass]="isActive ? 'active-container' : 'in-active-container'">
  <div class="switch-inner-circle-2" [ngClass]="isActive ? 'active-2' : 'in-active-2'"></div>
  </div>
</div>

