import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app-constants.constants';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  @ViewChild('navigateToLoginBtn') navigateToLoginBtn;
  @Input() public errorMessage: string = null;
  public IDC_LOGO = AppConstants.IDC_LOGO;

  /*----------METHOD TO REDIRECT THE USER TO LOGIN PAGE---------*/
  redirectToLogin() {
    this.router.navigate([AppConstants.LOGIN_URL]);
  }

  constructor(private router: Router) { }
}
