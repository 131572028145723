import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCountryCode'
})
export class FilterCountryCodePipe implements PipeTransform {

  transform (inputArray: any, searchText): any {
    if (searchText) {
      const resultArray = [];
      for (const x of inputArray) {
        if (x['countryCode'].toLowerCase().indexOf(searchText.toLowerCase()) > -1 
        || x['country'].toLowerCase().indexOf(searchText.toLowerCase())> -1) {
          resultArray.push(x);
        }
      }
      return resultArray;
    }
    return inputArray;
  }
}
