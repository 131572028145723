import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConstants } from './app/app-constants.constants';
import { URL_CONSTANTS } from './app/app-base-url.constants';


if (environment.production) {
  enableProdMode();
  /*----------REMOVED CONSOLE LOGS IN PRODUCTION ENVIRONMENT----------*/
  if (window && window.console) {
    window.console.log = () => { };
  }
  AppConstants.LANDING_PAGE_SET_PASSWORD_LINK = '/app/set-password/';
  AppConstants.BASE_URL = URL_CONSTANTS.BASE_URL;
  AppConstants.BASE_URL_ADMIN = URL_CONSTANTS.BASE_URL_ADMIN_PROD; // PROD SETTING --> ENABLE PROD URLS
  AppConstants.BASE_URL_USER = URL_CONSTANTS.BASE_URL_USER_PROD; // PROD SETTING --> ENABLE PROD URLS
  console.log(AppConstants.LANDING_PAGE_SET_PASSWORD_LINK, AppConstants.BASE_URL, AppConstants.BASE_URL_ADMIN, AppConstants.BASE_URL_USER)
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
