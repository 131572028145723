import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitUnderscore'
})
export class SplitUnderscorePipe implements PipeTransform {

  transform (value: any): any {
    try {
      if (value) {
        const splittedText = value.split('_');
        let label = '';
        for(const x of splittedText) {
          label =  label+' '+x;
        }
        return label;
      } 
    }
    catch(e) {}
    return value;
  }
}
