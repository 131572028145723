import { Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {

  public ERROR_ICON = 'assets/img/modal-icons/error.svg';
  public SUCCESS_ICON = 'assets/img/modal-icons/success.svg';
  public INFO_ICON = 'assets/img/modal-icons/info.svg';

  @Input() custom_yes_button: string = null;
  @Input() custom_no_button: string = null;

  @Input() iconType: string = null; // WARNING - SUCCESS - INFO
  @Input() subHeader: string = null;

  @Input() hideCloseButton = false;
  @Input() buttonsType: string = null;
  @Input() alertText: string = null;
  @Input() alertSubText: string = null;
  @Output() modalAction = new EventEmitter();

  @Input() hideButtons = false;

  constructor() {}

}
